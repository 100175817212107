.searchbarwrap {
  position: relative;
  cursor: pointer;
  max-height: 48.33px;
  width: 56.6%;
  .mui-register-password{
    width: 100%;
    height: 100%;
    padding: 0px 10px;
  }
  @media (max-width: 1200px) {
    width: -webkit-fill-available;
    // min-height: 100px;
    display: flex;
    justify-content: center;
  }
  .search-result-div {
    z-index: 1000;
    background: #fff;
    border-radius: 8px;
    padding: 24px;
    position: absolute;
    top: 102%;
    border: 1px solid rgba(0, 0, 0, 0.1);
    width: calc(220% - 48px);
    left: 50%;      // Centers horizontally
    transform: translateX(-50%); 
    display: flex;
    flex-direction: column;
    gap: 10px;
    &.short{
    width: calc(100% - 48px);
    }
    .top-banner-livesearch {
      align-self: stretch;
      padding: 24px;
      background-color: rgba(104, 212, 145, 0.27);
      border-radius: 12px;
      display: flex;
      height: calc(151px - 48px);
      .img-wrapper {
        flex: 1;
        .img-banner-search {
          position: relative;
          width: 100%;
          bottom: 0;
          right: 0;
        }
      }
      .text-wrapper {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .top {
          span {
            display: block;
          }
          .title {
            color: #29241f;
            font-family: Roboto;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 131.5%; /* 21.04px */
            letter-spacing: 1.44px;
            text-transform: uppercase;
          }
          .subtext {
            color: var(--Foggy-Gray-500, #8c8666);
            font-family: Roboto;
            font-size: 12px;
            font-style: normal;
            font-weight: 600;
            line-height: 150%; /* 18px */
          }
        }
        .explore-search {
          border-radius: 10px;
          background: var(--Main-Green, #219d50);
          color: #ffff;
        }
      }
    }
    .live-search-scroll-div {
      overflow: auto;
      height: 276px;
      display: flex;
      flex-direction: column;
      gap: 8px;
      &::-webkit-scrollbar {
        width: 5px;
      }
      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: #219d5167;
        border-radius: 8px;
      }
      &::-webkit-scrollbar-thumb:hover {
        background: #219d5167;
      }
      .search-product-box {
        display: flex;
        border-radius: 8px;
        max-height: 92px;
        width: calc(100% - 8px);
        gap: 10px;
        padding: 4px;
        &:hover{
          background-color:var(--Green-Light, rgba(104, 212, 145, 0.27));
        }
        .img-wrapper {
          flex: 1;
          max-width: 75px;
          img {
            position: relative;
            right: 0;
            bottom: 0;
            width: 100%;
            max-height: 75px;
          }
        }
        .searchproduct-name {
          flex: 2;
          color: var(--Black-950, #050505);
          font-family: Roboto;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 150%; /* 24px */
          text-align: left;
          overflow: hidden;
        }
        .icon {
          flex: 1;
          max-width: 16px;
        }
      }
    }
  }
}
