.shop-mega-menu {
  background: #F7F7F7;
  width: 100%;
  position: absolute;
  top: 100%;
  // max-height: 619px;
  display: none;
  z-index: 100;
  @media (max-width: 1200px) {
    max-height: 67vh;
  }
  @media (max-width: 1120px) {
    max-height: calc(100vh - 188px);
  }
  &.shown {
    z-index: 1000;
    display: flex;
    flex-direction: column;
    align-items: center;
    @media (max-width: 1030px) {
      display: none;
    }
  }
  .top-link {
    // width: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    height: 592px;
    background: #F7F7F7;
    // max-width: 1352px;'
    width: 89.4%;
    margin: 50px 0;
    min-height: 565px;
    overflow: hidden;
    position: relative;
    max-width: 1352px;
    z-index: 100;
       @media (max-width: 1200px) {
      width: 95%; 
      }

    // @media (max-width: 1120px) {
    //   flex-wrap: nowrap;
    //   overflow: auto;
    //   max-width: 100vw;
    //   height: auto;
    //   min-height: auto;
    // }
    .megamenu-topwrapper{
      width: 100%;
      height: 100%;
      gap: 32px;
      display: flex;
      .tabs-wrapper{
      width: calc(75.8% - 16px);
      display: flex;
      gap: 16px;
      .tabs{
        display: flex;
        flex-direction: column;
        height: 100%;
        max-width: 298px;
        .tab{
          display: flex;
          align-items: center;
          gap: 6px;
          border-radius: 12px;
          height: 57px;
          cursor: pointer;
          &.active{
            background: #FFF;
          }
          .cat-name{
          color: #1E1E1E;
          font-family: Roboto;
          font-size: 16px;
          font-weight: 500;
          line-height: 150%; /* 24px */
          max-width:calc(98% - 52px);
          overflow: hidden;
          display: block;
          text-overflow: ellipsis;
          white-space: nowrap;
          }
        }
      }
      .tabpanel{
        padding: 12px 24px;
        border-radius: 16px;
        background: #FFF;
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        gap: 16px;
        align-items: flex-start;
        flex-wrap: wrap;
        position: relative;
        .subcat-link{
          display: block;
          color: #5C5F62;
          font-family: Roboto;
          font-size: 14px;
          font-weight: 600;
          line-height: 150%; /* 21px */
          &:hover{
            color: #0c7935;
          }
        }
        .opacity-image{
          position: absolute;
          width: 40%;
          background-color: transparent;
          opacity: 0.2;
          bottom: 0;
          right: 15%;
        }
      }
      }
      .megamenu-bestSeller-cont{
        width:calc(24.2% - 64px);
        padding: 24px;
        display: flex;
        flex-direction: column;
        @media (max-width: 1200px) {
          padding: 24px 0px;
          width: 24.2%;
        }
        .best-seller-title{
          display: block;
          text-align: left;
          color: #5C5F62;
          font-family: Roboto;
          font-size: 16px;
          font-weight: 700;
          line-height: 131.5%; /* 21.04px */
          letter-spacing: 1.44px;
          text-transform: uppercase;
          margin-bottom: 48px;
        }
        .products-wrapper{
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          flex-grow: 1;
          .product-box{
            cursor: pointer;
            display: flex;
            gap: 12px;
            .img-wrapper {
              width: 47%;
              border-radius: 16px;
              background-color: #fff;
              display: flex;
              justify-content: center;
              align-items: center;
              overflow: hidden;
              position: relative;
              padding-bottom: 47%;
              img {
                object-fit: contain;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%; /* Add this to ensure image covers the container */
              }
            }
            .right-productDesc{
              max-width: calc(53% - 12px);
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              text-align: left;
              .product-name{
                width: 100%;
                display: block;
                color: #3C3C3C;
                font-family: Roboto;
                font-size: clamp(14px,1.8vw,16px);
                font-weight: 700;
                line-height: 150%; /* 24px */
                max-height: 46px;
                overflow: hidden;
              }
              .price{
                color:#219D50;
                font-family: Roboto;
                font-size: clamp(20px,2vw,24px);
                font-weight: 600;
                line-height: 150%; /* 36px */
                letter-spacing: 0.36px;
              }
              .add-to-cart{
                display: flex;
                gap: 10px;
                background-color: #219D50;
                color: #FFF;
                font-family: Roboto;
                font-size: 14px;
                font-weight: 600;
                text-transform: capitalize;
                max-width: 92px;
                min-height: 36px;
                border-radius: 10px;
                transition: .3s all ease-in-out;
                &:hover{
                  background-color: #0c7935;
                  transform: scale(1.05);
                }
              }
            }
          }
        }
      }
    }
    // .box {
    //   width: calc(25% - 32px);
    //   display: flex;
    //   flex-direction: column;
    //   flex: 1 1;
    //   text-align: left;
    //   max-height: 90%;
    //   @media (max-width: 1120px) {
    //     width: 100% !important;
    //     max-width: 78vw;
    //     overflow-y: visible !important;
    //     padding: 30px 16px 28px 36px !important;
    //   }
    //   &:first-child {
    //     width: calc(25% - 96px);
    //     // padding: 50px 16px 18.5px 80px;
    //     padding: 50px 16px 18.5px 36px;
    //     // background-color: #f7f7f7;
    //     border-bottom: 1px solid #d0d0d0;
    //     @media (max-width: 1120px) {
    //       border: none;
    //     }
    //   }
    //   &:nth-child(2) {
    //     width: calc(25% - 96px);
    //     // padding: 18.5px 16px 68.5px 80px;
    //     padding: 20px 16px 5px 36px;
    //     background-color: #fff;
    //   }
    //   &:nth-child(3) {
    //     padding: 50px 16px 18.5px 36px;
    //     // background-color: #f7f7f7;
    //   }
    //   &:nth-child(4) {
    //     padding: 50px 16px 18.5px 0;
    //     background-color: #fff;
    //     overflow-y: visible;
    //     width: 45%;
    //     &::-webkit-scrollbar {
    //       width: 6px;
    //     }
    //     /* Track */
    //     &::-webkit-scrollbar-track {
    //       border-radius: 8px;
    //     }
    //     /* Handle */
    //     &::-webkit-scrollbar-thumb {
    //       background: var(--Green-Light, rgba(104, 212, 145, 0.27));
    //       border-radius: 8px;
    //     }
    //     &::-webkit-scrollbar-thumb:hover {
    //       background: #219d50;
    //     }
    //   }
    //   &:nth-child(5) {
    //     width: calc(25% - 96px);
    //     padding: 50px 80px 18.5px 16px;
    //   }
    //   &:nth-child(6) {
    //     width: calc(25% - 96px);
    //     padding: 18.5px 80px 68.5px 16px;
    //   }
    //   .cat-title {
    //     color: #1e1e1e;
    //     // font-family: Inter;
    //     font-size: 18px;
    //     font-weight: 600;
    //     text-transform: capitalize;
    //     padding-bottom: 10px;
    //   }
    //   .subcat {
    //     margin: 0px;
    //     padding: 0px;
    //     list-style: none;
    //     flex-wrap: wrap;
    //     display: flex;
    //     flex-direction: column;
    //     max-height: 91%;
    //     @media (max-width: 1120px) {
    //       flex-wrap: nowrap;
    //       max-height: unset;
    //     }
    //     li {
    //       a {
    //         display: block;
    //         color: #717286;
    //         // font-family: Inter;
    //         font-size: 16px;
    //         font-weight: 500;
    //         text-transform: capitalize;
    //         padding: 8px 0px;
    //         &:hover {
    //           color: #219d50;
    //         }
    //       }
    //     }
    //   }
    // }
  }
  .bottom-section {
    display: flex;
    padding: 16px 5.3%;
    align-items: center;
    align-self: stretch;
    background: #FFF;
    justify-content: center;
    // @media (max-width: 960px) {
    //   padding: 16px 20px;
    // }
    .wrapping {
      max-width: 1352px;
      display: flex;
      align-items: center;
      gap: 24px;
      align-self: stretch;
      width: 100%;
      @media (max-width: 960px) {
        flex-direction: column;
      }
      .text-wrappers-shop-mega {
        width: calc(100% - 184px);
        align-items: center;
        display: flex;
        gap: 8%;
        max-width: 1350px;
        @media (max-width: 960px) {
          flex-wrap: wrap;
          width: auto;
          gap: 20px;
        }
        .text {
          color: var(--Black, #000);
          font-family: Roboto;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 150%; /* 24px */
        }
      }
      .button {
        display: flex;
        padding: 12px 24px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        align-self: stretch;
        border-radius: 10px;
        background: var(--Main-Green, #219d50);
        border: none;
        color: var(--Main-White, #fff);
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-transform: capitalize;
        cursor: pointer;
        &:hover {
          background: #0c7935;
        }
      }
    }
  }
}
