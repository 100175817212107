.guesform {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
  width: -webkit-fill-available;
  .shipping,
  .billing {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    align-self: stretch;
    width: -webkit-fill-available;
    .MuiInputBase-root {
      color: #122146;
      font-size: 16px;
      border-radius: 12px;
      padding: 1px 16px;

      &:focus {
        box-shadow: 0px 4px 6px -2px rgba(0, 0, 0, 0.05),
          0px 10px 15px -3px rgba(32, 66, 137, 0.1);
      }

      &::placeholder {
        color: #616161;
        font-weight: 400;
        font-size: 16px;
      }
    }
    @media (max-width: 520px) {
      .MuiFormControl-root.MuiTextField-root {
        width: 100%;
      }
    }
    input {
      height: 27px;
      color: #122146;
      font-size: 16px;
      border-radius: 12px;
      border: 1px solid #dedede;
      padding: 10px 16px 10px 16px;
      width: 50%;
      @media (max-width: 520px) {
        width: calc(100% - 34px) !important;
      }
      &:focus {
        outline: 1px solid #4c9b58;
        box-shadow: 0px 4px 6px -2px rgba(0, 0, 0, 0.05),
          0px 10px 15px -3px rgba(32, 66, 137, 0.1);
      }

      &::placeholder {
        color: #616161;
        font-weight: 400;
        font-size: 16px;
      }
    }

    .top-part {
      display: flex;
      padding-left: 8px;
      align-items: center;
      gap: 24px;
      align-self: stretch;

      .heading {
        color: #595959;

        /* Web/Body/B2 - Medium */
        font-family: Roboto;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%; /* 27px */
        letter-spacing: 0.27px;
      }
    }

    .inputs-wrapper {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 24px;
      align-self: stretch;
      @media (max-width: 520px) {
        gap: 8px;
        max-height: 50vh;
        overflow-y: auto;
      }

      .inputs-row {
        display: flex;
        align-items: flex-start;
        gap: 16px;
        align-self: stretch;
        text-align: left;
        label.Mui-focused {
          color: #4c9b58;
        }
        .MuiOutlinedInput-root {
          border-radius: 10px;
          max-height: 49px;
          &.Mui-focused fieldset {
            border-color: #4c9b58;
          }
        }
        @media (max-width: 520px) {
          flex-direction: column;
        }
        .no-border-input {
          width: 100%;
          input {
            border: none;
            &:focus {
              outline: none;
              box-shadow: none;
            }
          }
        }
      }
    }
  }
  .checkbox {
    display: flex;
    align-items: center;
    gap: 8px;
    .checkbox_title {
      color: var(--body, #717286);
      font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%; /* 21px */
    }
  }
}
