.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  // height: 90vh;
  transform: translate(-50%, -50%);
  max-width: 731px;
  border-radius: 10px;
  background: #fff;
  overflow: auto;
  display: flex;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
 width: calc(90% - 48px);
  .MuiInputBase-root {
    color: #122146;
    font-size: 16px;
    border-radius: 12px;
    padding: 1px 16px;

    &:focus {
      box-shadow: 0px 4px 6px -2px rgba(0, 0, 0, 0.05), 0px 10px 15px -3px rgba(32, 66, 137, 0.10);
    }

    &::placeholder {
      color: #616161;
      font-weight: 400;
      font-size: 16px;
    }
  }
  @media (max-width: 520px) {
    .MuiFormControl-root.MuiTextField-root{
      width: 100%;
    }
 }
  input {
    height: 27px;
    color: #122146;
    font-size: 16px;
    border-radius: 12px;
    border: 1px solid #DEDEDE;
    padding: 10px 16px 10px 16px;
    width: 50%;
    @media (max-width: 520px) {
      width:calc(100% - 34px) !important;
   }
    &:focus {
      outline: 1px solid #4C9B58;
      box-shadow: 0px 4px 6px -2px rgba(0, 0, 0, 0.05), 0px 10px 15px -3px rgba(32, 66, 137, 0.10);
    }

    &::placeholder {
      color: #616161;
      font-weight: 400;
      font-size: 16px;
    }
  }

  .top-part {
    display: flex;
    padding-left: 8px;
    align-items: center;
    gap: 24px;
    align-self: stretch;

    h1 {
      flex: 1 0 0;
      color: #303030;
      font-size: clamp(20px,3.6vw,28px);
      font-style: normal;
      font-weight: 600;
      line-height: 150%; /* 42px */
      letter-spacing: 0.42px;
    }
  }

  .inputs-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    align-self: stretch;
    @media (max-width: 520px) {
      gap: 8px;
      max-height: 50vh;
      overflow-y: auto;
      }

    .inputs-row {
      display: flex;
      align-items: flex-start;
      gap: 16px;
      align-self: stretch;
      label.Mui-focused {
        color: #4C9B58;
      }
    .MuiOutlinedInput-root {
        border-radius: 10px;
        max-height: 49px;
        &.Mui-focused fieldset {
          border-color:#4C9B58;
        }
      }
      @media (max-width: 520px) {
        flex-direction: column;
     }
      .no-border-input {
        width: 100%;
        input {
          border:none;
          &:focus {
            outline: none;
            box-shadow: none;
          }
        }
      }
    }
  }

  .buttons {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;

    button {
      display: flex;
      padding: 12px 24px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      align-self: stretch;
      border-radius: 10px;
      background: var(--Main-Green, #219D50);
      color: var(--Main-White, #FFF);
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      text-transform: capitalize;
      width: 100%;
      border: none;
      &:disabled{
        opacity: 0.6;
        pointer-events: none;
      }
      &.white {
        display: flex;
        padding: 12px 20px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        align-self: stretch;
        color: #A7A7A7;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-transform: capitalize;
        background: transparent;
      }

      &.delete {
        display: flex;
        padding: 12px 24px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        align-self: stretch;
        border-radius: 10px;
        border: 1.5px solid #CD1D1D;
        background: transparent;
        color: #CD1D1D;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-transform: capitalize;
      }
    }

  }
}