.SettingsSecurity {
  background: #f8f8f8;
  .main-conatiner {
    display: flex;
    padding: 2.6% 5.2%;
    justify-content: center;
    align-items: center;
    @media (max-width: 450px) {
      padding: 0px;
    }
    .wrapper {
      display: flex;
      width: 100%;
      gap: 24px;
      max-width: 1352px;
      .SettingsSecurity-right {
        display: flex;
        flex-direction: column;
        width: calc(100% - 417px);
        gap: 24px;
        height: fit-content;
        @media (max-width: 1100px) {
          width: 100%;
        }
        .security-section {
          border-radius: 10px;
          background: #fff;
          display: flex;
          padding: 24px;
          flex-direction: column;
          align-items: flex-start;
          gap: 24px;
          align-self: stretch;
          p {
            color: #000;
            font-family: Roboto;
            font-size: 24px;
            font-weight: 600;
            line-height: 150%; /* 36px */
            letter-spacing: 0.36px;
          }
          .top-wrapper {
            display: flex;
            align-self: stretch;
            justify-content: space-between;
            align-items: center;
            .left {
              display: flex;
              gap: 8px;
              align-items: center;
              p {
                color: #000;
                font-family: Roboto;
                font-size: 24px;
                font-weight: 600;
                line-height: 150%; /* 36px */
                letter-spacing: 0.36px;
              }
              .badge {
                border-radius: 4px;
                background: rgba(47, 53, 49, 0.4);
                display: flex;
                padding: 4px 8px;
                justify-content: center;
                align-items: center;
                gap: 8px;
                color: var(--Variable-Off-White, #f7f6fb);
                font-family: Roboto;
                font-size: 10px;
                font-weight: 600;
                line-height: 150%; /* 15px */
                max-height: 23px;
                &.verified {
                  background-color: #219d50;
                }
              }
            }
            .edit-email-bttn {
              display: flex;
              justify-content: center;
              align-items: center;
              gap: 10px;
              padding: 10px 20px;
              max-height: 36px;
              border-radius: 10px;
              background: rgba(104, 212, 145, 0.27);
              color: var(--Main-Green, var(--Fruit-Salad-600, #219d50));
              font-family: Roboto;
              font-size: 14px;
              font-weight: 600;
              text-transform: capitalize;
              &:hover {
                background: #219d50;
                color: #fff;
                svg {
                  color: #fff;
                }
              }
              @media (max-width: 450px) {
                display: none;
              }
            }
          }
          .form-wrapper {
            align-self: stretch;
            display: flex;
            flex-wrap: wrap;
            gap: 16px;
            label.Mui-focused {
              color: #4c9b58;
            }
            .MuiOutlinedInput-root {
              border-radius: 10px;
              &.Mui-focused fieldset {
                border-color: #4c9b58;
              }
            }
            .input {
              margin-top: 5px;
              width: calc(50% - 8px);
              @media (max-width: 570px) {
                width: 100%;
              }
            }
            .save-changes {
              border-radius: 10px;
              background: #219d50;
              color: var(--Main-White, #fff);
              font-family: Roboto;
              font-size: 14px;
              font-weight: 600;
              text-transform: capitalize;
              display: flex;
              padding: 10px 20px;
              max-height: 36px;
              @media (max-width: 450px) {
                width: 100%;
              }
              &:disabled {
                opacity: 0.7;
                pointer-events: none;
              }
            }
          }
          .edit-email-bttn-mobile {
            display: none;
            justify-content: center;
            align-items: center;
            gap: 10px;
            padding: 10px 20px;
            max-height: 36px;
            border-radius: 10px;
            background: rgba(104, 212, 145, 0.27);
            color: var(--Main-Green, var(--Fruit-Salad-600, #219d50));
            font-family: Roboto;
            font-size: 14px;
            font-weight: 600;
            width: 100%;
            text-transform: capitalize;
            @media (max-width: 450px) {
              display: flex;
            }
          }
        }
      }
    }
  }
}
