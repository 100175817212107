.Prescriptions {
  background: #f8f8f8;
  .main-conatiner {
    display: flex;
    padding: 2.6% 5.2%;
    justify-content: center;
    align-items: center;
    @media (max-width: 450px) {
      padding: 0px;
    }
    .wrapper {
      display: flex;
      width: 100%;
      gap: 24px;
      max-width: 1352px;
      .right-div-prescription {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 24px;
        border-radius: 10px;
        width: calc(100% - 383px);
        @media (max-width: 1100px) {
          width: 100%;
        }
        .prescription-services {
          display: flex;
          padding: 32px;
          flex-direction: column;
          align-items: flex-start;
          gap: 24px;
          align-self: stretch;
          border-radius: 10px;
          background: #fff;
          @media (max-width: 890px) {
            padding: 18px;
          }
          .title {
            display: flex;
            gap: 7px;
            align-items: center;
            p {
              color: #000;
              font-family: Roboto;
              font-size: 24px;
              font-style: normal;
              font-weight: 600;
              line-height: 150%; /* 36px */
              letter-spacing: 0.36px;
            }
          }
          .presc-services-boxes {
            align-self: stretch;
            .swiper {
              width: 100%;
              height: "fit-content";
            }

            .swiper-slide {
              max-width: 251px;
              display: flex;
              padding: 24px 16px;
              flex-direction: column;
              align-items: center;
              justify-content: space-between;
              border-radius: 10px;
              border: 1px solid #dedede;
              background: #f8f8f8;
              align-self: stretch;
              height: 150px;
              .top-div {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                gap: 8px;
                p {
                  color: var(--Basic-Black, #000);
                  text-align: center;
                  font-family: Roboto;
                  font-size: 18px;
                  font-weight: 500;
                  line-height: 150%; /* 27px */
                  letter-spacing: 0.27px;
                }
                span {
                  color: var(--Grey-500, #717286);
                  text-align: center;
                  font-family: Roboto;
                  font-size: 14px;
                  font-weight: 400;
                  line-height: 150%; /* 21px */
                }
              }
              .serv-box-bttn {
                display: flex;
                padding: 10px 20px;
                justify-content: center;
                align-items: center;
                gap: 10px;
                border-radius: 10px;
                background: #219d50;
                color: #fff;
                font-family: Roboto;
                font-size: 14px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                text-transform: capitalize;
                max-height: 36px;
                &:hover {
                  background: #0c7935;
                }
              }
            }
          }
        }
        .presc-history {
          display: flex;
          padding: 32px;
          flex-direction: column;
          align-items: flex-start;
          gap: 24px;
          border-radius: 10px;
          background-color: #fff;
          align-self: stretch;
          @media (max-width: 890px) {
            padding: 32px 10px;
          }
          .title {
            display: flex;
            align-self: stretch;
            @media (max-width: 890px) {
              flex-direction: column;
              gap: 20px;
            }
            .left {
              flex: 1;
              display: flex;
              gap: 7px;
              align-items: center;
              p {
                text-align: left;
                color: #000;
                font-family: Roboto;
                font-size: clamp(20px, 4vw, 24px);
                font-style: normal;
                font-weight: 600;
                line-height: 150%; /* 36px */
                letter-spacing: 0.36px;
              }
            }
            .search-div {
              flex: 1;
              display: flex;
              padding: 0px 16px;
              justify-content: flex-start;
              align-items: center;
              gap: 10px;
              flex: 1;
              align-self: stretch;
              border-radius: 10px;
              border: 1px solid #dedede;
              input {
                padding: 12px 0px;
                flex: 5;
                display: flex;
                color: #616161;
                font-family: Roboto;
                font-size: 16px;
                font-weight: 400;
                line-height: 150%; /* 24px */
                border: none;
                &:disabled {
                  opacity: 0.6;
                }
                &::placeholder {
                  color: #616161;
                  font-family: Roboto;
                  font-size: 16px;
                  font-weight: 400;
                }
                &:focus {
                  outline: none;
                }
              }
            }
          }
          .empty-orders {
            border-radius: 24px;
            border: 1px solid #ebecff;
            background: #fff;
            display: flex;
            padding: 24px;
            flex-direction: column;
            align-items: center;
            gap: 8px;
            align-self: stretch;
            .empty-text {
              color: #717286;
              text-align: center;
              font-family: Roboto;
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: 150%; /* 24px */
            }
            .link-to-shop {
              color: var(--Main-White, #fff);
              font-family: Roboto;
              font-size: 14px;
              font-style: normal;
              font-weight: 600;
              line-height: normal;
              text-transform: capitalize;
              border-radius: 10px;
              background: #219d50;
              display: flex;
              padding: 10px 20px;
              justify-content: center;
              align-items: center;
              gap: 10px;
            }
          }
        }
      }
    }
  }
}
