.MuiStepLabel-label.MuiStepLabel-alternativeLabel, .MuiStepLabel-label.Mui-completed{
    font-family: Roboto !important;
    font-size: 14px;
    font-style: normal;
    font-weight: 600 !important;
    line-height: 150%; /* 21px */
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 3px;
}
.MuiStepLabel-label.Mui-completed, .MuiStepLabel-label.Mui-active {
    color:#219D50 !important;
    stroke: #219D50 !important;
}
.MuiStepLabel-label.MuiStepLabel-alternativeLabel{
    stroke: #eaeaf0;
}