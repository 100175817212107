.nationalIdComponent {
  height: fit-content;
  display: flex;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  border-radius: 10px;
  background: #fff;
  align-self: stretch;
  @media (max-width: 450px) {
    padding: 12px;
  }
  .section-title {
    color: #000;
    font-family: Roboto;
    font-size: clamp(22px, 5vw, 24px);
    font-weight: 600;
    line-height: 150%; /* 36px */
    letter-spacing: 0.36px;
  }
  .nationalId-card-wrapper {
    border-radius: 10px;
    border: 1px solid #ebecff;
    background: #fff;
    display: flex;
    padding: 8px;
    gap: 24px;
    align-self: stretch;
    flex-wrap: wrap;
    .nationalId-box {
      cursor: pointer;
      position: relative;
      border-radius: 10px;
      border: 1px solid #dedede;
      background: #fff;
      display: flex;
      padding: 16px;
      width: calc(50% - 46px);
      gap: 24px;
      align-self: stretch;
      @media (max-width: 1220px) {
        width: calc(100% - 32px);
      }
      &:hover {
        .circular-checkbox {
          background-color: #219d50;
        }
      }
      &.default {
        border: 1px solid #4c9b58;
        background: #f8f8f8;
        .circular-checkbox {
          background-color: #219d50;
        }
      }
      .circular-checkbox {
        border: 1px solid #dedede;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .desc-box {
        width: calc(100% - 38px);
        display: flex;
        flex-direction: column;
        gap: 8px;
        .top {
          display: flex;
          gap: 16px;
          align-items: center;
          .nationalId-name {
            color: #080e26;
            font-family: Roboto;
            font-size: 16px;
            font-weight: 500;
            line-height: 150%; /* 24px */
          }
          .badge {
            display: flex;
            padding: 4px 8px;
            justify-content: center;
            align-items: center;
            border-radius: 4px;
            background: #d6f3e1;
            color: var(--Fruit-Salad-600, #219d50);
            font-family: Roboto;
            font-size: 10px;
            font-weight: 600;
            line-height: 150%; /* 15px */
          }
        }
      }
      .bottom-div {
        display: flex;
        gap: 8px;
        width: 100%;
        .img-wrapper {
          width: 64px;
          height: 64px;
          border-radius: 7.397px;
          border: 0.462px dashed #0a1f8f;
          position: relative;
          overflow: hidden;
          background-repeat: no-repeat;
          background-size: cover;
          background-position: center;
          z-index: 2;
          .overlay-div {
            position: absolute;
            background: rgba(0, 0, 0, 0.311);
            top: 0;
            bottom: 0;
            right: 0;
            left: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            z-index: 10;
          }
        }
      }
      .edit-bttn {
        height: 30px;
        width: 30px;
        position: absolute;
        right: 16px;
        top: 16px;
        color: #219d50;
      }
    }
  }
  .empty-div {
    border-radius: 10px;
    border: 1px solid #dedede;
    background: #fff;
    display: flex;
    padding: 16px 24px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 8px;
    align-self: stretch;
    p {
      color: #717286;
      text-align: center;
      font-family: Roboto;
      font-size: 16px;
      font-weight: 400;
      line-height: 150%; /* 24px */
    }
  }
  .add-nationalId-bttn {
    border-radius: 10px;
    background: #219d50;
    padding: 10px 20px;
    max-height: 36px;
    color: #fff;
    gap: 10px;
    display: flex;
    align-items: center;
    &:hover {
      background: #0c7935;
    }
    @media (max-width: 770px) {
      width: 100%;
    }
    span {
      display: block;
      font-family: Roboto;
      font-size: 14px;
      font-weight: 600;
      text-transform: capitalize;
      white-space: nowrap;
    }
  }
}
