.megamenu-ctageoryimage{
    width: 46.5px;
    height: 37px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    img{
        width: 100%;
        object-fit: contain;
    }
}