.top-logout-div{
    display: flex;
    justify-content: space-between;
    width: 100%;
    p{
        color: #303030;
        font-family: Roboto;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: 150%; /* 36px */
        letter-spacing: 0.36px;
    }
}
.bottom-logout-wrapper{
    border-radius: 10px;
    border: 1px solid #DEDEDE;
    background: #FFF;
    display: flex;
    padding: 24px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 40px;
    align-self: stretch;
    .top{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
          .title{
        color: var(--Main-Black, #1E1E1E);
        text-align: center;
        font-family: Roboto;
        font-size: 20px;
        font-weight: 700;
        line-height: 150%; /* 30px */
    }
    .subtext{
        color: var(--Grey-400, #8F91A1);
        text-align: center;
        font-family: Roboto;
        font-size: 16px;
        font-weight: 400;
        line-height: 150%; /* 24px */
    }  
    }
    .bottom-buttons{
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 16px;
        .logout{
            width: 100%;
            padding: 12px 24px;
            gap: 10px;
            border-radius: 10px;
            background: #CD1D1D;
            color: var(--Main-White, #FFF);
            font-family: Roboto;
            font-size: 18px;
            font-weight: 700;
            text-transform: capitalize;
        }
        .cancel{
            color: #A7A7A7;
            font-family: Roboto;
            font-size: 18px;
            font-weight: 700;
            text-transform: capitalize;
            padding: 12px 20px;
            gap: 10px;
        }
    }

}