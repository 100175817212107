.Hcategory-product-swiper-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 48px;
  align-self: stretch;
  position: relative;
  @media (max-width: 1200px) {
    gap: 24px;
    align-items: center;
  }

  .products {
    width: 100%;
    position: relative;

    .mySwiper {
      position: relative;
      padding-top: 75px;
      @media (max-width: 1200px) {
        padding-top: 50px;
      }
      .top-section {
        position: absolute;
        width: 100%;
        top: 0px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        align-self: stretch;

        .top-buttons {
          display: flex;
          align-items: center;
          gap: 32px;

          .button {
            display: flex;
            padding: 10px 20px;
            justify-content: center;
            align-items: center;
            gap: 10px;
            border-radius: 10px;
            border: 1.5px solid #dadada;
            background: #fff;
            color: var(--Main-Black, #1e1e1e);
            // font-family: Roboto;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            text-transform: capitalize;
            &:hover {
              background: #219d50;
              color: #fff;
            }
            @media (max-width: 1200px) {
              display: none;
            }
          }

          .nav-buttons {
            display: flex;
            align-items: center;
            gap: 10px;

            button {
              width: 48px;
              height: 48px;
              border: 0.8px solid var(--Fruit-Salad-600, #219d50);
              background: #fff;
              border-radius: 50%;
              padding: 0px;
            }

            button:hover {
              background-color: #c7f3da;
            }
          }
        }

        h1 {
          color: var(--Variable-Grey-Text, #5c5f62);
          // font-family: Roboto;
          font-size: 24px;
          font-style: normal;
          font-weight: 500;
          line-height: 130%; /* 31.2px */
          @media (max-width: 960px) {
            font-size: 20px;
            font-weight: 700;
            line-height: 120%; /* 24px */
            letter-spacing: 0.3px;
          }
        }
      }
    }

    .inside-swiper {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 24px;
      position: relative;
      width: 100%;
      cursor: pointer;
      &:hover {
        img {
          scale: 1.1;
        }
      }

      .image-part {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 24px;
        flex: 1 0 0;
        align-self: stretch;
        border-radius: 16px;
        background: #F8F8F8;
        width: 100%;
        min-height: 200px;
        @media (max-width: 480px) {
          min-height: auto;
        }
        img {
          border-radius: 16px;
          transition: 300ms;
          // max-height: 170px;
          width: min-content;
          @media (max-width: 480px) {
            width: 100%;
            max-height: unset;
          }
        }

        button {
          display: flex;
          padding: 10px 20px;
          justify-content: center;
          align-items: center;
          gap: 10px;
          border-radius: 10px;
          background: var(--Main-Green, #219d50);
          position: absolute;
          right: 0px;
          top: 0px;
          border: none;
          color: var(--Main-White, #fff);
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          text-transform: capitalize;
          &:hover {
            scale: 1.1;
          }

          @media (max-width: 1200px) {
            color: var(--Foggy-Gray-0, #fff);
            font-size: 12px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            text-transform: capitalize;
            display: flex;
            padding: 8px 12px;
            justify-content: center;
            align-items: center;
            gap: 4px;
            position: absolute;
            right: 2px;
            border-radius: 4px;
            background: var(--Fruit-Salad-600, #219d50);
          }
        }
      }

      .price-rating {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        gap: 8px;

        .MuiRating-root {
          font-size: 20px;
          color: var(--Fruit-Salad-600, #219d50);
          @media (max-width: 1200px) {
            font-size: 16px;
          }
        }

        p {
          max-height: 60px;
          overflow: hidden;
          color: #3c3c3c;
          font-size: 20px;
          font-style: normal;
          font-weight: 400;
          line-height: 150%; /* 30px */
          letter-spacing: 0.3px;
          margin: 0px;
          text-align: start;
          @media (max-width: 1200px) {
            color: #3c3c3c;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 140%; /* 16.8px */
          }
          @media (max-width: 600px) {
            max-height: unset;
          }
        }

        .price {
          color: var(--Fruit-Salad-600, #219d50);
          font-size: 24px;
          font-style: normal;
          font-weight: 600;
          line-height: 150%; /* 36px */
          letter-spacing: 0.36px;
          @media (max-width: 1200px) {
            color: var(--Fruit-Salad-600, #219d50);
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 140%; /* 22.4px */
          }
        }
      }
    }
  }

  .swiper {
    width: 100%;
  }

  .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;

    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    max-height: 200px;
  }

  .swiper-button-next {
    display: none;
  }

  .swiper-button-prev {
    display: none;
  }
}
