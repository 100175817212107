.singlePresscriptionTable{
    display: flex;
    flex-direction: column;
    width: 100%;
    table{
    width: 100%;
    border-spacing: 0px;
    display: 'table';
    border: 1px solid #F5F5F5;
    border-radius: 0px 0px 10px 10px;
    thead{
        background: #F4F4F4;
        height: 53px;
        @media (max-width: 1100px) {
            display: none;
        }
        td{
            color:  #000;
            font-family: Roboto;
            font-size: 14px;
            font-weight: 600;
            line-height: 150%; /* 21px */
            text-align: left;
            &:first-child{
                padding-left: 40px;
                border-top-left-radius: 10px;
            }
            &:last-child{
               border-top-right-radius: 10px;
            }
            p{
                white-space: nowrap;
            }
        }
    }
    tbody{
        tr{
            max-height: 121px;
            &:last-child{
                .prescription-data-mobile{
                    border-bottom: none;
                }
            }
            td{
                padding: 16px 0px;
                text-align: left;
                &:first-child{
                    padding-left: 16px;
                    max-width: 268px;
                }
                &:last-child{
                    width: 50%;
                    padding-right: 16px;
                }
                .presc-name{
                    padding-left: 16px;
                    color: #000;
                    font-family: Roboto;
                    font-size: 16px;
                    font-weight: 500;
                    line-height: 150%; /* 24px */
                }
                .presc-price{
                    color: #000;
                    font-family: Roboto;
                    font-size: 16px;
                    font-weight: 500;
                    line-height: 150%; /* 24px */
                }
                .presc-details{
                    overflow: hidden;
                    color: #000;
                    text-overflow: ellipsis;
                    font-family: Roboto;
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 150%; /* 24px */
                }
                .prescription-data-mobile{
                    display: flex;
                    align-self: stretch;
                    flex-direction: column;
                    gap: 8px;
                    padding: 16px 0px;
                    border-bottom: 1px solid rgba(0, 0, 0, 0.306);
                    .medication-top{
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        .medication-name{
                            color: #000;
                            font-family: Roboto;
                            font-size: 16px;
                            font-weight: 700;
                            line-height: 140%; /* 22.4px */
                        }
                    }
                    .presc-details{
                        color: #000;
                        font-family: Roboto;
                        font-size: 12px;
                        font-weight: 400;
                    }
                }
            }
        }
    }
}
.empty-single_prescription{
    border-radius: 24px;
    border: 1px solid #EBECFF;
    background: #FFF;
    display: flex;
    padding: 24px;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    span{
        color: #717286;
        text-align: center;
        font-family: Roboto;
        font-size: 16px;
        font-weight: 400;
        line-height: 150%; /* 24px */
        text-align: center;
    }
}
}