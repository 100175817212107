.delivery-banner{
    background-color: #F5F5F1;
    height: 32px;
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    @media (max-width: 1100px) {
      background-color: #FFF;
    }
    @media (max-width: 630px) {
    justify-content:normal;
    }
    .desktop-view{
        display: flex;
        align-items: center;
        justify-content: center;
        .notice{
            color: #219D50;
            text-align: center;
            font-family: Roboto;
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
        }
    }
    .swiper {
            width: 660px;
      .swiper-slide {
        .notice{
            width: fit-content;
            display: block;
            white-space: nowrap;
            color: #219D50;
            text-align: center;
            font-family: Roboto;
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
        }
    }
      .swiper-wrapper {
        -webkit-transition-timing-function:linear!important; 
        -o-transition-timing-function:linear!important;
        transition-timing-function:linear!important; 
      }
}
}