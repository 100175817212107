.Wishlist {
  background: #f8f8f8;
  .main-conatiner {
    display: flex;
    padding: 2.6% 5.2%;
    justify-content: center;
    align-items: center;

    @media (max-width: 450px) {
      padding: 10px;
    }
    @media (max-width: 370px) {
      padding: 0px;
    }
    .wrapper {
      display: flex;
      width: 100%;
      gap: 24px;
      max-width: 1352px;
      .right-div-wishlist {
        height: fit-content;
        display: flex;
        padding: 32px;
        flex-direction: column;
        align-items: flex-start;
        gap: 24px;
        border-radius: 10px;
        background: #fff;
        width: calc(100% - 447px);
        &.disabled {
          opacity: 0.5;
          pointer-events: none;
        }
        @media (max-width: 1100px) {
          width: calc(100% - 64px);
        }
        @media (max-width: 450px) {
          padding: 15px;
          width: calc(100% - 30px);
        }
        @media (max-width: 380px) {
          padding: 6px;
          width: calc(100% - 12px);
        }
        .top {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          p {
            color: #000;
            font-family: Roboto;
            font-size: 24px;
            font-weight: 600;
            line-height: 150%; /* 36px */
            letter-spacing: 0.36px;
            @media (max-width: 380px) {
              padding-left: 30px;
              padding-top: 30px;
            }
          }
          .add-all-bttn {
            color: #219d50;
            font-family: Roboto;
            font-size: 18px;
            font-weight: 700;
            text-transform: capitalize;
            @media (max-width: 450px) {
              display: none;
            }
          }
        }
        .textwrap {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          gap: 24px;
          align-self: stretch;
          .note {
            color: var(--Main-Green, #4c9b58);
            font-family: Gabarito;
            font-size: 46px;
            font-style: normal;
            font-weight: 700;
            line-height: 120%; /* 76.8px */
            letter-spacing: 0.96px;
          }
          .button {
            border-radius: 10px;
            background: var(--Fruit-Salad-600, #219d50);
            display: flex;
            padding: 16px 24px;
            justify-content: center;
            align-items: center;
            gap: 16px;
            color: var(--Foggy-Gray-0, #fff);
            font-family: Roboto;
            font-size: 18px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            text-transform: capitalize;
            &:hover {
              background: #0c7935;
            }
          }
        }
        .add-all-bttn-mobile {
          display: none;
          border-radius: 10px;
          background: rgba(104, 212, 145, 0.27);
          color: #219d50;
          padding: 12px 24px;
          font-family: Roboto;
          font-size: 18px;
          font-weight: 700;
          text-transform: capitalize;
          width: 100%;
          @media (max-width: 450px) {
            display: flex;
          }
        }
      }
    }
  }
}
