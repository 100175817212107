.settingsAddress {
  background: #f8f8f8;
  .main-conatiner {
    display: flex;
    padding: 2.6% 5.2%;
    justify-content: center;
    align-items: center;
    @media (max-width: 450px) {
      padding: 0px;
    }
    .wrapper {
      display: flex;
      width: 100%;
      gap: 24px;
      max-width: 1352px;
      .right-div-settingsAddress {
        height: fit-content;
        display: flex;
        padding: 32px;
        flex-direction: column;
        align-items: flex-start;
        gap: 24px;
        border-radius: 10px;
        background: #fff;
        width: calc(100% - 447px);
        &.disabled {
          opacity: 0.6;
          pointer-events: none;
        }
        @media (max-width: 1100px) {
          width: 100%;
        }
        @media (max-width: 450px) {
          padding: 15px;
        }
        .page-title {
          color: #000;
          font-family: Roboto;
          font-size: 24px;
          font-weight: 600;
          line-height: 150%; /* 36px */
          letter-spacing: 0.36px;
        }
        .address-wrapper {
          width: 100%;
          display: flex;
          flex-direction: column;
          gap: 8px;
          .sect-title {
            color: #595959;
            font-family: Roboto;
            font-size: 14px;
            font-weight: 600;
            line-height: 150%; /* 21px */
            text-align: left;
          }
          .address-section {
            border-radius: 10px;
            border: 1px solid #ebecff;
            background: #fff;
            display: flex;
            padding: 8px;
            flex-direction: column;
            align-items: flex-start;
            gap: 24px;
            align-self: stretch;
            max-height: 210px;
            overflow: auto;
            &::-webkit-scrollbar {
              width: 5px;
            }
            &::-webkit-scrollbar-thumb {
              background: #eee;
              width: 5px;
            }
            .empty-div {
              display: flex;
              justify-content: center;
              align-items: center;
              flex-direction: column;
              gap: 8px;
              padding: 24px 0px;
              width: 100%;
              p {
                color: #717286;
                text-align: center;
                font-family: Roboto;
                font-size: 16px;
                font-weight: 400;
                line-height: 150%; /* 24px */
              }
            }
            .address-box {
              cursor: pointer;
              position: relative;
              border-radius: 10px;
              border: 1px solid #dedede;
              background: #fff;
              display: flex;
              padding: 16px 24px;
              gap: 24px;
              align-self: stretch;
              @media (max-width: 450px) {
                gap: 12px;
              }
              &:hover {
                .circular-checkbox {
                  background-color: #219d50;
                }
              }
              &.default {
                border: 1px solid #4c9b58;
                background: #f8f8f8;
                .circular-checkbox {
                  background-color: #219d50;
                }
              }
              .circular-checkbox {
                border: 1px solid #dedede;
                width: 20px;
                height: 20px;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
                min-width: 20px;
              }
              .desc-box {
                display: flex;
                flex-direction: column;
                gap: 8px;
                .top {
                  display: flex;
                  gap: 8px;
                  p {
                    color: #080e26;
                    font-family: Roboto;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 150%; /* 24px */
                  }
                  .badge {
                    display: flex;
                    padding: 4px 8px;
                    justify-content: center;
                    align-items: center;
                    border-radius: 4px;
                    background: #d6f3e1;
                    color: var(--Fruit-Salad-600, #219d50);
                    font-family: Roboto;
                    font-size: 10px;
                    font-weight: 600;
                    line-height: 150%; /* 15px */
                  }
                }
                .add-street {
                  color: var(--Grey-500, #717286);
                  font-family: Roboto;
                  font-size: 16px;
                  font-weight: 400;
                  line-height: 150%; /* 24px */
                  text-align: left;
                }
              }
              .edit-bttn {
                position: absolute;
                right: 24px;
                top: 16px;
                color: #219d50;
              }
            }
          }
        }
        .add-address-bttn {
          border-radius: 10px;
          background: #219d50;
          color: #fff;
          gap: 10px;
          display: flex;
          align-items: center;
          padding: 10px 20px;
          &:hover {
            background: #0c7935;
          }
          @media (max-width: 450px) {
            width: 100%;
          }
          span {
            font-family: Roboto;
            font-size: 14px;
            font-weight: 600;
            text-transform: capitalize;
          }
        }
      }
    }
  }
}
