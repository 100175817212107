.orders{
    background: #F8F8F8;
    .main-conatiner{
    display: flex;
    padding: 2.6% 5.2%;
    justify-content: center;
    align-items: center;
    @media (max-width: 420px) {
       padding: 0px;
    }
    .wrapper{
    display: flex;
    width: 100%;
    gap: 24px;
    max-width: 1352px;
    .right-orders-div{
        display: flex;
        flex-direction: column;
        width: calc(100% - 383px);
        gap: 24px;
        @media (max-width: 1100px) {
            width: 100%;
          }
        .order-history{
            display: flex;
            padding: 32px;
            flex-direction: column;
            align-items: flex-start;
            gap: 24px;
            align-self: stretch;
            border-radius: 10px;
            background-color: #fff;
            @media (max-width: 450px) {
               padding: 20px;
            }
            .title-div{
                display: flex;
                justify-content: space-between;
                width: 100%;
                @media (max-width: 520px) {
                    flex-direction: column;
                    gap: 20px;
                }
                .left{
                    flex:1;
                    display: flex;
                    align-items: center;
                    gap: 8px;
                p{
                    color: var(--Basic-Black, #000);
                    font-family: Roboto;
                    font-size: 24px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 150%; /* 36px */
                    letter-spacing: 0.36px;
                }
            }
            .search-div{
                display: flex;
                padding: 0px 16px;
                justify-content: flex-start;
                align-items: center;
                gap: 10px;
                flex: 1 ;
                align-self: stretch;
                border-radius: 10px;
                border: 1px solid #DEDEDE;
                input{
                    flex: 5;
                    display: flex;
                    color: #616161;
                    font-family: Roboto;
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 150%; /* 24px */
                    border: none;
                    padding: 12px 0px;
                    &::placeholder{
                        color: #616161;
                        font-family: Roboto;
                        font-size: 16px;
                        font-weight: 400;
                    }
                    &:focus{
                        outline: none;
                    }
                }
            }  
            }
            .sorting-div{
                width: 100%;
                padding: 12px 0px;
                display: flex;
                justify-content: space-between;
                @media (max-width: 565px) {
                    flex-direction: column;
                    gap: 13px;
                }
                .sort-tabs{
                    display: flex;
                    gap: 24px;
                    align-items: center;
                    .tab{
                        cursor: pointer;
                        display: block;
                        color: #A7A7A7;
                        font-family: Roboto;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: normal;
                        text-transform: capitalize;
                        @media (max-width: 350px) {
                            white-space: nowrap;
                        }
                        &.active{
                            color: #106330;
                        }
                    }
                }
                .select{
                    border-radius: 10px;
                    border: 1px solid rgba(0, 0, 0, 0.10);
                    background: #F8F8F8;
                    color:  #219D50;
                    font-family: Roboto;
                    font-size: 12px;
                    font-weight: 600;
                    max-height:34px;
                    @media (max-width: 565px) {
                        max-width:133px;
                    }
                }
            }
        }
        }
    }
}
}