.insurancePopupModal{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50% , -50%);
    display: flex;
    max-width: 699px;
    padding: 24px 16px 16px 16px;
    flex-direction: column;
    align-items: 'flex-start';
    gap: 24px;
    border-radius: 10px;
    background: #FFF;
    width: calc(90% - 32px);

    .top-inusrance-div{
        display: flex;
        justify-content: space-between;
        width: 100%;
        p{
            color: #303030;
            font-family: Roboto;
            font-size: 24px;
            font-style: normal;
            font-weight: 600;
            line-height: 150%; /* 36px */
            letter-spacing: 0.36px;
        }
    }
    label.Mui-focused {
        color: #4C9B58;
      }
    .MuiOutlinedInput-root {
        border-radius: 10px;
        &.Mui-focused fieldset {
          border-color:#4C9B58;
        }
      }
    .img-container-upload{
        display: flex;
        gap: 24px;
        @media (max-width: 780px) {
            flex-direction: column;
            max-height: 280px;
            overflow: auto;
          }
        .insuranceImgBox{
            flex: 1;
            display: flex;
            flex-direction: column;
            gap: 8px;
            .img-wrapper{
                border-radius: 10px;
                border: 1px dashed #4C9B58;
                background: #F8F8F8;
                display: flex;
                padding: 16px 24px;
                flex-direction: column;
                align-items: center;
                gap: 24px;
                align-self: stretch;
                .top-div{
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    gap: 8px;
                    p{
                        text-align: center;
                        color: #219D50;
                        font-family: Roboto;
                        font-size: 16px;
                        font-weight: 500;
                        line-height: 150%; /* 24px */
                        white-space: nowrap;
                    }
                }
                .bottom{
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    gap: 16px;
                    align-items: center;
                    text-align: center;
                    .file-preview{
                        width: 108px;
                        height: 108px;
                        border-radius: 7.397px;
                        border: 1px solid #219D50;
                    position: relative;
                    overflow: hidden;
                    background-repeat: no-repeat;
                    background-size: cover;
                    background-position: center;
                    z-index: 2;
                    .overlay-div{
                        position: absolute;
                        background: rgba(0, 0, 0, 0.311) ;
                        top: 0;
                        bottom: 0;
                        right: 0;
                        left: 0;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        z-index: 10;
                        .deleteImg{
                            width: 31px;
                            height: 31px;
                            border-radius: 7px;
                            background-color: #fff;
                        }
                    }
                    }
                    .input-file{
                        height: 1;
                        overflow: hidden;
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        white-space: nowrap;
                        width: 1;
                        visibility: hidden;
                    }
                    .upload-bttn{
                        border-radius: 10px;
                        background:  rgba(104, 212, 145, 0.27);
                        color: var(--Main-Green, var(--Fruit-Salad-600, #219D50));
                        font-family: Roboto;
                        padding: 16px 20px;
                        max-height: 48px;
                        width: 155px;
                        font-size: 14px;
                        font-weight: 600;
                        text-transform: capitalize;
                        box-shadow: none;
                    }
                    .filename{
                        text-align: center;
                        white-space: nowrap;
                        color: #8F91A1;
                        font-family: Roboto;
                        font-size: 12px;
                        font-weight: 400;
                        line-height: 16px; /* 133.333% */
                        letter-spacing: 0.4px;
                        text-overflow: ellipsis;
                        overflow: hidden;
                    }
                }
            }
            span{
                color: var(--Grey-500, #717286);
                font-family: Roboto;
                font-size: 14px;
                font-weight: 400;
                line-height: 150%; /* 21px */
            }
        }
    }
    .bttns-wrapper{
        display: flex;
        flex-direction: column;
        gap: 16px;
        margin-top: 7px;
        .add-insu{
            border-radius: 10px;
            background: #219D50;
            color:#FFF;
            font-family: Roboto;
            font-size: 18px;
            font-weight: 700;
            text-transform: capitalize;
            &:disabled{
                opacity: 0.7;
            }
        }
        .delete-insu{
            border-radius: 10px;
            border: 1.5px solid #CD1D1D;
            color: #CD1D1D;
            font-family: Roboto;
            font-size: 18px;
            font-weight: 700;
            text-transform: capitalize;
        }
        .cancel{
            color: #A7A7A7;
            font-family: Roboto;
            font-size: 18px;
            font-weight: 700;
            text-transform: capitalize;
        }
    }
}