.dashboard {
  background: #f8f8f8;
  .main-conatiner {
    display: flex;
    padding: 2.6% 5.2%;
    justify-content: center;
    align-items: center;
    @media (max-width: 580px) {
      padding: 10px;
    }
    .wrapper {
      display: flex;
      width: 100%;
      gap: 24px;
      max-width: 1352px;
      .right-div {
        display: flex;
        flex-direction: column;
        width: calc(100% - 383px);
        gap: 24px;
        @media (max-width: 1100px) {
          width: 100%;
        }
        .appointments-section {
          display: flex;
          padding: 32px;
          flex-direction: column;
          align-items: flex-start;
          gap: 24px;
          border-radius: 10px;
          background: #d6f3e1;
          .mobile-bttn {
            display: none;
            border-radius: 10px;
            background: rgba(104, 212, 145, 0.27);
            color: var(--Main-Green, var(--Fruit-Salad-600, #219d50));
            font-family: Roboto;
            font-size: 18px;
            font-weight: 700;
            text-transform: capitalize;
            width: 100%;
            &.appointment {
              background-color: transparent;
            }
            @media (max-width: 1100px) {
              display: block;
            }
          }
          .title-div {
            display: flex;
            justify-content: space-between;
            width: 100%;
            .left {
              display: flex;
              align-items: center;
              gap: 8px;
              p {
                color: var(--Basic-Black, #000);
                font-family: Roboto;
                font-size: 24px;
                font-style: normal;
                font-weight: 600;
                line-height: 150%; /* 36px */
                letter-spacing: 0.36px;
              }
            }
            .view-all {
              color: #219d50;
              font-family: Roboto;
              font-size: 18px;
              font-style: normal;
              font-weight: 700;
              line-height: normal;
              text-transform: capitalize;
              border: none;
              background-color: transparent;
              @media (max-width: 1100px) {
                display: none;
              }
              &:hover {
                background-color: #219d50;
                color: white;
              }
            }
          }
          .product-boxes-wrapper {
            width: 100%;
            display: flex;
            gap: 1.75%;
            @media (max-width: 1100px) {
              flex-direction: column;
              padding-bottom: 24px;
            }
            .product-box-recently {
              flex: 1;
              display: flex;
              padding: 24px 16px;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              gap: 16px;
              border-radius: 10px;
              border: 1px solid #dedede;
              background: #f8f8f8;
              .img-wrapper {
                width: 83px;
                img {
                  width: 100%;
                }
              }
              .bottom-reviewed-div {
                width: 100%;
                display: flex;
                justify-content: space-between;
                .left {
                  display: flex;
                  align-items: flex-start;
                  flex-direction: column;
                  .product-name {
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    max-width: 120px;
                    overflow: hidden;
                    color: #393939;
                    font-family: Roboto;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 150%; /* 27px */
                    letter-spacing: 0.27px;
                  }
                  .product-details {
                    color: #5c5f62;
                    font-family: Roboto;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 16px; /* 133.333% */
                    letter-spacing: 0.4px;
                  }
                }
                .right {
                  display: flex;
                  flex-direction: column;
                  align-items: flex-start;
                  .product-price {
                    color: #171717;
                    font-family: Roboto;
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 150%; /* 30px */
                  }
                  .product-stocking {
                    color: #aaabad;
                    font-family: Roboto;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 150%; /* 21px */
                  }
                }
              }
              .quantity-product-box {
                display: flex;
                justify-content: space-between;
                width: 100%;
              }
              .reorder-bttn {
                display: flex;
                padding: 10px 0px;
                width: 100%;
                justify-content: center;
                align-items: center;
                gap: 10px;
                border-radius: 10px;
                background: #219d50;
                color: #fff;
                font-family: Roboto;
                font-size: 14px;
                font-weight: 600;
                text-transform: capitalize;
                max-height: 36px;
                &:hover {
                  background-color: #0c7935;
                }
              }
            }
          }
        }
        .reorder-section {
          display: flex;
          padding: 40px 24px;
          flex-direction: column;
          align-items: flex-start;
          gap: 24px;
          border-radius: 10px;
          background: #fff;
          @media (max-width: 370px) {
            padding: 40px 0px;
          }
          .mobile-bttn {
            display: none;
            border-radius: 10px;
            background: rgba(104, 212, 145, 0.27);
            color: var(--Main-Green, var(--Fruit-Salad-600, #219d50));
            font-family: Roboto;
            font-size: 18px;
            font-weight: 700;
            text-transform: capitalize;
            width: 100%;
            @media (max-width: 1100px) {
              display: block;
            }
          }
          .title-div {
            display: flex;
            justify-content: space-between;
            width: 100%;
            .left {
              display: flex;
              align-items: center;
              gap: 8px;
              p {
                color: var(--Basic-Black, #000);
                font-family: Roboto;
                font-size: 24px;
                font-style: normal;
                font-weight: 600;
                line-height: 150%; /* 36px */
                letter-spacing: 0.36px;
              }
            }
            .view-all {
              color: #219d50;
              font-family: Roboto;
              font-size: 18px;
              font-style: normal;
              font-weight: 700;
              line-height: normal;
              text-transform: capitalize;
              border: none;
              background-color: transparent;
              &:hover {
                background: #219d50;
                color: #fff;
              }
              @media (max-width: 1100px) {
                display: none;
              }
            }
          }
        }
      }
    }
  }
}
