.prescription-info-comp {
  display: flex;
  padding: 32px;
  gap: 24px;
  border-radius: 10px;
  background: #fff;
  @media (max-width: 580px) {
    padding: 20px;
  }
  @media (max-width: 1100px) {
    flex-direction: column;
  }
  .box {
    display: flex;
    padding: 24px;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    flex: 1 0 0;
    border-radius: 10px;
    border: 1px solid #dedede;
    background: #f8f8f8;
    .presc-box-title {
      color: var(--Basic-Black, #000);
      font-family: Roboto;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 150%; /* 27px */
      letter-spacing: 0.27px;
    }
    .box-bottom {
      align-self: stretch;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .circle {
        background-color: #fff;
        border-radius: 50%;
        width: 64px;
        height: 64px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .view-all{
        color: #0c7935;
        background-color: transparent;
        justify-self: flex-end;
        text-transform: capitalize;
      }
      .number-info-cont {
        display: flex;
        flex-direction: column;
        gap: 8px;
        .presc-count {
          color: #000;
          font-family: Gabarito;
          font-size: 18px;
          font-style: normal;
          font-weight: 500;
          line-height: 120%; /* 57.6px */
          text-align: left;
        }
        .count-desc {
          align-items: center;
          display: flex;
          p {
            color: var(--Basic-Black, #000);
            font-family: Roboto;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 150%; /* 24px */
          }
        }
      }
    }
    .scroll-div {
      border-radius: 10px;
      border: 1px solid #dedede;
      background: #fff;
      display: flex;
      padding: 12px;
      flex-direction: column;
      align-self: stretch;
      gap: 4px;
      max-height: 141px;
      overflow: auto;
      &::-webkit-scrollbar {
        width: 5px;
      }
      &::-webkit-scrollbar-thumb {
        background: #eee;
        width: 5px;
      }
      .presc-components {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 12px;
        border-radius: 6px;
        &.filled {
          background: #f8f8f8;
        }
        &:hover {
          background: #f8f8f8;
        }
        .presc-details {
          display: flex;
          align-items: flex-start;
          flex-direction: column;
          gap: 8px;
          .name {
            color: #000;
            font-family: Roboto;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 140%; /* 19.6px */
          }
          .desc {
            display: flex;
            gap: 4px;
            color: #000;
            font-family: Roboto;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 140%; /* 16.8px */
          }
        }
        .pay-bttn {
          border-radius: 10px;
          background: #219d50;
          color: #fff;
          font-family: Roboto;
          font-size: 14px;
          font-weight: 600;
          text-transform: capitalize;
          &:hover {
            background: #0c7935;
          }
        }
        .refill-bttn {
          border-radius: 10px;
          border: 1.5px solid #dadada;
          background: #fff;
          color: #1e1e1e;
          font-family: Roboto;
          font-size: 14px;
          font-weight: 600;
          text-transform: capitalize;
        }
      }
      .empty-div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .icon {
          font-size: 48px;
          color: #717286;
        }
        span {
          color: #717286;
          text-align: center;
          font-family: Roboto;
          font-size: 16px;
          font-weight: 400;
          line-height: 150%; /* 24px */
        }
      }
    }
  }
}
