.settingsInsurance{
    background: #F8F8F8;
    .main-conatiner{
    display: flex;
    padding: 2.6% 5.2%;
    justify-content: center;
    align-items: center;
    @media (max-width: 450px) {
        padding: 0px;
      }
    .wrapper{
    display: flex;
    width: 100%;
    gap: 24px;
    max-width: 1352px;
    .right-div-settingsInsurance{
        display: flex;
        flex-direction: column;
        width: calc(100% - 447px);
        gap: 24px;
        @media (max-width: 1100px) {
            width: 100%;
          }
    }
    }
}
}