.settingsPayments {
  background: #f8f8f8;
  .main-conatiner {
    display: flex;
    padding: 2.6% 5.2%;
    justify-content: center;
    align-items: center;
    @media (max-width: 860px) {
      padding: 20px;
    }
    @media (max-width: 450px) {
      padding: 0px;
    }
    .wrapper {
      display: flex;
      width: 100%;
      gap: 24px;
      max-width: 1352px;
      .right-div-settingsPayments {
        height: fit-content;
        display: flex;
        padding: 32px;
        flex-direction: column;
        align-items: flex-start;
        gap: 24px;
        border-radius: 10px;
        background: #fff;
        width: calc(100% - 447px);
        &.disabled {
          opacity: 0.5;
          pointer-events: none;
        }
        @media (max-width: 1100px) {
          width: calc(100% - 64px);
        }
        @media (max-width: 450px) {
          padding: 20px;
          width: calc(100% - 40px);
        }
        .page-title {
          color: #000;
          font-family: Roboto;
          font-size: 24px;
          font-weight: 600;
          line-height: 150%; /* 36px */
          letter-spacing: 0.36px;
        }
        .address-wrapper {
          width: 100%;
          display: flex;
          flex-direction: column;
          gap: 8px;
          .address-section {
            border-radius: 10px;
            border: 1px solid #ebecff;
            background: #fff;
            display: flex;
            padding: 8px;
            flex-wrap: wrap;
            gap: 8px;
            align-self: stretch;
            @media (max-width: 680px) {
              flex-direction: column;
              flex-wrap: nowrap;
              overflow: auto;
              max-height: 322px;
            }
            .empty-div {
              display: flex;
              justify-content: center;
              align-items: center;
              flex-direction: column;
              gap: 8px;
              padding: 24px 0px;
              width: 100%;
              p {
                color: #717286;
                text-align: center;
                font-family: Roboto;
                font-size: 16px;
                font-weight: 400;
                line-height: 150%; /* 24px */
              }
            }
            .payment-box {
              cursor: pointer;
              position: relative;
              border-radius: 10px;
              border: 1px solid #dedede;
              background: #fff;
              display: flex;
              padding: 24px;
              width: calc(50% - 56px);
              gap: 16px;
              align-self: stretch;
              @media (max-width: 680px) {
                width: calc(100% - 50px);
              }
              &:hover {
                .circular-checkbox {
                  background-color: #219d50;
                }
              }
              &.default {
                border: 1px solid #4c9b58;
                background: #f8f8f8;
                .circular-checkbox {
                  background-color: #219d50;
                }
              }
              .circular-checkbox {
                border: 1px solid #dedede;
                width: 20px;
                height: 20px;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
              }
              .desc-box {
                width: calc(100% - 38px);
                display: flex;
                flex-direction: column;
                gap: 24px;
                .top {
                  display: flex;
                  gap: 16px;
                  align-items: center;
                  .img-wrapper {
                    width: 49px;
                    height: 15px;
                    img {
                      width: 100%;
                      object-fit: contain;
                    }
                  }
                  .badge {
                    display: flex;
                    padding: 4px 8px;
                    justify-content: center;
                    align-items: center;
                    border-radius: 4px;
                    background: #d6f3e1;
                    color: var(--Fruit-Salad-600, #219d50);
                    font-family: Roboto;
                    font-size: 10px;
                    font-weight: 600;
                    line-height: 150%; /* 15px */
                  }
                }
                .card-number {
                  text-align: left;
                  color: #080e26;
                  font-family: Roboto;
                  font-size: 16px;
                  font-weight: 500;
                  line-height: 150%; /* 24px */
                }
              }
              .bottom-div {
                display: flex;
                flex-direction: column;
                gap: 8px;
                width: 100%;
                .card-details {
                  width: 100%;
                  display: flex;
                  justify-content: space-between;
                  p {
                    color: var(--Grey-500, #717286);
                    font-family: Roboto;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 150%; /* 21px */
                  }
                }
              }
              .edit-bttn {
                height: 30px;
                width: 30px;
                position: absolute;
                right: 24px;
                top: 24px;
                color: #219d50;
              }
            }
          }
        }
        .add-payment-bttn {
          border-radius: 10px;
          background: #219d50;
          color: #fff;
          gap: 10px;
          display: flex;
          align-items: center;
          &:hover {
            background: #0c7935;
          }
          @media (max-width: 680px) {
            width: 100%;
          }
          span {
            display: block;
            font-family: Roboto;
            font-size: 14px;
            font-weight: 600;
            text-transform: capitalize;
            white-space: nowrap;
          }
        }
      }
    }
  }
}
