.reorder-popup.MuiBox-root{
    @media (max-width: 380px) {
        padding: 24px 12px 32px 12px;
        width: calc(90% - 24px);
    }
.top-div-reorder{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    .texts-div{
        display: flex;
        flex-direction: column;
        gap: 8px;
        p{
            color: #303030;
            font-family: Roboto;
            font-size: clamp(20px,4vw,28px);
            font-weight: 800;
            line-height: 150%; /* 42px */
            letter-spacing: 0.42px;
        }
        span{
            color: #616161;
            font-family: Roboto;
            font-size: 16px;
            font-weight: 400;
            line-height: 150%; /* 24px */
        }
    }
}
.reorders-productsWrapper{
    padding: 16px;
    border-radius: 10px;
    border: 1px solid #F5F5F5;   
    overflow: auto;
    align-self: stretch;
    max-height: 450px;
    height: 60.5%;
    @media (max-width: 402px) {
        padding: 4px;
    }
    &::-webkit-scrollbar {
        width: 5px;
      }
      &::-webkit-scrollbar-thumb {
        background: #219d5183;
        width: 5px;
      }
    .reorder-product-box{
        padding: 10px;
        display: flex;
        align-items: center;
        gap: 10px;
        .img-wrapper{
            border-radius: 5.208px;
            border: 1.042px solid #F8F8F8;
            background: #F8F8F8;
            width: 100px;
            height: 100px;
            img{
                width: 100%;
            }
        }
        .reorder-product-info{
            display: flex;
            flex-direction: column;
            gap: 10px;
            width: calc(100% - 110px);
            .product-name{
                color: #393939;
                font-family: Roboto;
                font-size: 16px;
                font-weight: 500;
                line-height: 150%; /* 24px */
            }
            .reorder-productInfo{
                display: flex;
                justify-content: space-between;
                width: 100%;
                .left{
                    display: flex;
                    gap: 10px;
                    .price{
                        color: var(--Main-Green, var(--Fruit-Salad-600, #219D50));
                        font-family: Roboto;
                        font-size: 24px;
                        font-weight: 600;
                        line-height: 150%; /* 36px */
                        letter-spacing: 0.36px;
                    }
                
                }
                .delete-bttn{
                    border-radius: 10px;
                    background: #F8F8F8;
                    width: 40px;
                    height: 40px;
                    color: #000000;
                }
            }
            .product-bttn-div{
                display: flex;
                gap: 12px;
                @media (max-width: 570px) {
                    flex-direction: column;
                }
                .product-detail{
                    flex: 1;
                    border-radius: 10px;
                    border: 1.5px solid #DADADA;
                    background: #FFF;
                    color:  #1E1E1E;
                    font-family: Roboto;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;
                    text-transform: capitalize;
                    &:disabled{
                        opacity: 0.6;
                        pointer-events: none;
                    }
                }
                .product-added-to-cart{
                    flex: 1;
                    border-radius: 10px;
                    background: rgba(104, 212, 145, 0.27);
                    color:  #219D50;
                }
                .product-add-to-cart{
                    flex: 1;
                    border-radius: 10px;
                    background: #219D50;
                    color:  #fff;
                    &:disabled{
                        opacity: 0.6;
                        pointer-events: none;
                    }
                }
            }
        }
    }
}
.reorder-bottom-div{
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;
    p{
        text-align: left;
        color: #909090;
        font-family:  Roboto;
        font-size: clamp(14px,2vw,16px);
        font-weight: 400;
        line-height: 150%; /* 24px */
    }
    .buttons-div{
        display: flex;
        flex-direction: column;
        gap: 12px;
        .addToCart-reorder{
            padding: 12px 24px;
            border-radius: 10px;
            background: #219D50;
            color:  #FFF;
            font-family: Roboto;
            font-size: 18px;
            font-weight: 700;
            line-height: normal;
            text-transform: capitalize;
            &:disabled{
                pointer-events: none;
                opacity: 0.4;
            }
        }
        .RemoveAllCart{
            border-radius: 10px;
            border: 1.5px solid #CD1D1D;
            color: #CD1D1D;
            font-family: Roboto;
            font-size: 18px;
            font-weight: 700;
            text-transform: capitalize;
        }
        .cancel-bttn{
            color: #A7A7A7;
            font-family: Roboto;
            font-size: 18px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            text-transform: capitalize;
        }
    }
}
}