.dasboard-Navigation {
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 359px;
  @media (max-width: 1100px) {
    display: none;
  }
  .top-userInfo {
    border-radius: 10px;
    background: #fff;
    padding: 12px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    .user-top {
      display: flex;
      gap: 16px;
      .text-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding-bottom: 8px;
        .user-name {
          text-align: left;
          color: #219d50;
          font-family: Roboto;
          font-size: 24px;
          font-style: normal;
          font-weight: 600;
          line-height: 150%;
          letter-spacing: 0.36px;
        }
        .user-email {
          overflow: hidden;
          color: #000;
          text-overflow: ellipsis;
          font-family: Roboto;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 150%; /* 21px */
          text-align: left;
        }
      }
    }
    .MuiPaper-root.MuiAccordion-root::before {
      opacity: 0 !important;
    }
    .MuiPaper-root.MuiAccordion-root {
      box-shadow: none !important;
      .MuiButtonBase-root {
        transition: 300ms;
        &:hover {
          opacity: 0.8;
        }
      }
    }
    .MuiAccordionSummary-content {
      flex-grow: initial !important;
      color: #219d50;
      font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      text-transform: capitalize;
    }
    .MuiSvgIcon-root {
      color: #219d50;
    }
    .MuiPaper-root.MuiAccordion-root.Mui-expanded {
      margin: 0px !important;
    }
    .main-txt {
      overflow: hidden;
      color: var(--Basic-Black, #00000075);
      text-overflow: ellipsis;
      font-family: Roboto;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%; /* 24px */
    }
    .sub-txt {
      overflow: hidden;
      color: var(--Basic-Black, #000);
      text-align: right;
      text-overflow: ellipsis;
      font-family: Roboto;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 150%; /* 24px */
    }
  }
}
