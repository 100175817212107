.TestimonialsSwiper {
  display: flex;
  flex-direction: column;
  gap: 24px;

  .testimonails {
    width: 100%;
    @media (max-width: 1200px) {
      padding-left: 24px;
      width: calc(100% - 24px);
    }
    .box {
      display: flex;
      padding: 34px;
      flex-direction: column;
      align-items: flex-start;
      gap: 10px;
      align-self: stretch;
      border-radius: 16px;
      background: #fff;
      justify-content: space-between;
      gap: 16px;
      min-height: 350px;
      @media (max-width: 960px) {
        padding: 24px 16px;
        min-height: auto;
      }
      .note {
        color: var(--Black-500, #6d6d6d);
        // font-family: Roboto;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; /* 24px */
        text-align: left;
        white-space: pre-wrap;
        @media (max-width: 960px) {
          font-size: 14px;
          font-weight: 700;
        }
      }
      .author {
        display: flex;
        align-items: center;
        gap: 16px;
        // flex: 1 0 0;
        @media (max-width: 960px) {
          align-self: flex-start;
        }
        .name {
          display: flex;
          align-items: flex-start;
          gap: 16px;
          flex: 1 0 0;
          flex-direction: column;
          .stars {
            display: flex;
            align-items: flex-start;
            gap: 5.926px;
            @media (max-width: 960px) {
              align-self: flex-end;
            }
            .css-iljtu2-MuiRating-root {
              width: 20px;
              height: 20px;
              color: #219d50;
            }
          }
          p {
            color: #16171a;
            // font-family: Roboto;
            font-size: 24px;
            font-style: normal;
            font-weight: 600;
            line-height: 150%; /* 36px */
            letter-spacing: 0.36px;
            @media (max-width: 960px) {
              font-size: 18px;
              font-weight: 700;
            }
          }
        }
        img {
          max-width: 100px;
          max-height: 100px;
          @media (max-width: 960px) {
            max-width: 50px;
            max-height: 50px;
          }
        }
      }
    }
    .buttons {
      margin-top: 50px;
      display: flex;
      justify-content: center;
      gap: 16px;
      img {
        transition: 300ms;
        &:hover {
          scale: 1.1;
        }
      }
    }
  }
  .swiper {
    width: 100%;
    max-width: 1300px;
    @media (max-width: 960px) {
      max-width: 100vw;
    }
  }

  .swiper-wrapper {
    margin-top: 30px;
  }

  .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 16px;
  }

  .swiper-button-next {
    display: flex;
    align-items: center;
    justify-content: center;
    // top: var(--swiper-navigation-top-offset, 45%) !important;
    bottom: 0;
    width: 40px !important;
    height: 40px !important;
    border-radius: 50%;
    background-color: #fff;
    border: 1px solid #219d50;
  }

  .swiper-button-prev {
    display: flex;
    align-items: center;
    justify-content: center;
    // top: var(--swiper-navigation-top-offset, 45%) !important;
    bottom: 0;
    width: 40px !important;
    height: 40px !important;
    border-radius: 50%;
    background-color: #fff;
    border: 1px solid #219d50;
  }

  .swiper-button-next:after {
    font-size: 18px !important;
    color: #219d50;
  }

  .swiper-button-prev:after {
    font-size: 18px !important;
    color: #219d50;
  }

  // .swiper-button-prev,
  // .swiper-rtl .swiper-button-next {
  //   left: 0px !important;
  //   right: auto;
  // }

  // .swiper-button-next,
  // .swiper-rtl .swiper-button-prev {
  //   right: 0px !important;
  //   left: auto;
  // }
}
