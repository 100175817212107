.thank-you-presc-submittion{
    display: flex;
    padding: 0px 40px 40px 40px;
    flex-direction: column;
    align-items: center;
    gap: 34px;
    width: calc(100% - 463px);
    border-radius: 24px;
    background: #FFF;
    @media (max-width: 1100px) {
        width: calc(100% - 80px);
      }
      @media (max-width: 580px) {
        padding: 0px 20px 40px 20px;
        width: 100%;
      }
      .thanks-img{
        max-width: 90%;
      }
    .bottom-summary{
        display: flex;
        flex-direction: column;
        gap: 24px;
        justify-content: center;
        align-items: center;
        .top{
            display: flex;
            flex-direction: column;
            gap: 6px;
            .title{
                color:  #122146;
                font-family: Gabarito;
                font-size: 32px;
                font-style: normal;
                font-weight: 700;
                line-height: 120%;
            }
            .prescriptionId{
                color:  #000;
                font-family: Roboto;
                font-size: 24px;
                font-weight: 500;
                line-height: 130%; /* 31.2px */
                display: flex;
                flex-wrap: wrap;
                span{
                    padding-right: 7px;
                    display: block;
                }
            }
        }
        .mssg{
            color: var(--gray, #5F7586);
            text-align: center;
            font-family: Roboto;
            font-size: 16px;
            font-weight: 400;
            line-height: 150%; /* 24px */
            max-width: 646px;
        }
        .returnbttn{
            color: #FFF;
            font-family: Roboto;
            font-size: 14px;
            font-weight: 600;
            text-transform: capitalize;
            border-radius: 10px;
            background:  #219D50;
            max-height: 36px;
            width: fit-content;
            padding: 10px 20px;
            &.white{
                border: 1.5px solid #DADADA;
                color: #1E1E1E;
                background-color: transparent;
            }
            @media (max-width: 580px) {
                width: calc(100% - 40px);
              }
        }
    }
}