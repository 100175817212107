.minorAliments {
  background: #f8f8f8;
  .main-conatiner {
    display: flex;
    padding: 2.6% 5.2%;
    justify-content: center;
    align-items: center;
    .wrapper {
      display: flex;
      width: 100%;
      gap: 24px;
      max-width: 1352px;
      .right-div-minors {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 24px;
        border-radius: 10px;
        width: calc(100% - 383px);
        @media (max-width: 1100px) {
          width: 100%;
        }
        .minor-services {
          display: flex;
          padding: 24px;
          flex-direction: column;
          gap: 24px;
          align-self: stretch;
          border-radius: 10px;
          background: #fff;
          .top-div {
            display: flex;
            gap: 8px;
            p {
              color: #000;
              font-family: Roboto;
              font-size: clamp(20px, 4vw, 24px);
              font-weight: 600;
              line-height: 150%; /* 36px */
              letter-spacing: 0.36px;
              text-align: left;
            }
          }
          .services-boxes-wrapper {
            display: flex;
            gap: 24px;
            @media (max-width: 1280px) {
              flex-direction: column;
            }
            .box {
              flex: 1;
              border-radius: 10px;
              border: 1px solid #dedede;
              background: #f8f8f8;
              padding: 24px 16px;
              display: flex;
              flex-direction: column;
              align-items: center;
              gap: 16px;
              .top {
                display: flex;
                flex-direction: column;
                gap: 8px;
                p {
                  color: #000;
                  text-align: center;
                  font-family: Roboto;
                  font-size: 18px;
                  font-weight: 500;
                  line-height: 150%; /* 27px */
                  letter-spacing: 0.27px;
                }
                span {
                  color: #717286;
                  text-align: center;
                  font-family: Roboto;
                  font-size: 14px;
                  font-weight: 400;
                  line-height: 150%; /* 21px */
                  max-width: 275px;
                }
              }
              .book-bttn {
                display: flex;
                padding: 10px 20px;
                justify-content: center;
                align-items: center;
                gap: 10px;
                border-radius: 10px;
                background: #219d50;
                color: #fff;
                font-family: Roboto;
                font-size: 14px;
                font-weight: 600;
                text-transform: capitalize;
                &:hover {
                  background: #0c7935;
                }
              }
              .vacine-selection-div {
                max-height: 46px;
                display: flex;
                gap: 10px;
                border-radius: 10px;
                border: 1px solid #dedede;
                background: #fff;
                box-shadow: 0px 0px 16px 0px rgba(234, 76, 137, 0.06);
                padding: 10px;
                align-self: stretch;
                align-items: center;
              }
            }
          }
          .minor-assessment {
            display: flex;
            padding: 24px 30px;
            align-items: center;
            align-self: stretch;
            border-radius: 10px;
            border: 1px solid #dedede;
            background: #f8f8f8;
            gap: 24px;
            @media (max-width: 1380px) {
              flex-direction: column;
            }
            .left-text-div {
              flex: 1;
              display: flex;
              flex-direction: column;
              gap: 8px;
              text-align: left;
              p {
                color: #000;
                font-family: Roboto;
                font-size: 18px;
                font-weight: 500;
                line-height: 150%; /* 27px */
                letter-spacing: 0.27px;
              }
              span {
                color: #717286;
                font-family: Roboto;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 150%; /* 21px */
              }
            }
            .right-assessment-select {
              flex: 1.8;
              display: flex;
              max-height: 40px;
              padding: 10px;
              align-items: center;
              gap: 10px;
              border-radius: 10px;
              border: 1px solid #dedede;
              background: #fff;
              box-shadow: 0px 0px 16px 0px rgba(234, 76, 137, 0.06);
              @media (max-width: 890px) {
                width: 100%;
              }
            }
          }
          .select-vac-div {
            text-align: left;
            flex: 1;
            @media (max-width: 890px) {
              width: 100%;
            }
            .select-label {
              color: #333;
              font-family: Roboto;
              font-size: 16px;
              font-weight: 400;
              line-height: 18px; /* 112.5% */
            }
            .MuiOutlinedInput-notchedOutline {
              border: none !important;
            }
            .MuiSvgIcon-root.MuiSelect-icon {
              background-color: #68d49145;
              border-radius: 50%;
              color: #219d50;
              transition: 300ms;
              &:hover {
                color: #fff;
              }
            }
          }
          .book-vac {
            flex: 1;
            color: #fff;
            font-family: Roboto;
            font-size: 14px;
            font-weight: 600;
            text-transform: capitalize;
            border-radius: 10px;
            background: #219d50;
            padding: 10px 20px;
            white-space: nowrap;
            max-height: 40px;
            max-width: 165px;
            &:hover {
              background: #0c7935;
            }
            &.disabled {
              opacity: 0.5;
              pointer-events: none;
            }
            @media (max-width: 1280px) {
              display: none;
            }
            &:disabled {
              opacity: 0.5;
            }
          }
          .book-vac-mobile {
            display: none;
            flex: 1;
            color: #fff;
            font-family: Roboto;
            font-size: 14px;
            font-weight: 600;
            text-transform: capitalize;
            border-radius: 10px;
            background: #219d50;
            padding: 10px 0px;
            width: 100%;
            white-space: nowrap;
            max-height: 40px;
            @media (max-width: 1280px) {
              display: flex;
            }
            &:disabled {
              opacity: 0.5;
            }
          }
        }
      }
    }
  }
}
