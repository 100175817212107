.profile-section{
    display: flex;
    padding: 24px;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    align-self: stretch;
    border-radius: 10px;
    background: #FFF;
    h1{
        color: #000;
        font-family: Roboto;
        font-size: 24px;
        font-weight: 600;
        line-height: 150%; /* 36px */
        letter-spacing: 0.36px;
    }
.notSetting-container{
    display: flex;
   justify-content: space-between;
   align-items: flex-start;
   align-self: stretch;
   text-align: left;
   padding-bottom: 16px;
   border-bottom: 1px solid  rgba(0, 0, 0, 0.14) ; 
   &:last-child{
    border-bottom: none;
   }
   .left-wrapper{
    display: flex;
    flex-direction: column;
    .setting-title{
        color: #4C9B58;
        font-family: Roboto;
        font-size: 16px;
        font-weight: 500;
        line-height: 150%; /* 24px */
    }
    span{
        color: #717286;
        font-family: Roboto;
        font-size: 16px;
        font-weight: 400;
        line-height: 150%; /* 24px */
    }
   }
   .MuiSwitch-switchBase.Mui-checked{
    color:#219D50;
    &:hover{
      background-color: #219d510b,
    }
  }
   .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track {
    background-color:#219D50;
  }
}
}