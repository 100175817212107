.writeNote-popup{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    padding: 24px 16px 16px 16px;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    width: 90%;
    max-width: 796px;
    border-radius: 10px;
    background: #FFF;
    &.success{
       .buttons-div, .textarea{
            display: none;
        }
        .top-div{
            justify-content: flex-end;
            .left-div{
                display: none;
            }
        }
        .success-div{
            display: flex;
        }
    }
    .top-div{
        display: flex;
        justify-content: space-between;
        align-items: center;
        align-self: stretch;
        .left-div{
            display: flex;
            align-items: center;
            gap: 20px;
            p{
                color: #303030;
                font-family: Roboto;
                font-size: 24px;
                font-style: normal;
                font-weight: 600;
                line-height: 150%; /* 36px */
                letter-spacing: 0.36px;
            }
            .doctor-badge{
                display: flex;
                justify-content: center;
                align-items: center;
                width: 186px;
                padding: 4px 12px;
                gap: 6px;
                border-radius: 8px;
                border: 1px solid #DEDEDE;
                background: rgba(104, 212, 145, 0.27);
                span{
                    color: #1E1E1E;
                    font-family: Roboto;
                    font-size: 14px;
                    font-weight: 600;
                    line-height: 150%; /* 21px */
                }
            }
        }
    }
    .buttons-div{
        display: flex;
        gap: 12px;
        flex-direction: column;
        width: 100%;
        .send-chatMessage{
            border-radius: 10px;
            background:#219D50;
            color:#FFF;
            font-family: Roboto;
            font-size: 14px;
            font-weight: 600;
            text-transform: capitalize;
            height: 48px;
        }
        .cancel-bttn{
            color: #1E1E1E;
            font-family: Roboto;
            font-size: 14px;
            font-weight: 600;
            text-transform: capitalize;
        }
    }
    .success-div{
        display: none;
        flex-direction: column;
        justify-content: center;
        gap: 18px;
        align-items: center;
        p{
            color: #303030;
            font-family: Roboto;
            font-size: 24px;
            font-weight: 600;
            line-height: 150%; /* 36px */
            letter-spacing: 0.36px;
        }
        .back-bttn{
            color:#1E1E1E;
            font-family: Roboto;
            font-size: 14px;
            font-weight: 600;
            text-transform: capitalize;
        }
    }
}