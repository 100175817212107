.info-div {
  display: flex;
  flex-direction: column;
  gap: 18px;
  flex: 1;
  &.disabled {
    opacity: 0.7;
    pointer-events: none;
  }
  .info-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-items: center;
    align-self: stretch;
    p {
      text-align: left;
      color: #303030;
      font-family: Roboto;
      font-size: clamp(18px, 3vw, 20px);
      font-weight: 700;
      line-height: 150%; /* 30px */
    }
    .useAnother-address {
      border-radius: 10px;
      background: rgba(104, 212, 145, 0.27);
      display: flex;
      padding: 10px 20px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      color: #219d50;
      font-family: Roboto;
      font-size: 14px;
      font-weight: 600;
      text-transform: capitalize;
      &:disabled {
        pointer-events: none;
        opacity: 0.5;
      }
      @media (max-width: 390px) {
        padding: 8px 12px;
        font-size: 12px;
      }
      &:hover {
        background-color: #219d50;
        color: #fff;
      }
    }
  }
  .address-boxes-wrapper {
    padding: 16px;
    border-radius: 10px;
    border: 1px solid #dedede;
    display: flex;
    flex-direction: column;
    gap: 16px;
    overflow: auto;
    height: 244px;
    @media (max-width: 390px) {
      padding: 12px;
    }
    &::-webkit-scrollbar {
      width: 5px;
    }
    &::-webkit-scrollbar-thumb {
      background: #eee;
      width: 5px;
    }
    .address-box {
      cursor: pointer;
      position: relative;
      border-radius: 10px;
      border: 1px solid #dedede;
      background: #fff;
      display: flex;
      padding: 16px 24px;
      gap: 24px;
      align-self: stretch;
      &:hover {
        .circular-checkbox {
          background-color: #219d50;
        }
      }
      &.default {
        border: 1px solid #4c9b58;
        background: #f8f8f8;
        .circular-checkbox {
          background-color: #219d50;
        }
      }
      .circular-checkbox {
        border: 1px solid #dedede;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .desc-box {
        width: calc(100% - 44px);
        display: flex;
        flex-direction: column;
        gap: 8px;
        .top {
          display: flex;
          gap: 8px;
          p {
            max-width: 181px;
            height: 24px;
            overflow: hidden;
            text-overflow: ellipsis;
            color: #080e26;
            font-family: Roboto;
            text-align: left;
            font-size: clamp(14px, 4vw, 16px);
            font-style: normal;
            font-weight: 500;
            line-height: 150%; /* 24px */
          }
          .badge {
            display: flex;
            padding: 4px 8px;
            justify-content: center;
            align-items: center;
            border-radius: 4px;
            background: #d6f3e1;
            color: var(--Fruit-Salad-600, #219d50);
            font-family: Roboto;
            font-size: 10px;
            font-weight: 600;
            line-height: 150%; /* 15px */
          }
        }
        .add-street {
          text-align: left;
          color: #717286;
          font-family: Roboto;
          font-size: clamp(12px, 4vw, 16px);
          font-weight: 400;
          line-height: 150%; /* 24px */
        }
      }
      .edit-bttn {
        position: absolute;
        right: 24px;
        top: 16px;
        color: #219d50;
      }
    }
  }

  .payment-box {
    cursor: pointer;
    position: relative;
    border-radius: 10px;
    border: 1px solid #dedede;
    background: #fff;
    display: flex;
    padding: 15px;
    gap: 16px;
    align-self: stretch;
    &.default {
      border: 1px solid #4c9b58;
      background: #f8f8f8;
    }
    .desc-box {
      width: calc(100% - 38px);
      display: flex;
      gap: 8px;
      flex-direction: column;
      .top {
        display: flex;
        gap: 16px;
        align-items: center;
        .card-number {
          text-align: left;
          color: #080e26;
          font-family: Roboto;
          font-size: 16px;
          font-weight: 500;
          line-height: 150%; /* 24px */
        }
        .badge {
          display: flex;
          padding: 4px 8px;
          justify-content: center;
          align-items: center;
          border-radius: 4px;
          background: #d6f3e1;
          color: var(--Fruit-Salad-600, #219d50);
          font-family: Roboto;
          font-size: 10px;
          font-weight: 600;
          line-height: 150%; /* 15px */
        }
      }
    }
    .bottom-div {
      display: flex;
      flex-direction: column;
      gap: 8px;
      width: 100%;
      .card-details {
        width: 100%;
        display: flex;
        justify-content: space-between;
        p {
          color: var(--Grey-500, #717286);
          font-family: Roboto;
          font-size: 14px;
          font-weight: 400;
          line-height: 150%; /* 21px */
        }
      }
    }

    .absolute-div {
      align-items: center;
      display: flex;
      gap: 8px;
      position: absolute;
      right: 15px;
      top: 15px;
      .img-wrapper {
        width: 49px;
        height: 15px;
        img {
          width: 100%;
          object-fit: contain;
        }
      }
      .edit-bttn {
        height: 30px;
        width: 30px;
        color: #219d50;
      }
    }
  }
}
.empty-div-Addresspayment-box {
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  align-self: stretch;
  gap: 8px;
  height: 100%;
  p {
    color: #717286;
    text-align: center;
    font-family: Roboto;
    font-size: 16px;
    font-weight: 400;
    line-height: 150%; /* 24px */
  }
}
