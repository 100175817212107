.delete-userimg-box{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    max-width: 655px;
    padding: 31px;
    flex-direction: column;
    align-items: center;
    gap: 22px;
    border: none;
    border-radius: 10px;
    background: #FFF;
    width: calc(90% - 62px);
    .upper{
        align-self: stretch;
        display: flex;
        justify-content: space-between;
        p{
            color: #303030;
            font-family: Roboto;
            font-size: clamp(22px,4vw,28px);
            font-weight: 800;
            line-height: 150%; /* 42px */
            letter-spacing: 0.42px;
        }
    }
    .bttns-div{
        display: flex;
        flex-direction: column;
        gap: 16px;
        align-self: stretch;
        .delete-bttn{
            border-radius: 10px;
            background: #CD1D1D;
            color: var(--Main-White, #FFF);
            font-family: Roboto;
            font-size: 18px;
            font-weight: 700;
            text-transform: capitalize;
            width: 100%;
            &:disabled{
                opacity: 0.6;
            }
        }
        .cancel-bttn{
            color: #A7A7A7;
            font-family: Roboto;
            font-size: 18px;
            font-weight: 700;
            text-transform: capitalize;
        }
    }
}