.LoginSignupmodal {
  position: absolute;
  top: 50%;
  left: 50%;
  // height: 90vh;
  transform: translate(-50%, -50%);
  width: 45%;
  border-radius: 10px;
  background: #fff;
  overflow: auto;
  @media (max-width: 1200px) {
    width: 90%;
  }

  .switcher {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    background: #f5f5f1;
    border-radius: 10px;

    button {
      display: flex;
      width: 50%;
      padding: 12px 20px;
      justify-content: center;
      align-items: center;
      border: none;
      gap: 10px;
      flex: 1 0 0;
      color: var(--Light-Grey-Text, #a7a7a7);
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      text-transform: capitalize;
      cursor: pointer;
      transition: background-color 0.5s ease;
      border-radius: 10px 10px 0px 0px;

      &.selected {
        background-color: #ffffff;
        border-bottom: 1px solid #a7a7a7;
        color: var(--Main-Green, var(--Fruit-Salad-600, #219d50));
      }
    }
  }

  .form-wrapper {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    width: calc(100% - 62px);
    padding: 57px 31px;
    gap: 22px;
    overflow: auto;
    max-height: 74vh;
    &::-webkit-scrollbar {
      width: 5px;
    }
    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #219d5167;
      border-radius: 8px;
    }
    &::-webkit-scrollbar-thumb:hover {
      background: #219d5167;
    }
    @media (max-width: 1200px) {
      padding: 24px;
      width: calc(100% - 48px);
    }

    p {
      color: #616161;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%; /* 24px */
      margin: 0px;
    }

    .buttons {
      display: flex;
      flex-direction: column;
      width: 100%;
      gap: 12px;

      button {
        display: flex;
        padding: 12px 24px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        align-self: stretch;
        border-radius: 10px;
        background: var(--Main-Green, #219d50);
        border: none;
        color: var(--Main-White, #fff);
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-transform: capitalize;
        cursor: pointer;
        &:hover {
          background: #0c7935;
        }
        &:disabled{
          opacity: 0.8;
          pointer-events: none;
        }
      }

      .white-button {
        display: flex;
        padding: 12px 24px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        align-self: stretch;
        border-radius: 10px;
        border: 1.5px solid #dadada;
        background: #fff;
        color: var(--Main-Black, #1e1e1e);
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-transform: capitalize;
        &:hover {
          background: #219d50;
          color: #fff;
        }
      }
    }

    .forgot-password {
      display: flex;
      align-items: flex-end;
      justify-content: flex-end;
      width: 100%;
      cursor: pointer;
      text-decoration: none;

      p {
        color: var(--Main-Green, var(--Fruit-Salad-600, #219d50));
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        text-transform: capitalize;
      }
    }

    .form-row {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 22px;
      width: 100%;
      @media (max-width: 1200px) {
        flex-direction: column;
      }

      .mui-register-password {
        padding-right: 10px;
        border-radius: 12px;
        border: 1px solid #e2e8f0;
        input {
          border: none;
        }
        width: 100%;
      }
    }

    .checkbox {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      width: 100%;
      gap: 8px;

      p {
        color: var(--body, #717286);
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; /* 21px */
      }

      input {
        width: 16px;
        height: 16px;
        flex-shrink: 0;
        border-radius: 2.286px;
        border: 1.143px solid #dddada;
        background: #fff;
        cursor: pointer;
      }
    }

    h1 {
      margin: 0px;
    }

    input {
      width: calc(100% - 29px);
      height: 27px;
      color: #122146;
      font-size: 16px;
      border-radius: 12px;
      border: 1px solid #dedede;
      padding: 14.5px 17px 14.5px 17px;
      &:focus {
        outline: 1px solid #00588f;
        box-shadow: 0px 4px 6px -2px rgba(0, 0, 0, 0.05),
          0px 10px 15px -3px rgba(32, 66, 137, 0.1);
      }
      &::placeholder {
        color: #616161;
        font-weight: 400;
        font-size: 16px;
      }
    }
  }
}
