.notes-history-section{
    display: flex;
    flex-direction: column;
    gap: 12px;
    align-self: stretch;
    .top-container-notes{
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        text-align: left;
        p{
            color: #000;
            font-family: Roboto;
            font-size: clamp(20px, 5vw, 24px);
            font-weight: 600;
            line-height: 150%; /* 36px */
            letter-spacing: 0.36px;
        }
        .write-note-bttn{
            display: flex;
            gap: 10px;
            align-items: center;
            color: #1E1E1E;
            font-family: Roboto;
            font-size: 14px;
            font-weight: 600;
            text-transform: capitalize;
            border-radius: 10px;
            border: 1.5px solid #DADADA;
            background: #FFF;
            padding: 10px 20px;
            max-height: 36px;
        }
    }
    .chat-container{
        display: flex;
        flex-direction: column;
     .chat-wrapper{
        max-height: 333px;
        overflow: auto;
        display: flex;
        padding: 23px;
        flex-direction: column;
        align-items: flex-start;
        gap: 24px;
        align-self: stretch;
        border-radius: 12px 12px 0px 0px;
        background:rgba(104, 212, 145, 0.27);
        &::-webkit-scrollbar {
            width:5px;
          }
        &::-webkit-scrollbar-thumb {
            background: #9DC2AB; 
            width: 5px;
          }
          .empty-chat{
            border-radius: 10px;
            border: 1px solid #DEDEDE;
            display: flex;
            height: 123px;
            padding: 16px 24px;
            flex-direction: column;
            align-items: center;
            gap: 8px;
            align-self: stretch;
            span{
                color: #717286;
                text-align: center;
                font-family: Roboto;
                font-size: 16px;
                font-weight: 400;
                line-height: 150%; /* 24px */
            }
          }
    }   
    .send-msg-container{
        border-radius: 0px 0px 12px 12px;
        border: 1px solid #E3E3E3;
        background: #FFF;
        display: flex;
        padding: 20px;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 10px;
        align-self: stretch;
        .input-box{
            border-radius: 10px;
            border: 1px solid #DEDEDE;
            align-self: stretch;
            display: flex;
            overflow: hidden;
            input{
                align-self: stretch;
                width: calc(100% - 55px);
                border: none;
                padding: 12px 0px 12px 16px ;
                &:focus{
                    outline: none;
                }
            }
            .sendBttn{
                &:disabled{
                    pointer-events: none;
                    opacity: 0.6;
                }
            }
        }
      }
    }
    
}