.notificationAlertList-box{
    padding: 16px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 427px;
    border-radius: 8px;
    transition: .2s all ease-in-out;
    cursor: pointer;
    @media (max-width: 560px) {
        width: calc(100% - 32px);
        }
    &.new{
        background-color: #F7F7F7;
    }
    &:hover{
        background-color: #F7F7F7;
        .close-bttn{
            display: flex;
        }
    }
    .left{
        display: flex;
        gap: 16px;
        .notif-icon-box{
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #68D49145;
            width: 40px;
            height: 40px;
            border-radius: 8px;
            min-width: 40px;
        }
        .notif-data{
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-self: stretch;
            text-align: left;
            gap: 6px;
            .notif-title{
                color: #000;
                font-family: Roboto;
                font-size: 16px;
                font-weight: 700;
                text-transform: capitalize;
            }
            .notif-detail{
                color: #A7A7A7;
                font-family: Roboto;
                font-size: 12px;
                font-weight: 400;
                line-height: 16px; /* 133.333% */
                letter-spacing: 0.4px;
            }
        } 
    }
    .close-bttn{
        width: 24px;
        height: 24px;
        display: none;
        color: black;
    }
}