.prescriptionOrder{
    background: #F8F8F8;
    &.disabled{
        opacity: 0.4;
        pointer-events: none;
    }
    .main-conatiner{
    display: flex;
    padding: 2.6% 5.2%;
    justify-content: center;
    align-items: center;
    @media (max-width: 450px) {
        padding: 0px;
        }
    .wrapper{
    display: flex;
    width: 100%;
    gap: 24px;
    max-width: 1352px;
    .prescriptionOrder-right{
        display: flex;
        flex-direction: column;
        width: calc(100% - 390px);
        gap: 24px;
        height: fit-content;
        @media (max-width: 1100px) {
            width: 100%;
            }
        .top-wrapper{
            align-self: stretch;
            display: flex;
            padding: 32px;
            flex-direction: column;
            align-items: flex-start;
            gap: 24px;
            border-radius: 10px;
            background-color: #FFF;
            @media (max-width: 860px) {
                padding: 32px 20px;
                }
            .back-bttn{
                color: #219D50;
                font-family: Roboto;
                font-size: 14px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                text-transform: capitalize;
                gap: 10px;
            }
            .top-div{
                display: flex;
                gap: 8px;
                align-items: center;
                h1{
                    color: #000;
                    font-family: Roboto;
                    font-size: clamp(20px, 5vw,28px);
                    font-weight: 800;
                    line-height: 150%; /* 42px */
                    letter-spacing: 0.42px;
                }
                .pres-status{
                    border-radius: 4px;
                    background: rgba(47, 53, 49, 0.40);
                    display: flex;
                    padding: 4px 8px;
                    justify-content: center;
                    align-items: center;
                    gap: 8px;
                    color: #FFF;
                    font-family: Roboto;
                    font-size: 12px;
                    font-weight: 600;
                    line-height: 150%; /* 18px */
                    max-height: 26px;
                    &.Paid, &.Shipped, &.Completed, &.Processing{
                        background: rgba(33, 157, 80, 0.60);
                        color: #FFF;
                        }
                        &.Cancelled{
                            background: #E1544A;
                            color: #ffff;
                        }
                        &.Ready.For.Pickup
                        {
                            background: #219D50;
                            color: #FFF;
                        }
                        &.Pending.Payment{
                            background: #219D50;
                            color: #FFF;
                        }
                        &.Submitted{
                            background: #2F353166;
                            color: #FFF;
                        }
                }
            }
            h2{
                color: #000;
                font-family: Roboto;
                font-size: 24px;
                font-weight: 600;
                line-height: 150%; /* 36px */
                letter-spacing: 0.36px;
            }
        }
       .order-summary{
        display: flex;
        padding: 32px;
        flex-direction: column;
        align-self: stretch;
        border-radius: 10px;
        background: #FFF;
        @media (max-width: 450px) {
           padding: 16px;
            }
        .order-wrapper{
            border-radius: 10px;
            border: 2px dashed #DEDEDE;
            background: #FFF;
            display: flex;
            padding: 24px;
            align-items: center;
            gap: 24px;
            align-self: stretch;
            @media (max-width: 860px) {
               flex-direction: column;
               align-items: flex-start;
                }
            .order-summary-left{
                flex: 1;
                display: flex;
                flex-direction: column;
                text-align: left;
                gap: 16px;
                align-self: stretch;
                p{
                    color: #303030;
                    font-family: Roboto;
                    font-size: clamp(18px, 2.6vw,20px);
                    font-weight: 700;
                    line-height: 150%; /* 30px */
                }
                .receipt-div{
                    display: flex;
                    flex-direction: column;
                    gap: 24px;
                    align-self: stretch;
                    .receipt-item{
                        align-self: stretch;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        span{
                            color: #393939;
                            font-family: Roboto;
                            font-size: clamp(14px, 1.8vw,16px);
                            font-weight: 400;
                            line-height: 150%; /* 24px */
                        }
                    }
                }
            }
            .order-total-right{
                display: flex;
                flex-direction: column;
                // gap: 28px;
                flex: 1;
                align-self: stretch;
                .subtotal{
                    text-align: left;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    align-self: stretch;
                    span{
                        color: #303030;
                        font-family: Roboto;
                        font-size: clamp(16px, 3vw,24px);
                        font-weight: 600;
                        line-height: 150%; /* 36px */
                        letter-spacing: 0.36px;
                    }
                    .subtext{
                        color: #393939;
                        font-family: Roboto;
                        font-size: clamp(12px, 2.6vw,16px);
                        font-weight: 400;
                        line-height: 150%; /* 24px */
                    }
                }
                .pay-presc-order-bttn{
                    color:#FFF;
                    font-family: Roboto;
                    font-size: 18px;
                    font-weight: 700;
                    text-transform: capitalize;
                    border-radius: 10px;
                    background:#219D50;
                    height: 45px;
                    &:disabled{
                        pointer-events: none;
                        opacity: 0.5;
                    }
                }
            }
        }
       }
        }
    }
}
}