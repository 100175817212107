.SettingsGeneral {
  background: #f8f8f8;
  .main-conatiner {
    display: flex;
    padding: 2.6% 5.2%;
    justify-content: center;
    align-items: center;
    @media (max-width: 450px) {
      padding: 0px;
    }
    .wrapper {
      display: flex;
      width: 100%;
      gap: 24px;
      max-width: 1352px;
      .SettingsGeneral-right {
        display: flex;
        flex-direction: column;
        width: calc(100% - 417px);
        gap: 24px;
        height: fit-content;
        @media (max-width: 1100px) {
          padding: 6px;
          width: 100%;
        }
        &.disabled {
          pointer-events: none;
          opacity: 0.6;
        }
        .profile-section {
          display: flex;
          padding: 24px;
          flex-direction: column;
          align-items: flex-start;
          gap: 24px;
          align-self: stretch;
          border-radius: 10px;
          background: #fff;
          h1 {
            color: #000;
            font-family: Roboto;
            font-size: 24px;
            font-weight: 600;
            line-height: 150%; /* 36px */
            letter-spacing: 0.36px;
          }
          .profile-img-wrapper {
            display: flex;
            align-items: center;
            gap: 16px;
            .uploadImg {
              text-align: center;
              display: flex;
              justify-content: center;
              align-items: center;
              width: 159px;
              white-space: nowrap;
              border-radius: 10px;
              border: 1.5px solid #dadada;
              background: #fff;
              color: #1e1e1e;
              font-family: Roboto;
              font-size: 14px;
              font-weight: 600;
              text-transform: capitalize;
              box-shadow: none;
              &:hover {
                background: #219d50;
                color: #fff;
              }
            }
            .inputFile {
              display: none;
            }
            .delete-bttn {
              width: 42px;
              height: 37px;
              border-radius: 10px;
              border: 1.5px solid #dadada;
            }
          }
          .form-wrapper {
            display: flex;
            flex-wrap: wrap;
            align-self: stretch;
            gap: 16px;
            label.Mui-focused {
              color: #4c9b58;
            }
            .MuiOutlinedInput-root {
              border-radius: 10px;
              &.Mui-focused fieldset {
                border-color: #4c9b58;
              }
            }
            .input {
              width: 100%;
              margin-bottom: 6px;
              &.short {
                width: calc(50% - 8px);
                @media (max-width: 660px) {
                  width: 100%;
                }
              }
            }
          }
          .save-changes {
            border-radius: 10px;
            background: #219d50;
            color: var(--Main-White, #fff);
            font-family: Roboto;
            font-size: 14px;
            font-weight: 600;
            text-transform: capitalize;
            padding: 10px 20px;
            max-height: 36px;
            &:hover {
              background: #0c7935;
            }
          }
        }
      }
    }
  }
}
