.presc-order-data {
  padding-top: 24px;
  display: flex;
  flex-direction: column;
  align-self: stretch;
  gap: 48px;
  &.cart {
    padding-top: 0px;
    gap: 24px;
  }
  .container {
    display: flex;
    flex-direction: column;
    gap: 24px;
    &.cart {
      background-color: #ffff;
      border-radius: 10px;
      padding: 40px 24px;
    }
    .title {
      text-align: left;
      color: #000;
      font-family: Roboto;
      font-size: clamp(20px, 4vw, 24px);
      font-weight: 600;
      line-height: 150%; /* 36px */
      letter-spacing: 0.36px;
      margin: 0px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 16px;
    }
    .wrapper {
      display: flex;
      align-self: stretch;
      flex-wrap: wrap;
      gap: 18px;
      @media (max-width: 860px) {
        flex-direction: column;
      }
      .input-box {
        flex: 1;
        display: flex;
        flex-direction: column;
        gap: 12px;
        .label {
          align-items: center;
          display: flex;
          gap: 8px;
          p {
            color: #303030;
            font-family: Roboto;
            font-size: clamp(18px, 2vw, 20px);
            font-weight: 700;
            line-height: 150%; /* 30px */
          }
          span {
            color: #717286;
            font-family: Roboto;
            font-size: 14px;
            font-weight: 400;
            line-height: 150%; /* 21px */
          }
        }
        .MuiFormControl-root.MuiTextField-root {
          width: 100%;
          border-radius: 10px;
        }
        .MuiOutlinedInput-root {
          border-radius: 10px;
          &.Mui-focused fieldset {
            border-color: #4c9b58;
          }
        }
      }
      .delivery-container {
        flex: 1;
        display: flex;
        gap: 24px;
        flex-direction: column;
        text-align: left;
        @media (max-width: 860px) {
          width: 100%;
        }
        &.disabled {
          opacity: 0.4;
          pointer-events: none;
        }
        p {
          color: #303030;
          font-family: Roboto;
          font-size: clamp(18px, 2vw, 20px);
          font-weight: 700;
          line-height: 150%; /* 30px */
        }
        .options-boxes {
          max-height: 260px;
          overflow: auto;
          display: flex;
          flex-direction: column;
          gap: 8px;
          border-radius: 10px;
          border: 1px solid #dedede;
          padding: 16px;
          &::-webkit-scrollbar {
            width: 5px;
          }
          /* Handle */
          &::-webkit-scrollbar-thumb {
            background: #333b3667;
            border-radius: 8px;
          }
          &::-webkit-scrollbar-thumb:hover {
            background: #333b3667;
          }
          @media (max-width: 390px) {
            padding: 12px;
          }
          .deliveryMethod-box {
            display: flex;
            padding: 24px;
            gap: 24px;
            align-self: stretch;
            border-radius: 10px;
            border: 1px solid #dedede;
            background: #fff;
            justify-content: space-between;
            cursor: pointer;
            height: 41px;
            &.default {
              border-radius: 10px;
              border: 1px solid #4c9b58;
              background: #f8f8f8;
              .circular-checkbox {
                background-color: #219d50;
              }
            }
            &.disabled{
              opacity: 0.4;
              pointer-events: none;
            }
            &:hover {
              .circular-checkbox {
                background-color: #219d50;
              }
            }
            .left {
              display: flex;
              align-items: center;
              gap: 24px;
              @media (max-width: 860px) {
                gap: 12px;
              }
              .circular-checkbox {
                border: 1px solid #dedede;
                min-width: 20px;
                width: 20px;
                height: 20px;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
              }
              span {
                color: #080e26;
                font-family: Roboto;
                font-size: clamp(12px, 3vw, 16px);
                font-weight: 500;
                line-height: 150%; /* 24px */
              }
            }
            .right {
              display: flex;
              align-items: center;
              gap: 24px;
              @media (max-width: 380px) {
                gap: 12px;
              }
              .img-wrapper {
                max-width: 70px;
                @media (max-width: 380px) {
                  max-width: 60px;
                }
                img {
                  width: 100%;
                  max-height: 84px;
                }
              }
              .cost {
                color: #717286;
                font-family: Roboto;
                font-size: clamp(12px, 2vw, 14px);
                font-weight: 400;
                line-height: 150%; /* 21px */
              }
            }
          }
        }
      }
    }
  }
}
