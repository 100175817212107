.prescription-component{
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: calc(100% - 383px);
    @media (max-width: 1100px) {
        width: 100%;
      }
      &.disabled{
        opacity: 0.7;
        pointer-events: none;
      }
.prescription-form{
display: flex;
padding: 24px;
flex-direction: column;
align-items: flex-start;
gap: 24px;
border-radius: 10px;
background: #FFF;
.back-bttn{
    color: #219D50;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: capitalize;
    gap: 10px;
}
.top-div{
    display: flex;
    gap: 8px;
    p{
        color:  #1E1E1E;
        font-family: Roboto;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: 150%; /* 36px */
        letter-spacing: 0.36px;
    }
}
.slogan{
    color:  #5C5F62;
    font-family: Roboto;
    font-size: 16px;
    font-weight: 400;
    line-height: 150%; /* 24px */
    text-align: left;
}
.form{
    align-self: stretch;
    display: flex;
    flex-direction: column;
    gap: 12px;
    .form-title{
        color:  #5C5F62;
        font-family: Roboto;
        font-size: 14px;
        font-weight: 600;
        text-align: left;
        line-height: 150%; /* 21px */
    }
    .input-container{
        align-self: stretch;
        display: flex;
        flex-wrap: wrap;
        gap: 16px;
        label.Mui-focused {
            color: #4C9B58;
          }
        .MuiOutlinedInput-root {
            border-radius: 10px;
            &.Mui-focused fieldset {
              border-color:#4C9B58;
            }
          }
        .input{
            width: 100%;
            &.short{
                width: calc(50% - 8px);
                @media (max-width: 580px) {
                    width: 100%;
                  }
            }
        }
        .css-1h51icj-MuiAutocomplete-root .MuiAutocomplete-tag {
            background-color: #219D50;
            color: #fff;
            padding: 6px;
            color:  #FFF;
            font-family: Roboto;
            font-size: 12px;
            font-weight: 700;
            line-height: 140%; /* 16.8px */
        }
        .MuiButtonBase-root-MuiChip-root .MuiChip-deleteIcon{
            color: #fff !important;
        }
    }
}
.add-bttn{
    color: #219D50;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.upload-div{
border-radius: 10px;
border: 1px dashed var(--Main-Green, #4C9B58);
background: #F8F8F8;
display: flex;
padding: 16px 24px;
justify-content: space-between;
align-items: center;
align-self: stretch;
&.disabled{
    opacity: 0.5;
    pointer-events: none;
}
@media (max-width: 580px) {
    flex-direction: column;
    gap: 24px;
  }
&.active{
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 24px;
}
.left-div{
    display: flex;
    align-items: center;
    gap: 8px;
    color: #219D50;
    @media (max-width: 580px) {
        flex-direction: column;
      }
    p{
        font-family: Roboto;
        font-size: 16px;
        font-weight: 500;
        line-height: 150%; /* 24px */
    }
}
.right-div{
    display: flex;
    gap: 16px;
    align-items: center;
    @media (max-width: 580px) {
        flex-direction: column;
      }
    .input-file{
        height: 1;
        overflow: hidden;
        position: absolute;
        bottom: 0;
        left: 0;
        white-space: nowrap;
        width: 1;
        visibility: hidden;
    }
    .upload-bttn{
        border-radius: 10px;
        background:  rgba(104, 212, 145, 0.27);
        color: var(--Main-Green, var(--Fruit-Salad-600, #219D50));
        font-family: Roboto;
        padding: 16px 20px;
        max-height: 48px;
        width: 155px;
        font-size: 14px;
        font-weight: 600;
        text-transform: capitalize;
        box-shadow: none;
    }
    .filename{
        max-width: 82px;
        color: #8F91A1;
        font-family: Roboto;
        font-size: 12px;
        font-weight: 400;
        line-height: 16px; /* 133.333% */
        letter-spacing: 0.4px;
        text-overflow: ellipsis;
        overflow: hidden;
        @media (max-width: 580px) {
            white-space: nowrap;
          }
    }
}
.file-preview{
    width: 108px;
    height: 108px;
    border-radius: 7.397px;
    border: 1px solid #219D50;
position: relative;
overflow: hidden;
background-repeat: no-repeat;
background-size: cover;
background-position: center;
z-index: 2;
.overlay-div{
    position: absolute;
    background: rgba(0, 0, 0, 0.311) ;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
    .deleteImg{
        width: 31px;
        height: 31px;
        border-radius: 7px;
        background-color: #fff;
    }
}
}
}
.checkbox-presc{
display: flex;
align-items: center;
.desc{
color: #616161;
font-family: Roboto;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 150%; /* 24px */
}
}
}
.submit-div{
    display: flex;
    width: 100%;
    justify-content: space-between;
    @media (max-width: 580px) {
       flex-direction: column-reverse;
       gap: 12px;
       width: 90%;
       align-self: center;
       padding-bottom: 15px;
      }
    .cancel-bttn{
    padding: 10px 20px;
    border-radius: 10px;
    border: 1.5px solid #DADADA;
    background: #FFF;
    color:  #1E1E1E;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 600;
    text-transform: capitalize;
    max-height: 36px;
    }
    .submitForm{
        border-radius: 10px;
        background: #219D50;
        display: flex;
        padding: 10px 20px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        max-height: 36px;
        color: #FFF;
        font-family: Roboto;
        font-size: 14px;
        font-weight: 600;
        text-transform: capitalize;
        &:disabled{
            opacity: 0.7;
            pointer-events: none;
        }
    }
}
}