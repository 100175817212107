.Sidecartmodal {
  position: absolute;
  top: 0;
  right: 0;
  width: 33%;
  max-width: 485px;
  height: 100%;
  border-radius: 8px;
  background: #fff;
  overflow: auto;
  border: none;
  transform: translate(1%, 0%);
  @media (max-width: 1200px) {
    width: 100%;
    max-width: 100vw;
    border-radius: 0;
  }

  .cart-wrap {
    display: flex;
    padding: 32px;
    flex-direction: column;
    align-items: flex-start;
    // height: -webkit-fill-available;
    height: calc(100% - 48px);
    &.disabled {
      opacity: 0.6;
      pointer-events: none;
    }
    @media (max-width: 1200px) {
      padding: 24px;
      width: calc(100% - 48px);
    }
    .title {
      display: flex;
      align-items: center;
      align-self: stretch;
      padding-bottom: 24px;
      justify-content: space-between;
      .closing {
        display: flex;
        align-items: center;
        gap: 8px;
        .count {
          color: #303030;

          /* Web/Body/B1 */
          font-family: Roboto;
          font-size: 20px;
          font-style: normal;
          font-weight: 400;
          line-height: 150%; /* 30px */
          letter-spacing: 0.3px;
          text-align: right;
        }
        svg {
          transition: 300ms;
          cursor: pointer;
          border-radius: 50%;
          &:hover {
            scale: 1.25;
            background-color: rgba(0, 0, 0, 0.04);
          }
        }
      }
      h1 {
        color: #303030;
        // font-family: Roboto;
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: 130%; /* 31.2px */
      }
    }
    .cartwrapping {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      // height: -webkit-fill-available;
      height: 100%;
      width: -webkit-fill-available;
      overflow: hidden;
      justify-content: space-between;
    }
    .textwrap {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 24px;
      align-self: stretch;
      text-align: center;
      height: -webkit-fill-available;
      .note {
        color: var(--Main-Green, #4c9b58);
        font-family: Gabarito;
        font-size: 34px;
        font-style: normal;
        font-weight: 700;
        line-height: 120%; /* 76.8px */
        letter-spacing: 0.96px;
      }
      .button {
        border-radius: 10px;
        background: var(--Fruit-Salad-600, #219d50);
        display: flex;
        padding: 16px 24px;
        justify-content: center;
        align-items: center;
        gap: 16px;
        color: var(--Foggy-Gray-0, #fff);
        font-family: Roboto;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        text-transform: capitalize;
        &:hover {
          background: #0c7935;
        }
      }
    }
    .CartItems {
      height: calc(100% - 180px);
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      // max-width: -webkit-fill-available;
      overflow-y: scroll;
      &::-webkit-scrollbar {
        display: none;
        width: 10px;
      }

      /* Track */
      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px grey;
        border-radius: 8px;
      }

      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: #219d50;
        border-radius: 8px;
      }
      &::-webkit-scrollbar-thumb:hover {
        background: #0c7935;
      }
      .item {
        display: flex;
        padding: 10px 5px;
        align-items: center;
        gap: 10px;
        align-self: stretch;
        border-bottom: 2px dashed #dfdfdf;
        overflow: hidden;
        min-height: 100px;
        width: 100%;
        img{
          width: 80px;
        }
        @media (max-width: 960px) {
          max-width: calc(100% - 10px);
          img {
            max-width: 25%;
          }
        }
        .data {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 10px;
          flex: 1 0 0;
          .name {
            white-space: nowrap;
            width: 120px;
            overflow: hidden;
            text-overflow: ellipsis;
            color: #393939;
            // font-family: Roboto;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 150%; /* 24px */
          }
          .info {
            display: flex;
            align-items: center;
            // gap: 10px;
            align-self: stretch;
            // justify-content: space-evenly;
            justify-content: flex-start;
            gap: 16px;
            @media (max-width: 960px) {
              justify-content: flex-start;
            }
            .price {
              color: var(--Main-Green, var(--Fruit-Salad-600, #219d50));
              font-family: Roboto;
              font-size: 20px;
              font-style: normal;
              font-weight: 600;
              line-height: 150%; /* 36px */
              letter-spacing: 0.36px;
              @media (max-width: 960px) {
                font-size: 20px;
              }
            }
            .input,
            input {
              display: flex;
              align-items: center;
              gap: 8px;
              align-self: stretch;
              border-radius: 8px;
              border: 1px solid rgba(18, 18, 18, 0.08);
              background: var(--Basic-White, #fff);
              position: relative;
              max-width: 30%;
            }
            input {
              border: none;
              padding: 4px 4px 4px 16px;
              max-width: 100%;

              @media (max-width: 960px) {
                padding: 4px 4px 4px 6px;
                &::-webkit-input-placeholder {
                  color: #000000;
                  font-weight: 600;
                  font-size: 14px;
                }
              }
            }
            .incbuttons {
              display: flex;
              width: 19px;
              padding: 0px 4px;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              // gap: 8px;
              gap: 3px;
              align-self: stretch;
              border-radius: 4px;
              background: var(--Fruit-Salad-600, #219d50);
              position: absolute;
              right: 10%;
              top: 12%;
              cursor: pointer;
              // height: -webkit-fill-available;
              .MuiButtonBase-root {
                height: 10px;
                padding: 4px 6px;
                border-radius: 2px;
                display: flex;
                align-items: center;
                justify-content: center;
                transition: 300ms;

                &:hover {
                  background: #0c7935;
                }
              }
              svg {
                // height: 50%;
              }
            }
            .delete {
            }
          }
        }
        &:last-child {
          border-bottom: 2px solid #dfdfdf;
        }
      }
    }
    .buttons {
      display: flex;
      flex-direction: column;
      width: 100%;
      gap: 12px;
      padding-top: 24px;
      .cost {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 1px;
        align-self: stretch;
        border-top: 2px solid rgba(0, 0, 0, 0.1);
        padding-top: 24px;
        .field {
          display: flex;
          padding: 12px 0px;
          align-items: center;
          gap: 24px;
          align-self: stretch;
          justify-content: space-between;
        }
        .field.border {
          border-bottom: 2px dashed #dfdfdf;
        }
      }
      .discount {
        display: flex;
        align-items: center;
        gap: 10px;
        align-self: stretch;
        padding-top: 24px;
        input {
          width: calc(100% - 29px);
          height: 27px;
          color: #122146;
          font-size: 16px;
          border-radius: 12px;
          border: 1px solid #dedede;
          padding: 14.5px 17px 14.5px 17px;
          &:focus {
            outline: 1px solid #00588f;
            box-shadow: 0px 4px 6px -2px rgba(0, 0, 0, 0.05),
              0px 10px 15px -3px rgba(32, 66, 137, 0.1);
          }
          &::placeholder {
            color: #616161;
            font-weight: 400;
            font-size: 16px;
          }
        }
        .apply {
          display: flex;
          padding: 10px 20px;
          justify-content: center;
          align-items: center;
          gap: 10px;
          align-self: stretch;
          border-radius: 10px;
          background: var(--Green-Light, rgba(104, 212, 145, 0.27));
          color: var(--Main-Green, var(--Fruit-Salad-600, #219d50));
          font-family: Roboto;
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          text-transform: capitalize;
          border: none;
          &:hover {
            background-color: #219d50;
            color: #fff;
          }
        }
      }
      .button {
        display: flex;
        padding: 12px 24px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        align-self: stretch;
        border-radius: 10px;
        background: var(--Main-Green, #219d50);
        border: none;
        color: var(--Main-White, #fff);
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-transform: capitalize;
        cursor: pointer;
        &:hover {
          background: #0c7935;
        }
      }
    }
  }
}
