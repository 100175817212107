.orders-overview {
  display: flex;
  padding: 40px 24px;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 24px;
  border-radius: 10px;
  background: #fff;
  .title-div {
    display: flex;
    justify-content: space-between;
    width: 100%;
    .left {
      display: flex;
      align-items: center;
      gap: 8px;
      p {
        color: var(--Basic-Black, #000);
        font-family: Roboto;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: 150%; /* 36px */
        letter-spacing: 0.36px;
      }
    }
    .view-all {
      color: #219d50;
      font-family: Roboto;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      text-transform: capitalize;
      border: none;
      background-color: transparent;
      &:hover {
        background: #219d50;
        color: #fff;
      }
      @media (max-width: 1100px) {
        display: none;
      }
    }
  }
  .orders-boxes-wrapper-mobile {
    display: none;
    @media (max-width: 1100px) {
      display: flex;
    }
  }
  .orders-boxes-wrapper {
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: 24px;
    .order-box {
      display: flex;
      flex-direction: column;
      gap: 16px;
      padding: 20px;
      border-radius: 10px;
      border: 1px solid #dedede;
      background: #fff;
      justify-content: flex-start;
      gap: 26px;
      .top-order-details-div {
        display: flex;
        width: 100%;
        justify-content: space-between;
        color: #393939;
        font-family: Roboto;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%; /* 30px */
        letter-spacing: 0.3px;
        @media (max-width: 1100px) {
          p:nth-child(2) {
            display: none;
          }
        }
        .drop-down-mobile {
          display: none;
          @media (max-width: 1100px) {
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
      }
      .bottom-div-delivery-status {
        display: flex;
        flex-direction: column;
        width: 100%;
        transition: 0.3s all ease-in-out;
        @media (max-width: 1100px) {
          .MuiStepper-root {
            flex-direction: column;
            align-items: center;
          }
          .MuiStepConnector-root.MuiStepConnector-alternativeLabel {
            transform: rotate(90deg);
            width: 22px;
          }
          .MuiStepConnector-root {
            position: relative;
            left: inherit;
            right: inherit;
          }
          .MuiStep-root {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 22px;
          }
        }
      }
      .bottom-div {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        &.mobile {
          display: none;
          border-bottom: 1px solid #00000024;
          padding-bottom: 10px;
          &:last-child {
            border-bottom: none;
          }
          .img-container-order {
            flex-wrap: wrap;
          }
          @media (max-width: 1100px) {
            display: flex;
          }
          .button-div {
            width: 100%;
            .presc-details-bttn {
              flex: 1;
            }
            .reorder-bttn {
              flex: 2;
            }
          }
        }
        @media (max-width: 1100px) {
          display: none;
        }
        .img-container-order {
          display: flex;
          align-items: center;
          gap: 12px;
          .img-wrapper {
            width: 52px;
            height: 52px;
            border-radius: 50%;
            overflow: hidden;
            img {
              width: 100%;
            }
          }
          .rest-imges {
            color: #909090;
            font-family: Roboto;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 131.5%; /* 21.04px */
            letter-spacing: 1.44px;
            text-transform: uppercase;
          }
        }
        .button-div {
          max-height: 36px;
          display: flex;
          gap: 12px;
          .presc-details-bttn {
            display: flex;
            padding: 8px 20px;
            justify-content: center;
            align-items: center;
            gap: 10px;
            border-radius: 10px;
            border: 1.5px solid #dadada;
            background: #fff;
            color: #1e1e1e;
            font-family: Roboto;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            text-transform: capitalize;
            &:hover {
              background: #219d50;
              color: #fff;
            }
          }
          .reorder-bttn {
            display: flex;
            padding: 10px 20px;
            justify-content: center;
            align-items: center;
            gap: 10px;
            border-radius: 10px;
            background: #219d50;
            color: #fff;
            font-family: Roboto;
            font-size: 14px;
            font-weight: 600;
            text-transform: capitalize;
            &:hover {
              background: #0c7935;
            }
          }
        }
      }
    }
  }
  .empty-orders {
    border-radius: 24px;
    border: 1px solid #ebecff;
    background: #fff;
    display: flex;
    padding: 24px;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    .empty-text {
      color: #717286;
      text-align: center;
      font-family: Roboto;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%; /* 24px */
    }
    .link-to-shop {
      color: var(--Main-White, #fff);
      font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      text-transform: capitalize;
      border-radius: 10px;
      background: #219d50;
      display: flex;
      padding: 10px 20px;
      justify-content: center;
      align-items: center;
      gap: 10px;
    }
  }
  .view-all-mobile {
    display: none;
    border-radius: 10px;
    background: rgba(104, 212, 145, 0.27);
    color: var(--Main-Green, var(--Fruit-Salad-600, #219d50));
    font-family: Roboto;
    font-size: 18px;
    font-weight: 700;
    text-transform: capitalize;
    width: 100%;
    @media (max-width: 1100px) {
      display: block;
    }
  }
}
