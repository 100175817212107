.OpsWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  scroll-behavior: smooth;
  .SectionOne {
    display: flex;
    padding: 80px;
    align-items: center;
    position: relative;
    width: -webkit-fill-available;
    justify-content: center;
    @media (max-width: 960px) {
      padding: 40px 24px;
      max-width: calc(100vw - 48px);
    }
    .wrap {
      max-width: 1360px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 80px;
      z-index: 2;
      @media (max-width: 960px) {
        gap: 24px;
        max-width: 100%;
        flex-direction: column-reverse;
      }
      .textwrap {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 24px;
        align-self: stretch;
        .note {
          color: var(--Main-Green, #4c9b58);

          /* Headings/H1 */
          font-family: Gabarito;
          font-size: 64px;
          font-style: normal;
          font-weight: 700;
          line-height: 120%; /* 76.8px */
          letter-spacing: 0.96px;
        }
        .title {
          color: #3c3c3c;

          /* Headings/H2 */
          font-family: Gabarito;
          font-size: 48px;
          font-style: normal;
          font-weight: 700;
          line-height: 120%; /* 57.6px */
          letter-spacing: 0.72px;
        }
        .desc {
          color: var(--Main-Black, #1e1e1e);
          font-family: Roboto;
          font-size: 20px;
          font-style: normal;
          font-weight: 400;
          line-height: 150%; /* 30px */
          letter-spacing: 0.3px;
        }
        .button {
          border-radius: 10px;
          background: var(--Fruit-Salad-600, #219d50);
          display: flex;
          padding: 16px 24px;
          justify-content: center;
          align-items: center;
          gap: 16px;
          color: var(--Foggy-Gray-0, #fff);
          font-family: Roboto;
          font-size: 18px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          text-transform: capitalize;
          &:hover {
            background: #0c7935;
          }
        }
      }
    }
  }
}
