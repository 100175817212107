.pay-prescription-popup{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    max-width: 683px;
    padding: 24px;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    border: none;
    border-radius: 10px;
    background: #FFF;
    width: calc(90% - 48px);
    .top-cont{
        display: flex;
        justify-content: space-between;
        width: 100%;
        p{
            color: #303030;
            font-family: Roboto;
            font-size: clamp(20px,6vw,28px);
            font-style: normal;
            font-weight: 600;
            line-height: 150%; /* 42px */
            letter-spacing: 0.42px;
        }
    }
    .select-presc-cont{
        display: flex;
        padding: 24px 16px;
        flex-direction: column;
        align-items: center;
        gap: 16px;
        align-self: stretch;
        border-radius: 10px;
        border: 1px solid #DEDEDE;
        background: #F8F8F8;
        p{
            color: #717286;
            text-align: center;
            font-family: Roboto;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 150%; 
        }
        .MuiOutlinedInput-notchedOutline{
            border-radius: 10px;
            border: 1px solid #DEDEDE;
            background: #FFF;
            box-shadow: 0px 0px 16px 0px rgba(234, 76, 137, 0.06);
            z-index: 0 !important;
        }
        .MuiSvgIcon-root.MuiSelect-icon {
            background-color: #68D49145;
            border-radius: 50%;
            color:#219D50 ;
            z-index: 50;
        }
        .MuiOutlinedInput-input {
            z-index: 10 !important;
        }
        .select-label{
            color: #333;
            font-family: Roboto;
            font-size: 16px;
            font-weight: 400;
            line-height: 18px; /* 112.5% */
            z-index: 10;
        }
    }
    .pay-bttn{
        width: 100%;
        border-radius: 10px;
        background: #219D50;
        color: #FFF;
        font-family: Roboto;
        font-size: 18px;
        font-weight: 700;
        text-transform: capitalize;
        &:hover{
            background-color: #188642;
        }
        &:disabled{
            color: #fff;
            opacity: 0.8;
        }
    }
    .cancel-bttn{
        color: #A7A7A7;
        font-family: Roboto;
        font-size: 18px;
        font-weight: 700;
        text-transform: capitalize;
        width: 100%;
    }
}