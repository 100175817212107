.bottom-user-cont-navigation-links {
    display: flex;
    padding: 12px;
    flex-direction: column;
    border-radius: 10px;
    background: #fff;
    .dashboard-nav-link {
      flex: 1;
      text-align: left;
      display: flex;
      gap: 16px;
      border-radius: 8px;
      padding: 16px 12px;
      color: #393939;
      font-family: Roboto;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%; /* 27px */
      letter-spacing: 0.27px;
      transition: none;
      &.active {
        background: #f8f8f8;
        color: #000;
        font-weight: 500;
      }
      &:hover {
        background: #f8f8f8;
        // font-weight: 500;
        color: #000;
      }
    }
    .button-logout {
      display: flex;
      justify-content: flex-start;
      padding: 16px 12px;
      align-items: center;
      gap: 16px;
      max-height: 57px;
      align-self: stretch;
      align-items: center;
      color: #f95457;
      font-family: Roboto;
      font-size: 18px;
      font-weight: 400;
      line-height: 140%; /* 25.2px */
      text-transform: capitalize;
      &:hover {
        background-color: #f8f8f8;
      }
    }
    .MuiPaper-root.MuiAccordion-root::before {
      opacity: 0 !important;
    }
    .MuiPaper-root.MuiAccordion-root {
      box-shadow: none !important;
    }
    .MuiAccordionSummary-content {
      display: flex;
      gap: 16px;
      color: #393939;
      font-family: Roboto;
      font-size: 18px;
      font-weight: 400;
      line-height: 150%; /* 27px */
      letter-spacing: 0.27px;
    }
    .MuiSvgIcon-root {
      color: #393939;
    }
    .MuiPaper-root.MuiAccordion-root.Mui-expanded {
      margin: 0px !important;
    }
    .MuiButtonBase-root.MuiAccordionSummary-root {
      padding: 0px 12px !important;
    }
    .MuiAccordionDetails-root {
      display: flex;
      flex-direction: column;
      padding: 0px 12px !important;
    }
  }