.singlePrescription{
    background: #F8F8F8;
    .main-conatiner{
    display: flex;
    padding: 2.6% 5.2%;
    justify-content: center;
    align-items: center;
    .wrapper{
    display: flex;
    width: 100%;
    gap: 24px;
    max-width: 1352px;
    .singlePrescription-right{
        display: flex;
        padding: 32px;
        flex-direction: column;
        align-items: flex-start;
        gap: 24px;
        border-radius: 10px;
        background: #FFF;
        width: calc(100% - 417px);
        height: fit-content;
        @media (max-width: 1100px) {
            width: calc(100% - 30px);
            padding: 32px 15px;
    }
        .back-bttn{
            color: #219D50;
            font-family: Roboto;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            text-transform: capitalize;
            gap: 10px;
        }
        .top-container{
            display: flex;
            justify-content: space-between;
            width: 100%;
            @media (max-width: 1100px) {
                flex-direction: column;
                }
            .top-wrapper-left{
                display: flex;
                flex-direction: column;
                gap: 8px;
            h1{
                text-align: left;
                color: #000;
                font-family: Roboto;
                font-size: clamp(20px, 4vw, 24px);
                font-weight: 600;
                line-height: 150%; /* 36px */
                letter-spacing: 0.36px;
            }
            .desc-prescription{
                display: flex;
                gap: 8px;
                align-items: center;
                .status{
                    border-radius: 4px;
                    font-family: Roboto;
                    font-size: 12px;
                    font-weight: 600;
                    line-height: 150%; /* 18px */
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    padding: 4px 8px;
                    &.Paid, &.Shipped, &.Completed, &.Processing{
                        background: rgba(33, 157, 80, 0.60);
                        color: #FFF;
                        }
                        &.Cancelled{
                            background: #E1544A;
                            color: #ffff;
                        }
                        &.Ready.For.Pickup
                        {
                            background: #219D50;
                            color: #FFF;
                        }
                        &.Pending.Payment{
                            background: #219D50;
                            color: #FFF;
                        }
                        &.Submitted{
                            background: #2F353166;
                            color: #FFF;
                        }
                }
                .presc-no{
                    color: #818181;
                    font-family: Roboto;
                    font-size: 14px;
                    font-weight: 600;
                    line-height: 150%; /* 21px */
                }
            }
            }
            .button-right{
                align-items: center;
                display: flex;
                gap: 12px;
                @media (max-width: 1100px) {
                    padding-top: 10px;
                        }
                .refill-bttn{
                    border-radius: 10px;
                    border: 1.5px solid #DADADA;
                    background: #FFF;
                    display: flex;
                    padding: 10px 20px;
                    justify-content: center;
                    align-items: center;
                    gap: 10px;
                    color: var(--Main-Black, #1E1E1E);
                    font-family: Roboto;
                    font-size: 14px;
                    font-weight: 600;
                    text-transform: capitalize;
                    max-height: 36px;
                    @media (max-width: 1100px) {
                        padding: 10px 8px;
                        font-size: 12px;
                        white-space: nowrap;
                    }
                }
                .pay-bttn{
                    border-radius: 10px;
                    background: #219D50;
                    color: var(--Main-White, #FFF);
                    font-family: Roboto;
                    font-size: 14px;
                    font-weight: 600;
                    text-transform: capitalize;
                    padding: 10px 20px;
                    max-height: 36px;
                    @media (max-width: 1100px) {
                        padding: 10px 12px;
                        font-size: 12px;
                        white-space: nowrap;
                    }
                }
            }
        }
        .empty-div{
            display: flex;
            padding: 24px;
            flex-direction: column;
            align-items: center;
            gap: 8px;
            align-self: stretch;
            border-radius: 24px;
            border: 1px solid #EBECFF;
            background: #FFF;
            p{
                color: #717286;
                text-align: center;
                font-family: Roboto;
                font-size: 16px;
                font-weight: 400;
                line-height: 150%; /* 24px */
            }
        }
      
        }
    }
}
}