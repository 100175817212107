.userEmail-popup{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 731px;
    border-radius: 10px;
    background: #fff;
    overflow: auto;
    gap: 24px;
    display: flex;
    padding: 24px;
    flex-direction: column;
    align-items: flex-start;
    width: calc(90% - 48px);
    label.Mui-focused {
        color: #4C9B58;
      }
    .MuiOutlinedInput-root {
        border-radius: 10px;
        &.Mui-focused fieldset {
          border-color:#4C9B58;
        }
      }
    .top-wrapper{
        display: flex;
        flex-direction: column;
        gap: 8px;
        .top{
            display: flex;
            justify-content: space-between;
            width: 100%;
            p{
                color: #303030;
                font-family: Roboto;
                font-size: clamp(20px,6vw,28px);
                font-weight: 600;
                line-height: 150%; /* 42px */
                letter-spacing: 0.42px;
            }
        }
        .alert-text{
            color: #717286;
            font-family: Roboto;
            font-size: clamp(14px,2vw,16px);
            font-weight: 400;
            line-height: 150%; /* 24px */
        }
    }
    .bttn-div{
        align-self: stretch;
        display: flex;
        flex-direction: column;
        gap: 16px;
        .save-bttn{
            border-radius: 10px;
            background: #219D50;
            color: #FFF;
            font-family: Roboto;
            font-size: 18px;
            font-weight: 700;
            text-transform: capitalize;
        }
        .cancel-bttn{
            color: #A7A7A7;
            font-family: Roboto;
            font-size: 18px;
            font-weight: 700;
            text-transform: capitalize;
        }
    }
}