.Footer {
  display: flex;
  padding: 56px 0px;
  flex-direction: column;
  align-items: center;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  @media (max-width: 1324px) {
    display: flex;
    padding: 40px 24px;
  }
  .footer-wrapper {
    width: 100%;
    max-width: 1300px;
    display: flex;
    flex-direction: column;
    gap: 63px;
    @media (max-width: 1324px) {
      display: flex;
      flex-direction: column;
    }
    .upperbox {
      display: flex;
      align-items: flex-start;
      gap: 40px;
      align-self: center;
      width: 100%;
      max-width: 1300px;
      flex-wrap: wrap;
      // @media (max-width: 1324px) {
      //   // max-width: 390px;
      //   display: flex;
      //   flex-direction: column;
      //   width: calc(100vw - 50px);
      // }
      .box {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 16px;
        flex: 1 0 0;
        @media (max-width: 960px) {
          width: calc(50% - 40px);
          flex: auto;
        }
        @media (max-width: 420px) {
          width: 100%;
          flex: auto;
        }
        .head {
          color: var(--Color-Black, #1a1919);
          // font-family: Roboto;
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: 131.5%; /* 21.04px */
          letter-spacing: 1.44px;
          text-transform: uppercase;
        }
        .itms {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 12px;
          align-self: stretch;
          .item {
            color: var(--Grey-500, #6b6a61);
            // font-family: Roboto;
            font-size: 14px;
            text-align: left;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            text-decoration: none;
            transition: 300ms;
            &:hover {
              color: #209d50;
            }
          }
        }
      }
    }
    .paymentcards {
      align-self: flex-end;
      width: fit-content;
      display: flex;
      align-items: center;
      gap: 16px;
      margin-top: -80px;
      @media (max-width: 960px) {
        margin-top: 0px;
        align-self: center;
      }
      @media (max-width: 460px) {
        gap: 2%;
        img{
          width: calc(20% - 2% );
        }
      }
    }
    .hr {
      width: -webkit-fill-available;
      height: 0;
      background-color: #219d50;
      max-width: 80%;
      @media (max-width: 1324px) {
        max-width: calc(100vw - 50px);
        width: 100%;
      }
    }
    .lower {
      display: flex;
      justify-content: space-between;
      align-self: center;
      width: 100%;
      max-width: 1300px;
      @media (max-width: 1324px) {
        flex-direction: column;
        align-items: center;
        // max-width: 390px;
        width: calc(100vw - 50px);
        gap: 20px;
      }
      img {
        max-width: 255px;
      }
      p {
        color:#1A1919;
        text-align: center;
        /* Web/Body/B3 */
        font-family: Roboto;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; /* 24px */
        a{
          color:  #1A1919;
          text-decoration: underline;
        }
      }
    }
  }
}
