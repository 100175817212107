.automatocReminder-container{
display: flex;
padding: 32px;
gap: 24px;
align-self: stretch;
border-radius: 10px;
background: #D6F3E1;
justify-content: space-between;
align-items: center;
@media (max-width: 860px) {
    flex-direction: column;
    align-items: flex-start;
}
.left{
display: flex;
gap: 6px;
.text-wrap{
    text-align: justify;
    display: flex;
    gap: 8px;
    flex-direction: column;
    width: calc(100% - 33px );
    p:first-child{
        margin-top: 0px;
        color: #000;
        font-family: Roboto;
        font-size: 24px;
        font-weight: 600;
        line-height: 150%; /* 36px */
        letter-spacing: 0.36px;
        text-align: left;
    }
    p:last-child{
        max-width: 422px;
        color: #000;
        font-family: Roboto;
        font-size: 16px;
        font-weight: 400;
        line-height: 150%; /* 24px */
        @media (max-width: 860px) {
            margin-left: -30px;
        }
    }
}
}
.activateReminder{
    color: #FFF;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 600;
    text-transform: capitalize;
    border-radius: 10px;
    background:  #219D50;
    max-height: 36px;
    width: fit-content;
    padding: 10px 20px;
    white-space: nowrap;
    @media (max-width: 860px) {
       width: 100%;
    }
    &:hover{
        background:  #219d51a4;
    }
}
}
.top-auto-reminder-popup{
    width: 100%;
    display: flex;
    justify-content: flex-end;
}
.bottom-auto-reminder-popup{
    width: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 18px;
    p{
color: #303030;
font-family: Roboto;
font-size: 24px;
font-weight: 600;
line-height: 150%; /* 36px */
letter-spacing: 0.36px;
text-align: center;
    }
}
#back-auto-reminder{
color:  #1E1E1E;
font-family: Roboto;
font-size: 14px;
font-weight: 600;
text-transform: capitalize;
}