.tableComponent-dashboard {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: auto;
  table {
    width: 100%;
    border-spacing: 0px;
    display: "table";
    border: 1px solid #f5f5f5;
    border-radius: 0px 0px 10px 10px;
    thead {
      background: #f4f4f4;
      height: 53px;
      @media (max-width: 1100px) {
        display: none;
      }
      td {
        color: var(--Basic-Black, #000);
        font-family: Roboto;
        font-size: 14px;
        font-weight: 600;
        line-height: 150%; /* 21px */
        text-align: left;
        &:first-child {
          padding-left: 40px;
          border-top-left-radius: 10px;
        }
        &:last-child {
          border-top-right-radius: 10px;
        }
        p {
          white-space: nowrap;
        }
      }
    }
    tbody {
      tr {
        max-height: 121px;
        &:last-child {
          .products-mobile-table {
            border-bottom: none;
          }
          .order-detail-mobile {
            border-bottom: none;
          }
          .presc-data {
            border-bottom: none;
          }
        }
        td {
          text-align: left;
          &:first-child {
            padding-left: 16px;
            max-width: 268px;
          }
          &:last-child {
            padding-right: 16px;
          }
          .bold-p {
            padding: 18px 0px;
            text-align: center;
            overflow: hidden;
            color: #000;
            text-overflow: ellipsis;
            font-family: Roboto;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 150%; /* 24px */
          }
          .pres-status {
            padding: 4px 8px;
            width: fit-content;
            border-radius: 4px;
            background-color: #e7e7e7;
            color: #5c5f62;
            font-family: Roboto;
            font-size: 10px;
            font-weight: 600;
            line-height: 150%; /* 15px */
          }
          .order-detail-mobile {
            display: flex;
            flex-direction: column;
            gap: 8px;
            padding: 16px 0px;
            border-bottom: 1px solid rgba(0, 0, 0, 0.164);
            .bold-p {
              padding: 0px;
            }
            .top {
              display: flex;
              justify-content: space-between;
              align-self: stretch;
              p {
                font-weight: 700;
              }
            }
            .middle {
              display: flex;
              gap: 4px;
              align-items: center;
              .price {
                color: #000;
                font-family: Roboto;
                font-size: 15px;
                font-weight: 700;
                line-height: 140%; /* 16.8px */
              }
            }
            .button-div {
              .presc-details-bttn {
                flex: 1;
              }
              .reorder-bttn {
                flex: 2;
              }
            }
          }
          .product-info {
            padding: 16px 0px 16px 0px;
            display: flex;
            align-items: center;
            gap: 8px;
            .img-wrapper {
              width: 83px;
              @media (max-width: 375px) {
                width: 60px;
              }
              img {
                width: 100%;
                border-radius: 11.333px;
                background: #f8f8f8;
              }
              p {
                color: #393939;
                font-family: Roboto;
                font-size: 20px;
                font-style: normal;
                font-weight: 500;
                line-height: 150%; /* 30px */
                letter-spacing: 0.3px;
              }
            }
          }
          .right-product-info {
            display: flex;
            flex-direction: column;
            gap: 8px;
            p {
              width: 179px;
              overflow: hidden;
              text-overflow: ellipsis;
              height: 30px;
            }
            .wishlistProducts-p {
              color: #393939;
              font-family: Roboto;
              font-size: 20px;
              font-weight: 500;
              line-height: 150%; /* 30px */
              letter-spacing: 0.3px;
            }
            .Products-p,
            .ProductsDetails-p {
              color: #000;
              font-family: Roboto;
              font-size: 16px;
              font-style: normal;
              font-weight: 700;
              line-height: 140%; /* 22.4px */
              white-space: nowrap;
              @media (max-width: 394px) {
                max-width: calc(100% - 83px);
              }
            }
            .mobile-stocking-price {
              display: flex;
              gap: 4px;
              span {
                color: #000;
                font-family: Roboto;
                line-height: 140%; /* 16.8px */
                &:first-child {
                  font-weight: 400;
                  font-size: 12px;
                }
                &:last-child {
                  font-size: 14px;
                  font-weight: 700;
                }
              }
            }
            span {
              color: #5c5f62;
              font-family: Roboto;
              font-size: 12px;
              font-weight: 400;
              line-height: 16px; /* 133.333% */
              letter-spacing: 0.4px;
            }
          }

          .price {
            color: #171717;
            font-family: Roboto;
            font-size: 20px;
            font-style: normal;
            font-weight: 700;
            line-height: 150%; /* 30px */
          }
          .stocking {
            color: #171717;
            font-family: Roboto;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 150%; /* 21px */
          }
          .product-variant {
            display: flex;
            gap: 16px;
            align-items: center;
            padding-left: 16px;
            width: fit-content;
            @media (max-width: 780px) {
              width: 100%;
              padding-left: 0px;
            }
            .count-div {
              width: calc(123px - 8px);
              display: flex;
              padding: 6px 4px;
              justify-content: center;
              align-items: center;
              gap: 16px;
              border-radius: 10px;
              border: 1px solid #dedede;
              background: #fff;
              .quantity {
                color: #303030;
                font-family: Roboto;
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: 150%; /* 24px */
              }
              .quantity-bttn {
                border-radius: 32px;
                border: 0.8px solid #dedede;
                background: #f7f6fb;
                width: 32px;
                height: 32px;
              }
            }
            .variantBox {
              display: flex;
              padding: 8px 10px;
              justify-content: center;
              align-items: center;
              gap: 8px;
              border-radius: 10px;
              background: #f8f8f8;
              color: #717286;
              font-family: Roboto;
              font-size: 14px;
              font-weight: 400;
              @media (max-width: 780px) {
                flex: 1;
              }
              .color-circle {
                width: 16px;
                height: 16px;
                border-radius: 50%;
                border: 1px solid #303030;
              }
            }
          }
          .bttn {
            display: flex;
            justify-content: flex-end;
            @media (max-width: 1100px) {
              justify-content: center;
              align-items: center;
            }
            .add-to-cart-bttn {
              white-space: nowrap;
              background-color: #219d50;
              padding: 10px 20px;
              color: #fff;
              border-radius: 10px;
              color: var(--Main-White, #fff);
              font-family: Roboto;
              font-size: 14px;
              font-style: normal;
              font-weight: 600;
              line-height: normal;
              text-transform: capitalize;
              &:hover {
                background: #0c7935;
              }
            }
          }
          .button-div {
            max-height: 36px;
            display: flex;
            gap: 12px;
            .presc-details-bttn {
              width: fit-content;
              display: flex;
              padding: 8px 20px;
              justify-content: center;
              align-items: center;
              gap: 10px;
              border-radius: 10px;
              border: 1.5px solid #dadada;
              background: #fff;
              color: #1e1e1e;
              font-family: Roboto;
              font-size: 14px;
              font-style: normal;
              font-weight: 600;
              line-height: normal;
              text-transform: capitalize;
              &:hover {
                background: #219d50;
                color: #fff;
              }
              @media (max-width: 890px) {
                flex: 2;
                font-size: 12px;
                white-space: nowrap;
                &:last-child {
                  flex: 1;
                }
              }
            }
            .reorder-bttn {
              width: fit-content;
              display: flex;
              padding: 10px 20px;
              justify-content: center;
              align-items: center;
              gap: 10px;
              border-radius: 10px;
              background: #68d49145;
              color: #219d50;
              font-family: Roboto;
              font-size: 14px;
              font-style: normal;
              font-weight: 600;
              line-height: normal;
              text-transform: capitalize;
              &:hover {
                background: #219d50;
                color: #fff;
              }
            }
          }
          .status-box {
            display: flex;
            padding: 4px 8px;
            width: fit-content;
            justify-content: center;
            align-items: center;
            font-family: Roboto;
            border-radius: 4px;
            font-size: 12px;
            font-style: normal;
            font-weight: 600;
            line-height: 150%; /* 18px */
            &.Paid,
            &.Shipped,
            &.Completed,
            &.Processing {
              background: rgba(33, 157, 80, 0.6);
              color: #fff;
            }
            &.Cancelled {
              background: #e1544a;
              color: #ffff;
            }
            &.Ready.For.Pickup {
              background: #219d50;
              color: #fff;
            }
            &.Pending.Payment {
              background: #219d50;
              color: #fff;
            }
            &.Submitted {
              background: #2f353166;
              color: #fff;
            }
          }
          .products-mobile-table {
            display: flex;
            flex-direction: column;
            padding: 16px 0px;
            border-bottom: 1px solid #0000001d;
          }
          .presc-data {
            display: flex;
            flex-direction: column;
            gap: 19px;
            padding: 16px 0px;
            border-bottom: 1px solid rgba(0, 0, 0, 0.231);
            .top {
              display: flex;
              justify-content: space-between;
              align-items: center;
              p {
                padding: 0px;
                margin: 0px;
                color: #000;
                font-family: Roboto;
                font-size: 14px;
                font-weight: 700;
                line-height: 140%; /* 19.6px */
              }
            }
          }
        }
      }
    }
  }
}
