.notification-alert-nav{
    display: flex;
    flex-direction: column;
    position: relative;
    .notification {
        position: relative;
        cursor: pointer;
        display: flex;
        padding: 0px 4px;
        align-items: center;
        gap: 10px;
        color: #219D50;
        .new-notification-circle{
          top: 45%;
          right: 7px;
          position: absolute;
          width: 6.875px;
          height: 6.875px;
          background-color: #219D50;
          border-radius: 50%;
          border: 2px solid #fff;
        }
      }
      .notification-container{
        max-height: 0px;
        padding: 0px;
        position: absolute;
        top: 180%;
        right: 0;
        display: flex;
        flex-direction: column;
        border-radius: 10px;
        background: #FFF;
        box-shadow: 4px 4px 12px 0px rgba(0, 0, 0, 0.15);
        transition: .3s all ease-in-out;
        overflow: hidden;
        @media (max-width: 560px) {
          width: calc(100vw - 24px);
          right: -63px;
          }
        &.open{
          border: 1px solid #E8E8E8;
          max-height:600px;
          padding: 12px;
        }
         .notification-list{ 
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 4px;
        max-height: 276px;
        overflow: auto;
        &::-webkit-scrollbar {
          width:5px;
        }
      &::-webkit-scrollbar-thumb {
          background: #eee; 
          width: 5px;
        }
        .empty-not{
          padding: 16px;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 427px;
          border-radius: 8px;
          color: #A7A7A7;
          @media (max-width: 560px) {
            width: calc(100% - 32px);
            }
        }
      }
      }
      .load-more-div{
        border-top: 1px solid rgba(0, 0, 0, 0.10);
        display: flex;
        padding: 24px;
        align-items: center;
        gap: 37px;
        align-self: stretch;
        .see-more-notication{
            color:  #A7A7A7;
            font-family: Roboto;
            font-size: 14px;
            font-weight: 600;
            text-transform: capitalize;
        }
      }
}
