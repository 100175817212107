.singleOrder{
    background: #F8F8F8;
    .main-conatiner{
    display: flex;
    padding: 2.6% 5.2%;
    justify-content: center;
    align-items: center;
    @media (max-width: 390px) {
        padding: 0px;
      }
    .wrapper{
    display: flex;
    width: 100%;
    gap: 24px;
    max-width: 1352px;
    .right-div-single-order{
        display: flex;
        padding: 32px;
        flex-direction: column;
        align-items: flex-start;
        gap: 24px;
        border-radius: 10px;
        background: #FFF;
        width: calc(100% - 417px);
        @media (max-width: 1100px) {
            width: calc(100% - 32px);
            padding: 32px 16px;
        }
        .back-bttn{
            color: #219D50;
            font-family: Roboto;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            text-transform: capitalize;
            gap: 10px;
        }
        .order-title-div{
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            @media (max-width: 780px) {
                align-items: flex-start;
            }
            .left{
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: 4px;
                .top{
                    display: flex;
                    align-items: center;
                    gap: 12px;
                    .order-title{
                        color:  #000;
                        font-family: Roboto;
                        font-size: clamp(20px,6vw ,24px);
                        font-style: normal;
                        font-weight: 600;
                        line-height: 150%; /* 36px */
                        letter-spacing: 0.36px;
                    }
                    .order-number, .order-date{
                        color: #5c5f62aa;
                        font-family: Roboto;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 150%; /* 21px */
                        @media (max-width: 780px) {
                            display: none;
                        }
                    }
                    .divider{
                        @media (max-width: 780px) {
                            display: none;
                        }
                    }
                }
           
                .bottom-order-status{
                    display: flex;
                    height: 29px;
                    padding: 4px 8px;
                    justify-content: center;
                    align-items: center;
                    gap: 8px;
                    border-radius: 4px;
                    background: rgba(104, 212, 145, 0.27);
                    color: var(--Main-Green, var(--Fruit-Salad-600, #219D50));
                    font-family: Roboto;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 150%;
                }
            }
            .reorder-bttn{
                display: flex;
                padding: 10px 20px;
                justify-content: center;
                align-items: center;
                gap: 10px;
                border-radius: 10px;
                background:  #219D50;
                color:  #FFF;
                font-family: Roboto;
                font-size: 14px;
                font-weight: 600;
                text-transform: capitalize;
                max-height: 36px;
            }
        }
        .order-number-mobile, .order-date-mobile{
            display: none;
            color: #5c5f62aa;
            font-family: Roboto;
            font-size: 12px;
            font-style: normal;
            font-weight: 600;
            line-height: 150%; /* 21px */
            @media (max-width: 1100px) {
                display: block;
        }
    }
        .all-order-info{
            display: flex;
            gap: 40px;
            width: calc(100% - 40px);
            border-radius: 10px;
            border: 2px dashed #E3E3E3;
            background: #FBFBFB;
            display: flex;
            padding: 16px 16px 16px 24px;
            @media (max-width: 780px) {
                flex-direction: column;
            }
            .left-box{
               flex: 1;
               display: flex;
               flex-direction: column;
               gap: 24px;
               .address-div{
                text-align: left;
                display: flex;
                flex-direction: column;
                gap: 8px;
                p{
                font-family: Roboto;
                    &:first-child{
                        color:#303030;
                        font-size: clamp(18px, 4vw, 20px);
                        font-weight: 700;
                    }
                    &:last-child{
                        color:#717286;
                        font-size: 16px;
                        font-weight: 400;
                    }
                }
               }
               .delivery-div{
                display: flex;
                justify-content: space-between;
                @media (max-width: 435px) {
                    flex-wrap: wrap;
                    gap: 10px;
                 }
                .delivery-inner-div{
                    flex: 1;
                    display: flex;
                    flex-direction: column;
                    text-align: left;
                    gap: 16px;
                    &:last-child{
                        max-width: fit-content;
                    }
                    p{
                        font-family: Roboto;
                        color:#303030;
                        font-size: clamp(18px, 4vw, 20px);
                        font-weight: 700;    
                }
                .delivery-info{
                    display: flex;
                    gap: 8px;
                    align-items: center;
                    p{
                        color: #717286;
                        font-family: Roboto;
                        font-size: 16px;
                        font-weight: 400;
                    }
                    img{
                        max-width: 52px;
                    }
                }
               }
            }
            .payment-div{
                display: flex;
                flex-direction: column;
                gap: 24px;
                text-align: left;
                .div-title{
                    font-family: Roboto;
                    color:#303030;
                    font-size: 20px;
                    font-weight: 700;
                }
                img{
                    width: 50px;
                }
                .bottom{
                    display: flex;
                    flex-direction: column;
                    gap: 8px;
                    .card-number{
                        color: #080E26;
                        font-family: Roboto;
                        font-size: 16px;
                        font-weight: 500;
                    }
                    .card-info{
                        display: flex;
                        justify-content: space-between;
                        color:  #717286;
                        font-family: Roboto;
                        font-size: 14px;
                        font-weight: 400;
                    }
                }
                
            }
        }
        .right-box{
            flex: 1;
            display: flex;
            flex-direction: column;
            gap: 16px;
            padding: 24px;
            border-radius: 10px;
            border: 1px solid #DEDEDE;
            .top-div-summary{
                display: flex;
                flex-direction: column;
                gap: 24px;
                .price{
                    display: flex;
                    justify-content: space-between;
                    color: #393939;
                    font-family: Roboto;
                    font-size: 16px;
                    font-weight: 400;
                    @media (max-width: 780px) {
                        font-size: 14px;
                     }
                }
               
            }
            .subtotal{
                padding: 12px 0px;
                display: flex;
                justify-content: space-between;
                color: #303030;
                font-family: Roboto;
                font-size: 24px;
                font-weight: 600;
                letter-spacing: 0.36px;
                @media (max-width: 780px) {
                   font-size: 16px;
                }
            }
        }
    }
        }
    }
}
}