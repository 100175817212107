.info-div{
    display: flex;
    flex-direction: column;
    gap: 18px;
    .payments-boxes-wrapper{
        display: flex;
        flex-wrap: wrap;
        gap: 16px;
        border-radius: 10px;
        padding: 16px;
        border: 1px solid #DEDEDE;
        height: 87px;
        &.disabled{
            opacity: 0.4;
            pointer-events: none;
        }
        @media (max-width: 380px) {
            padding: 12px;
            }
        @media (max-width: 860px) {
            height: 186px;
            }
        overflow: auto;
        &::-webkit-scrollbar {
            width:5px;
          }
        &::-webkit-scrollbar-thumb {
            background: #eee; 
            width: 5px;
          }
        .payment-box{
            cursor: pointer;
            position: relative;
            border-radius: 10px;
            border: 1px solid #DEDEDE;
            background: #FFF;
            display: flex;
            padding: 15px;
            width: calc(50% - 40px);
            gap: 16px;
            align-self: stretch;
            @media (max-width: 860px) {
                width: calc(100% - 30px);
                }
            &:hover{
                .circular-checkbox{
                    background-color: #219D50;
                }
            }
            &.default{
                border: 1px solid  #4C9B58;
                background: #F8F8F8;
                .circular-checkbox{
                    background-color: #219D50;
                }
            }
            &.disabled{
                opacity: 0.4;
            }
            .circular-checkbox{
                border: 1px solid #DEDEDE;
                width: 20px;
                height: 20px;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
            }
            .desc-box{
                width: calc(100% - 38px);
                display: flex;
                flex-direction: column;
                gap: 8px;
                .top{
                    display: flex;
                    gap: 16px;
                    align-items: center;
                    @media (max-width: 410px) {
                        flex-direction: column;
                        align-items: flex-start;
                        gap: 0px;
                        }
                    .card-number{
                        text-align: left;
                        color: #080E26;
                        font-family: Roboto;
                        font-size: clamp(14px, 2vw,18px);
                        font-weight: 500;
                        line-height: 150%; /* 24px */
                    }
                    .badge{
                        display: flex;
                        padding: 4px 8px;
                        justify-content: center;
                        align-items: center;
                        border-radius: 4px;
                        background: #D6F3E1;
                        color: var(--Fruit-Salad-600, #219D50);
                        font-family: Roboto;
                        font-size: 10px;
                        font-weight: 600;
                        line-height: 150%; /* 15px */
                    }
                }
                
            }
                .bottom-div{
                    display: flex;
                    flex-direction: column;
                    gap: 8px;
                    width: 100%;
                    .card-details{
                        width: 100%;
                        display: flex;
                        justify-content: space-between;
                        p{
                            color: var(--Grey-500, #717286);
                            font-family: Roboto;
                            font-size: 14px;
                            font-weight: 400;
                            line-height: 150%; /* 21px */
                            padding-right: 30px;
                        }
                    }
                }
                .absolute-div{
                    display: flex;
                    position: absolute;
                    align-items: center;
                    gap: 8px;
                    right: 15px;
                    top:15px;
                    .edit-bttn{
                        height: 24px;
                        width: 24px;
                        color: #219D50;
                    }
                }
           
        }
    }
   
}
.empty-div-payment-box{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 8px;
    width: 100%;
    height: 100%;
    p{
        color: #717286;
        text-align: center;
        font-family: Roboto;
        font-size: 16px;
        font-weight: 400;
        line-height: 150%; /* 24px */
    }
}