.HomeWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  scroll-behavior: smooth;
  overflow: hidden;
  .SectionOne {
    // padding-top: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px 5.3%;
    max-width: 89.4%;
    @media (max-width: 960px) {
      padding-top: 40px;
      max-width: 100vw;
    }
    .tabs-outer {
      width: 100%;
      max-width: 1352px;
      .MuiTabs-root.MuiTabs-horizontal.wrapper {
        display: flex;
        flex-direction: column-reverse;
        .MuiTabPanel-root {
          display: flex;
          // padding: 0px 50px;
          align-items: center;
          position: relative;
          justify-content: center;
          @media (max-width: 960px) {
            padding: 0 24px;
          }
          .body-wrapper {
            gap: 47px;
            display: flex;
            padding-top: 40px;
            @media (max-width: 1060px) {
              // flex-direction: column-reverse;
              gap: 24px;
              padding-bottom: 0;
              padding-top: 40px;
              max-width: 90vw;
            }
            @media (max-width: 960px) {
              flex-direction: column-reverse;
            }
            .left {
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              gap: 10px;
              text-align: left;
              justify-content: center;
              width: calc(60% - 23.5px);
              @media (max-width: 1060px) {
                width: calc(60% - 24px);
              }
              @media (max-width: 960px) {
                width: 100%;
              }
              .subheader {
                display: flex;
                align-items: center;
                gap: 22px;
                color: var(--Fruit-Salad-800, #1c5f36);
                // font-family: Roboto;
                font-size: 16px;
                font-style: normal;
                font-weight: 700;
                line-height: 131.5%; /* 21.04px */
                letter-spacing: 1.44px;
                text-transform: uppercase;
                hr {
                  width: 73.5px;
                  height: 2.5px;
                  border-radius: 30px;
                  background: var(--Fruit-Salad-800, #1c5f36);
                }
              }
              .header {
                color: var(--Main-Green, var(--Fruit-Salad-600, #219d50));
                // font-family: Gabarito;
                font-size: clamp(28px, 5vw, 62px);
                font-style: normal;
                font-weight: 700;
                line-height: 120%; /* 76.8px */
                @media (max-width: 960px) {
                  color: var(--Main-Green, var(--Fruit-Salad-600, #219d50));
                  // font-family: Gabarito;
                  font-size: clamp(32px, 5vw, 40px);
                  font-style: normal;
                  font-weight: 700;
                  line-height: 120%; /* 48px */
                  br {
                    display: none;
                  }
                }
              }
              .note {
                color: var(--Basic-Black, #000);
                // font-family: Roboto;
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: 150%; /* 27px */
                letter-spacing: 0.27px;
                @media (max-width: 960px) {
                  color: var(--Basic-Black, #000);
                  // font-family: Roboto;
                  font-size: 16px;
                  font-style: normal;
                  font-weight: 500;
                  line-height: 150%; /* 24px */
                }
              }
              .cta {
                display: flex;
                padding: 12px 24px;
                justify-content: center;
                align-items: center;
                gap: 10px;
                border: none;
                border-radius: 10px;
                background: #219d50;
                margin: 50px 0;
                color: #fff;
                // font-family: Roboto;
                font-size: 18px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                text-transform: capitalize;
                cursor: pointer;
                transition: 300ms;
                &:hover {
                  background: #0c7935;
                }
                @media (max-width: 960px) {
                  align-self: stretch;
                  color: var(--Main-White, #fff);
                  font-size: 18px;
                  padding: 16px 24px;
                  margin-top: 24px;
                  margin-bottom: 40px;
                }
              }
              .lastSlideCTA {
                display: flex;
                align-items: center;
                gap: 16px;
                margin-bottom: 50px;
                .reorder {
                  display: flex;
                  padding: 16px 24px;
                  justify-content: flex-end;
                  align-items: center;
                  gap: 16px;
                  border-radius: 8px;
                  background: var(--Foggy-Gray-50, #f5f5f1);
                  color: var(--Main-Green, var(--Fruit-Salad-600, #219d50));
                  // font-family: Inter;
                  font-size: 18px;
                  font-style: normal;
                  font-weight: 700;
                  line-height: normal;
                  text-transform: capitalize;
                  border: none;
                  cursor: pointer;
                  transition: 300ms;
                  &:hover {
                    background: #219d50;
                    color: #f5f5f1;
                  }
                }
                .switch {
                  display: flex;
                  padding: 16px 0px;
                  justify-content: flex-end;
                  align-items: center;
                  gap: 8px;
                  color: var(--Main-Green, var(--Fruit-Salad-600, #219d50));
                  // font-family: Inter;
                  font-size: 18px;
                  font-style: normal;
                  font-weight: 700;
                  line-height: normal;
                  text-transform: capitalize;
                  border: none;
                  cursor: pointer;
                  background-color: transparent;
                  transition: 300ms;
                  &:hover {
                    scale: 1.1;
                  }
                }
              }
              .buttons {
                display: flex;
                align-items: flex-start;
                gap: 16px;
                img {
                  cursor: pointer;
                  transition: 300ms;
                  &:hover {
                    scale: 1.1;
                  }
                }
              }
            }
            .right {
              position: relative;
              width: calc(40% - 23.5px);
              img {
                width: 100%;
              }
              .whiteBG {
                min-height: 30vh;
              }
              @media (max-width: 1060px) {
                width: calc(40% - 24px);
              }
              @media (max-width: 960px) {
                width: 100%;
                img {
                  width: 78%;
                }
              }
              .background.top {
                position: absolute;
                top: 10%;
                max-width: 265px;
                @media (max-width: 960px) {
                  display: none;
                }
              }
            }
            .background.bottm {
              position: absolute;
              bottom: 0%;
              right: 0;
              max-width: 147px;
              @media (max-width: 960px) {
                top: 0;
                right: 0;
              }
            }
          }
        }
        .MuiTab-root{
          padding: 0px;
        }
        .MuiTabsList-root {
          display: flex;
          align-items: center;
          gap: 1%;
          align-self: stretch;
          margin: 24px 0px 64px 0px;
          @media (max-width: 1020px) {
            // gap: 8px;
            padding: 0 16px;
            padding-bottom: 24px;
            overflow: scroll;
          }
          button {
            display: flex;
            border: none;
            align-items: center;
            flex: 1 0 0;
            background-color: transparent;
            padding: 16px;
            // padding-right: 40px;
            // border-right: 3px solid #0000001e;
            opacity: 0.6;
            
            cursor: pointer;
            transition: 300ms;
            &:first-child{
              padding-left: 0px;
            }
            &:last-child{
              padding-right: 0px;
            }
            @media (max-width: 1300px) {
              padding: 0px;
            }
            // @media (max-width: 1200px) {
            //   padding-right: 15px;
            // }
            @media (max-width: 1020px) {
              max-width: 250px;
              // padding-right: 10px;
            }
          }
          button.Mui-selected {
            opacity: 1;
          }
          hr {
            width: 0px;
            height: 40px;
            background-color: #00000010;
          }
          .tabtitle {
            pointer-events: none;
            display: flex;
            align-items: center;
            text-align: left;
            gap: 1vw;
            height: 88px;
            width: 100%;
            img {
              max-width: 88px;
              height: 88px;
              @media (max-width: 1300px) {
                max-width: 80px;
              height: 80px;
              }
            }
            .txt{
              display: block;
              color: #29241F;
              font-family: Roboto;
              font-size: 15px;
              font-weight: 400;
              line-height: 150%; /* 24px */
            }
            .customDivider{
              height: 60%;
              width: 3px;
              border-radius: 5px;
              background-color: rgba(0, 0, 0, 0.10);
            }
            @media (max-width: 1020px) {
              width: 246px;
            }
          }
        }
      }
      .buttons {
        display: flex;
        justify-content: center;
        gap: 16px;
        img {
          cursor: pointer;
          transition: 300ms;
          &:hover {
            scale: 1.1;
          }
        }
      }
    }
  }
  .SectionTwo {
    display: flex;
    padding: 28px 0px;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    max-width: 1300px;
    gap: 28px;
    align-self: center;
    @media (max-width: 1060px) {
      display: none;
    }
    a {
      color: var(--Light-Grey-Text, #a7a7a7);
      // font-family: Roboto;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      text-transform: capitalize;
      text-decoration: none;
      transition: 300ms;
      &:hover {
        color: #000;
      }
    }
  }
  .SectionThree {
    display: flex;
    flex-direction: column;
    max-width: 1300px;
    padding: 80px 0;
    margin-bottom: 40px;
    justify-content: center;
    align-items: center;
    gap: 50px;
    position: relative;
    width: 95%;
    @media (max-width: 960px) {
      padding: 80px 12px 0px 12px;
      margin-bottom: 40px;
      max-width: calc(100vw - 24px);
      width: calc(100% - 24px);
    }
    .top {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 9px;
      .subheader {
        color: var(--Fruit-Salad-800, #1c5f36);
        text-align: center;
        // font-family: Roboto;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 131.5%; /* 21.04px */
        letter-spacing: 1.44px;
        text-transform: uppercase;
      }
      .header {
        color: #3c3c3c;
        text-align: center;
        // font-family: Gabarito;
        font-size: clamp(32px,4vw,56px);
        font-style: normal;
        font-weight: 700;
        line-height: 120%; /* 67.2px */
        @media (max-width: 960px) {
          font-size: 32px;
        }
      }
      .note {
        color: var(--Main-Black, #1e1e1e);
        // font-family: Roboto;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; /* 30px */
        letter-spacing: 0.3px;
        @media (max-width: 960px) {
          font-size: 16px;
          font-weight: 700;
          line-height: 131.5%; /* 21.04px */
          letter-spacing: 0.24px;
        }
      }
    }
    .bottom {
      width: 100%;
      z-index: 2;
      display: flex;
      flex-direction: column;
      gap:37px;
      .CTAbutton {
        display: flex;
        padding: 12px 24px;
        gap: 10px;
        width: -moz-fit-content;
        border-radius: 10px;
        background: var(--Main-Green, #219d50);
        color: var(--Main-White, #fff);
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        transition: 300ms;
        align-self: center;
        justify-content: center;
        align-items: center;
        &:hover {
          background: #0c7935;
        }
        @media (max-width: 960px) {
          align-self: center;
          width: -webkit-fill-available;
          max-width: calc(100vw - 88px);
        }
      }
    }
    .background {
      position: absolute;
      bottom: 30px;
      right: -10px;
      @media (max-width: 960px) {
        display: none;
      }
    }
  }
  .SectionFour {
    display: flex;
    padding: 80px 112px;
    justify-content: center;
    align-items: center;
    gap: 47px;
    align-self: stretch;
    background: var(--Foggy-Gray-50, #f5f5f1);
    @media (max-width: 960px) {
      padding: 40px 24px;
      max-width: calc(100vw - 48px);
    }
    .wrap {
      max-width: 1350px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 24px;
      align-self: stretch;
      @media (max-width: 960px) {
        max-width: 100%;
      }
      .top {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 8px;
        .subheader {
          color: var(--Fruit-Salad-800, #1c5f36);
          text-align: center;
          // font-family: Roboto;
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: 131.5%; /* 21.04px */
          letter-spacing: 1.44px;
          text-transform: uppercase;
        }
        .header {
          color: #3c3c3c;
          text-align: center;
          // font-family: Gabarito;
          font-size: 56px;
          font-style: normal;
          font-weight: 700;
          line-height: 120%; /* 67.2px */
          @media (max-width: 960px) {
            font-size: 32px;
            font-weight: 700;
          }
        }
        .greenSubheader {
          color: var(--Fruit-Salad-600, #219d50);
          text-align: center;
          // font-family: Roboto;
          font-size: 32px;
          font-style: italic;
          font-weight: 600;
          line-height: 120%; /* 38.4px */
          letter-spacing: 0.48px;
          margin: 24px 0;
          @media (max-width: 960px) {
            font-size: 20px;
            font-weight: 700;
          }
        }
        .note {
          color: var(--Main-Black, #1e1e1e);
          // font-family: Roboto;
          font-size: 20px;
          font-style: normal;
          font-weight: 400;
          line-height: 150%; /* 30px */
          letter-spacing: 0.3px;
          @media (max-width: 960px) {
            font-size: 16px;
            font-weight: 700;
            line-height: 131.5%;
            letter-spacing: 0.24px;
          }
        }
      }
      .bottom {
        display: flex;
        align-items: flex-start;
        gap: 28px;
        // flex: 1 0 0;
        flex-wrap: wrap;
        justify-content: center;
        @media (max-width: 1200px) {
          // flex-direction: column;
        }
        .box {
          display: flex;
          // height: 719px;
          align-self: stretch;
          flex-direction: column;
          align-items: center;
          gap: 24px;
          width: calc(33% - 28px);

          // width: min-content;
          // flex: 1 0 0;
          @media (max-width: 860px) {
            // flex-direction: column;
            width: calc(50% - 28px);
          }
          @media (max-width: 580px) {
            width: 100%;
          }
          img {
            width: 100%;
            border-radius: 16px;
            @media (max-width: 500px) {
              // width: auto;
              max-width: 100%;
            }
          }
          .text {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            align-items: center;
            flex: 1 0 0;
            align-self: stretch;
            span {
              border-radius: 5px;
              background: var(--Green-Light, rgba(104, 212, 145, 0.27));
              display: flex;
              padding: 10px;
              justify-content: center;
              align-items: center;
              gap: 10px;
              color: var(--Fruit-Salad-600, #219d50);
              // font-family: Roboto;
              font-size: 16px;
              font-style: normal;
              font-weight: 500;
              line-height: 150%; /* 24px */
              margin-bottom: 12px;
            }
            .title {
              color: var(--Basic-Black, #000);
              // font-family: Roboto;
              font-size: clamp(20px, 2vw, 28px);
              font-style: normal;
              font-weight: 600;
              line-height: 150%; /* 42px */
              letter-spacing: 0.42px;
              @media (max-width: 960px) {
                font-size: 20px;
                font-weight: 700;
                letter-spacing: 0.3px;
              }
            }
            .note {
              color: #3c3c3c;
              text-align: center;
              // font-family: Roboto;
              font-size: 20px;
              font-style: normal;
              font-weight: 400;
              line-height: 150%; /* 30px */
              letter-spacing: 0.3px;
              margin: 24px 0;
              @media (max-width: 960px) {
                font-size: 16px;
                font-weight: 700;
                letter-spacing: 0.24px;
              }
            }
            .button {
              display: flex;
              padding: 12px 24px;
              justify-content: center;
              align-items: center;
              gap: 10px;
              align-self: stretch;
              border-radius: 10px;
              background: var(--Main-Green, #219d50);
              color: var(--Main-White, #fff);
              // font-family: Roboto;
              font-size: 18px;
              font-style: normal;
              font-weight: 700;
              line-height: normal;
              text-transform: capitalize;
              text-decoration: none;
              cursor: pointer;
              transition: 300ms;
              &:hover {
                background: #0c7935;
              }
            }
            .button.white {
              border: 1.5px solid var(--Main-Green, #219d50);
              background-color: transparent;
              color: #219d50;
              &:hover {
                background: #219d50;
                color: #fff;
              }
            }
          }
        }
      }
    }
  }
  .SectionFive {
    display: flex;
    padding: 80px;
    align-items: center;
    position: relative;
    width: -webkit-fill-available;
    justify-content: center;
    @media (max-width: 960px) {
      padding: 40px 24px;
      max-width: calc(100vw - 48px);
    }
    .wrap {
      max-width: 1300px;
      display: flex;
      align-items: center;
      gap: 64px;
      z-index: 2;
      @media (max-width: 1060px) {
        max-width: 100%;
        flex-direction: column;
      }
      @media (max-width: 600px) {
        img {
          max-width: 100%;
        }
      }
      .textbox {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 8px;
        align-self: stretch;
        justify-content: center;
        text-align: left;
        .subheader {
          color: var(--Fruit-Salad-800, #1c5f36);
          // font-family: Roboto;
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: 131.5%; /* 21.04px */
          letter-spacing: 1.44px;
          text-transform: uppercase;
        }
        .header {
          color: #3c3c3c;
          // font-family: Gabarito;
          font-size: 48px;
          font-style: normal;
          font-weight: 700;
          line-height: 120%;
          @media (max-width: 1060px) {
            font-size: 30px;
            font-weight: 700;
            br {
              display: none;
            }
          }
        }
        .note {
          color: var(--Main-Black, #1e1e1e);
          // font-family: Roboto;
          font-size: 20px;
          font-style: normal;
          font-weight: 400;
          line-height: 150%; /* 30px */
          letter-spacing: 0.3px;
          margin: 24px 0;
          text-align: left;
          @media (max-width: 960px) {
            font-size: 16px;
            font-weight: 700;
            letter-spacing: 0.24px;
          }
        }
        .question {
          color: var(--Variable-Grey-Text, #5c5f62);
          // font-family: Roboto;
          font-size: 32px;
          font-style: normal;
          font-weight: 400;
          line-height: 120%; /* 38.4px */
          letter-spacing: 0.48px;
          margin-bottom: 16px;
          @media (max-width: 960px) {
            font-size: 24px;
            font-weight: 700;
            letter-spacing: 0.36px;
          }
        }
        .button {
          display: flex;
          padding: 12px 24px;
          justify-content: center;
          align-items: center;
          gap: 10px;
          border-radius: 10px;
          border: 1.5px solid var(--Main-Green, #219d50);
          color: var(--Main-Green, var(--Fruit-Salad-600, #219d50));
          // font-family: Roboto;
          font-size: 18px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          text-transform: capitalize;
          text-decoration: none;
          &:hover {
            background: #219d50;
            color: #fff;
          }
          @media (max-width: 960px) {
            align-self: stretch;
          }
        }
      }
      // img {
      //   width: 541px;
      //   height: 554px;
      // }
    }
    .topImage {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
    }
    .bottomImage {
      position: absolute;
      bottom: -10%;
      right: 0;
      z-index: 1;
      @media (max-width: 960px) {
        display: none;
      }
    }
  }
  .hr {
    width: clamp(50%, 1000px, 80%);
    height: 0px;
    border-radius: 30px;
    background: rgba(0, 0, 0, 0.05);
    margin: 24px 0;
    @media (max-width: 960px) {
      display: none;
    }
  }
  .SectionSix {
    display: flex;
    // padding: 80px;
    padding: 5%;
    width: 95%;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 40px;
    align-self: stretch;
    max-width: 1300px;
    align-self: center;
    @media (max-width: 1260px) {
      padding: 40px 24px;
      max-width: calc(100vw - 48px);
    }
    .top {
      display: flex;
      padding: 48px;
      align-items: center;
      align-self: stretch;
      justify-content: space-between;
      border-radius: 16px;
      background-color: var(--Fruit-Salad-100, #defae8);
      background-image: url(../../assets/GroupBackground.png);
      background-repeat: no-repeat;
      background-position: right;
      @media (max-width: 1060px) {
        padding: 40px 16px;
        flex-direction: column;
        background-position: bottom;
        background-size: contain;
        gap: 34px;
      }
      .text {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 8px;
        text-align: left;
        @media (max-width: 1260px) {
          text-align: center;
          align-items: center;
        }
        .subheader {
          color: var(--Fruit-Salad-800, #1c5f36);
          // font-family: Roboto;
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: 131.5%; /* 21.04px */
          letter-spacing: 1.44px;
          text-transform: uppercase;
          @media (max-width: 960px) {
            font-size: 14px;
            letter-spacing: 1.26px;
            text-align: center;
          }
        }
        .header {
          margin-bottom: 24px;
          color: var(--Fruit-Salad-600, #219d50);
          // font-family: Gabarito;
          font-size: 48px;
          font-style: normal;
          font-weight: 700;
          line-height: 120%;
          @media (max-width: 960px) {
            font-size: 32px;
          }
        }
        .note {
          color: #1d1d1d;
          // font-family: Roboto;
          font-size: 20px;
          font-style: normal;
          font-weight: 500;
          line-height: 150%; /* 30px */
          letter-spacing: 0.3px;
          @media (max-width: 960px) {
            font-size: 17px;
            font-weight: 700;
            line-height: 120%; /* 21.6px */
            letter-spacing: 0.2px;
          }
        }
      }
      .button {
        display: flex;
        padding: 12px 24px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 10px;
        background: var(--Main-Green, #219d50);
        color: var(--Main-White, #fff);
        // font-family: Roboto;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-transform: capitalize;
        &:hover {
          background: #0c7935;
        }
        @media (max-width: 1060px) {
          align-self: stretch;
        }
      }
    }
    .bottom {
      display: flex;
      align-items: flex-start;
      gap: 45px;
      align-self: stretch;
      @media (max-width: 1260px) {
        flex-direction: column;
        gap: 40px;
      }
      .left {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 24px;
        flex: 1 0 0;
        max-width: 28%;
        @media (max-width: 1260px) {
          max-width: 100%;
          gap: 40px;
          width: -webkit-fill-available;
        }
        .heading {
          color: var(--Variable-Grey-Text, #5c5f62);
          // font-family: Roboto;
          font-size: 24px;
          font-style: normal;
          font-weight: 500;
          line-height: 130%; /* 31.2px */
        }
        .boxesWrapper {
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 24px;
          @media (max-width: 1260px) {
            flex-direction: row;
            gap: 8px;
          }
          .box {
            display: flex;
            height: clamp(180px, 335px, 380px);
            padding: 24px 0px;
            flex-direction: column;
            justify-content: flex-end;
            align-items: center;
            gap: 24px;
            align-self: stretch;
            border-radius: 12px;
            background: var(--Variable-Off-White, #f7f6fb);
            position: relative;
            @media (max-width: 1260px) {
              height: clamp(190px, 200px, 250px);
              width: 50%;
            }
            img {
              position: absolute;
              left: auto;
              top: -26px;
              width: 190px;
              height: 189px;
              @media (max-width: 1260px) {
                max-width: 92px;
                max-height: 91px;
              }
            }
            .title {
              color: var(--Foggy-Gray-950, #29241f);
              // font-family: Roboto;
              font-size: 28px;
              font-style: normal;
              font-weight: 800;
              line-height: 150%; /* 42px */
              letter-spacing: 0.42px;
              display: flex;
              flex-direction: column;
              align-items: center;
              gap: 8px;
              @media (max-width: 960px) {
                font-size: 14px;
                font-weight: 700;
                letter-spacing: 0.21px;
              }
              span {
                color: var(--Foggy-Gray-500, #8c8666);
                // font-family: Roboto;
                font-size: 18px;
                font-style: normal;
                font-weight: 500;
                line-height: 150%; /* 27px */
                letter-spacing: 0.27px;
                @media (max-width: 960px) {
                  font-size: 12px;
                  font-weight: 700;
                  letter-spacing: 0.18px;
                }
              }
            }
            .button {
              display: flex;
              padding: 12px 24px;
              justify-content: center;
              align-items: center;
              border-radius: 10px;
              border: 1.5px solid var(--Main-Green, #219d50);
              color: var(--Main-Green, var(--Fruit-Salad-600, #219d50));
              // font-family: Roboto;
              font-size: 18px;
              font-style: normal;
              font-weight: 700;
              line-height: normal;
              text-transform: capitalize;
              &:hover {
                background: #219d50;
                color: #fff;
              }
              @media (max-width: 1260px) {
                font-size: 14px;
                margin-top: 20px;
              }
            }
            .badge {
              display: flex;
              max-width: 70px;
              max-height: 70px;
              padding: 16px 24px;
              justify-content: center;
              align-items: center;
              gap: 16px;
              position: absolute;
              left: -39px;
              top: 20px;
              border-radius: 59px;
              background: var(--Green-Light, rgba(104, 212, 145, 0.27));
              color: var(--Basic-Black, #000);
              text-align: center;
              // font-family: Roboto;
              font-size: 20px;
              font-style: normal;
              font-weight: 700;
              line-height: 150%; /* 30px */
              @media (max-width: 1060px) {
                max-width: 40px;
                max-height: 40px;
                padding: 7px 10px;
                color: var(--Basic-Black, #000);
                font-size: 8.889px;
                letter-spacing: 0.133px;
                top: -12px;
                left: -10px;
              }
            }
          }
          .box.green {
            background: var(--Green-Light, rgba(104, 212, 145, 0.27));
            .button {
              border: none;
              color: #fff;
              background: #219d50;
              &:hover {
                background: #0c7935;
              }
            }
          }
        }
      }
      .right {
        max-width: 100%;
        // @media (max-width: 1260px) {
        //   max-width: 100%;
        // }
      }
    }
  }
  .SectionSeven {
    display: flex;
    padding: 80px;
    align-items: center;
    background: var(--Foggy-Gray-50, #f5f5f1);
    position: relative;
    width: -webkit-fill-available;
    justify-content: center;
    @media (max-width: 960px) {
      padding: 40px 24px;
      max-width: calc(100vw - 48px);
    }
    .wrap {
      display: flex;
      align-items: center;
      gap: 64px;
      max-width: 1300px;
      @media (max-width: 960px) {
        max-width: 100%;
        flex-direction: column-reverse;
        z-index: 2;
        gap: 40px;
      }
      .text {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        text-align: left;
        z-index: 3;
        .subheader {
          color: var(--Fruit-Salad-800, #1c5f36);
          // font-family: Roboto;
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: 131.5%; /* 21.04px */
          letter-spacing: 1.44px;
          text-transform: uppercase;
        }
        .header {
          color: #3c3c3c;
          // font-family: Gabarito;
          font-size: 48px;
          font-style: normal;
          font-weight: 700;
          line-height: 120%; /* 57.6px */
          @media (max-width: 960px) {
            font-size: 30px;
          }
        }
        .note {
          color: var(--Main-Black, #1e1e1e);
          // font-family: Roboto;
          font-size: 20px;
          font-style: normal;
          font-weight: 400;
          line-height: 150%; /* 30px */
          letter-spacing: 0.3px;
          margin: 24px 0;
          text-align: left;
          @media (max-width: 960px) {
            font-size: 18px;
            font-weight: 700;
          }
        }
        .button {
          display: flex;
          padding: 12px 24px;
          justify-content: center;
          align-items: center;
          gap: 10px;
          border-radius: 10px;
          border: 1.5px solid var(--Main-Green, #219d50);
          color: var(--Main-Green, var(--Fruit-Salad-600, #219d50));
          // font-family: Roboto;
          font-size: 18px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          text-transform: capitalize;
          text-decoration: none;
          &:hover {
            background: #219d50;
            color: #fff;
          }
          @media (max-width: 960px) {
            align-self: stretch;
          }
        }
        .button.green {
          border: none;
          color: #fff;
          background: #219d50;
          &:hover {
            background: #0c7935;
          }
        }
      }
      img {
        width: 541px;
        height: 554px;
        @media (max-width: 960px) {
          width: auto;
          height: auto;
          max-width: 100%;
        }
      }
    }
    .topImage {
      position: absolute;
      top: -5%;
      left: 0;
      @media (max-width: 960px) {
        display: none;
      }
    }
    .bottomImage {
      position: absolute;
      bottom: -20%;
      right: 0;
      @media (max-width: 960px) {
        z-index: 1;
        bottom: -10px;
      }
    }
    .background {
      position: absolute;
      right: 0;
      bottom: 0;
    }
  }
  .SectionEight {
    display: flex;
    padding: 112px 80px 80px 80px;
    align-items: center;
    background: var(--Foggy-Gray-50, #f5f5f1);
    width: -webkit-fill-available;
    justify-content: center;
    @media (max-width: 1260px) {
      padding: 40px 24px;
      max-width: calc(100vw - 48px);
    }
    .wrap {
      display: flex;
      align-items: center;
      gap: 64px;
      max-width: 1350px;
      @media (max-width: 1260px) {
        max-width: 100%;
        flex-direction: column;
        z-index: 2;
        gap: 40px;
        img {
          max-width: 100%;
        }
      }
      .leftSide {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 32px;
        flex: 1 0 0;
        .text {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
          text-align: left;
          .subheader {
            color: var(--Fruit-Salad-800, #1c5f36);
            // font-family: Roboto;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 131.5%; /* 21.04px */
            letter-spacing: 1.44px;
            text-transform: uppercase;
          }
          .header {
            color: #3c3c3c;
            // font-family: Gabarito;
            font-size: 48px;
            font-style: normal;
            font-weight: 700;
            line-height: 120%; /* 57.6px */
            @media (max-width: 960px) {
              font-size: 30px;
            }
          }
        }
        .whitebox {
          display: flex;
          width: -webkit-fill-available;
          height: 301px;
          padding: 21px 37px;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
          gap: 24px;
          border-radius: 15px;
          background: #fff;
          @media (max-width: 960px) {
            padding: 21px 24px;
            width: auto;
            height: auto;
            text-align: left;
          }
          .title {
            color: var(--Fruit-Salad-600, #219d50);
            // font-family: Roboto;
            font-size: 24px;
            font-style: normal;
            font-weight: 600;
            line-height: 150%; /* 36px */
            letter-spacing: 0.36px;
            @media (max-width: 960px) {
              font-size: 20px;
              font-weight: 700;
            }
          }
          .note {
            color: var(--Main-Black, #1e1e1e);
            // font-family: Roboto;
            font-size: 20px;
            font-style: normal;
            font-weight: 400;
            line-height: 150%; /* 30px */
            letter-spacing: 0.3px;
            margin: 24px 0;
            text-align: left;
            @media (max-width: 960px) {
              font-size: 18px;
              font-weight: 700;
            }
          }
          .button {
            display: flex;
            padding: 12px 24px;
            justify-content: center;
            align-items: center;
            gap: 10px;
            border-radius: 10px;
            border: 1.5px solid var(--Main-Green, #219d50);
            color: var(--Main-Green, var(--Fruit-Salad-600, #219d50));
            // font-family: Roboto;
            font-size: 18px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            text-transform: capitalize;
            text-decoration: none;
            &:hover {
              background: #219d50;
              color: #fff;
            }
            @media (max-width: 960px) {
              align-self: stretch;
            }
          }
          .button.green {
            border: none;
            color: #fff;
            background: #219d50;
            &:hover {
              background: #0c7935;
            }
          }
        }
      }
    }
  }
  .SectionNine {
    display: flex;
    padding: 80px;
    align-items: center;
    position: relative;
    width: -webkit-fill-available;
    justify-content: center;
    @media (max-width: 960px) {
      padding: 40px 24px;
      max-width: calc(100vw - 48px);
    }
    .wrap {
      max-width: 1300px;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 40px;
      @media (max-width: 960px) {
        max-width: 100%;
      }
      .upper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 8px;
        align-self: stretch;
        .subheader {
          color: var(--Fruit-Salad-800, #1c5f36);
          // font-family: Roboto;
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: 131.5%; /* 21.04px */
          letter-spacing: 1.44px;
          text-transform: uppercase;
        }
        .header {
          color: #3c3c3c;
          // font-family: Gabarito;
          font-size: 48px;
          font-style: normal;
          font-weight: 700;
          line-height: 120%; /* 57.6px */
          @media (max-width: 960px) {
            font-size: 32px;
            font-weight: 700;
          }
        }
      }
      .lower {
        display: flex;
        align-items: flex-start;
        gap: 40px;
        align-self: stretch;
        @media (max-width: 960px) {
          flex-wrap: wrap;
          gap: 8px;
        }
        .box {
          display: flex;
          padding: 32px;
          flex-direction: column;
          align-items: flex-start;
          flex: 1 0 0;
          align-self: stretch;
          border-radius: 16px;
          background: #f8f8f8;
          backdrop-filter: blur(12px);
          text-align: left;
          @media (max-width: 960px) {
            padding: 24px 16px;
          }
          img {
            max-width: 30px;
            max-height: 30px;
          }
          .title {
            color: var(--Fruit-Salad-950, #082b17);
            // font-family: Roboto;
            font-size: 20px;
            font-style: normal;
            font-weight: 700;
            line-height: 150%; /* 30px */
            margin-top: 24px;
            margin-bottom: 12px;
            @media (max-width: 960px) {
              font-size: 16px;
              font-weight: 700;
            }
          }
          .note {
            color: var(--Grey-500, #6b6a61);
            // font-family: Roboto;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 150%; /* 24px */
            @media (max-width: 960px) {
              font-size: 12px;
              font-weight: 700;
            }
          }
        }
      }
    }
  }
  .SectionTen {
    display: flex;
    padding: 90px;
    align-items: center;
    position: relative;
    width: -webkit-fill-available;
    justify-content: center;
    @media (max-width: 960px) {
      padding: 40px 24px;
      max-width: calc(100vw - 48px);
    }
    .wrap {
      max-width: 1300px;
      display: flex;
      align-items: center;
      gap: 64px;
      z-index: 2;
      @media (max-width: 960px) {
        max-width: 100%;
        gap: 24px;
        flex-direction: column;
        img {
          max-width: 100%;
        }
      }
      .textbox {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 8px;
        align-self: stretch;
        justify-content: center;
        text-align: left;
        .subheader {
          color: var(--Fruit-Salad-800, #1c5f36);
          // font-family: Roboto;
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: 131.5%; /* 21.04px */
          letter-spacing: 1.44px;
          text-transform: uppercase;
        }
        .header {
          color: #3c3c3c;
          // font-family: Gabarito;
          font-size: 48px;
          font-style: normal;
          font-weight: 700;
          line-height: 120%; /* 57.6px */
          @media (max-width: 960px) {
            font-size: 30px;
          }
        }
        .note {
          color: var(--Main-Black, #1e1e1e);
          // font-family: Roboto;
          font-size: 20px;
          font-style: normal;
          font-weight: 400;
          line-height: 150%; /* 30px */
          letter-spacing: 0.3px;
          margin: 24px 0;
          text-align: left;
          @media (max-width: 960px) {
            font-size: 17px;
            font-weight: 700;
            letter-spacing: 0.27px;
          }
        }
        .button {
          display: flex;
          padding: 12px 24px;
          justify-content: center;
          align-items: center;
          gap: 10px;
          border-radius: 10px;
          border: 1.5px solid var(--Main-Green, #219d50);
          color: var(--Main-Green, var(--Fruit-Salad-600, #219d50));
          // font-family: Roboto;
          font-size: 18px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          text-transform: capitalize;
          text-decoration: none;
          &:hover {
            background: #219d50;
            color: #fff;
          }
          @media (max-width: 960px) {
            align-self: stretch;
          }
        }
        .button.green {
          border: none;
          color: #fff;
          background: #219d50;
          &:hover {
            background: #0c7935;
          }
        }
      }
    }
    .background {
      position: absolute;
      right: 0;
      bottom: 0;
    }
  }
  .SectionEleven {
    display: flex;
    padding: 80px 5.3%;
    align-items: center;
    position: relative;
    width: -webkit-fill-available;
    justify-content: center;
    background: var(--Foggy-Gray-50, #f5f5f1);
    @media (max-width: 960px) {
      padding: 40px 24px;
      max-width: calc(100vw - 48px);
    }
    .wrap {
      max-width: 1350px;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 40px;
      @media (max-width: 960px) {
        max-width: 100%;
      }
      .upper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 8px;
        align-self: stretch;
        .subheader {
          color: var(--Fruit-Salad-800, #1c5f36);
          // font-family: Roboto;
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: 131.5%; /* 21.04px */
          letter-spacing: 1.44px;
          text-transform: uppercase;
        }
        .header {
          color: #3c3c3c;
          // font-family: Gabarito;
          font-size: 48px;
          font-style: normal;
          font-weight: 700;
          line-height: 120%; /* 57.6px */
          @media (max-width: 960px) {
            font-size: 30px;
          }
        }
      }
      .lower {
        display: flex;
        align-items: flex-start;
        gap: 40px;
        align-self: stretch;
        @media (max-width: 960px) {
          flex-direction: column;
          gap: 16px;
        }
      }
    }
  }
  .SectionTwilve {
    display: flex;
    // padding: 80px;
    padding: 5%;
    width: 95%;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 40px;
    align-self: stretch;
    max-width: 1300px;
    align-self: center;
    @media (max-width: 960px) {
      padding: 40px 24px;
      max-width: calc(100vw - 48px);
    }
    .top {
      display: flex;
      padding: 48px;
      align-items: center;
      align-self: stretch;
      justify-content: space-between;
      border-radius: 16px;
      background-color: var(--Fruit-Salad-100, #defae8);
      background-image: url(../../assets/framebackground.png);
      background-repeat: no-repeat;
      background-position: right;
      @media (max-width: 960px) {
        padding: 40px 16px;
        flex-direction: column;
        background-position: bottom;
        background-size: contain;
        gap: 34px;
      }
      .text {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 8px;
        text-align: left;
        @media (max-width: 960px) {
          text-align: center;
          align-items: center;
        }
        .subheader {
          color: var(--Fruit-Salad-800, #1c5f36);
          // font-family: Roboto;
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: 131.5%; /* 21.04px */
          letter-spacing: 1.44px;
          text-transform: uppercase;
          @media (max-width: 960px) {
            font-size: 14px;
            letter-spacing: 1.26px;
            text-align: center;
          }
        }
        .header {
          margin-bottom: 24px;
          color: var(--Fruit-Salad-600, #219d50);
          // font-family: Gabarito;
          font-size: 48px;
          font-style: normal;
          font-weight: 700;
          line-height: 120%; /* 57.6px */
          @media (max-width: 960px) {
            font-size: 32px;
          }
        }
        .note {
          color: #1d1d1d;
          // font-family: Roboto;
          font-size: 20px;
          font-style: normal;
          font-weight: 500;
          line-height: 150%; /* 30px */
          letter-spacing: 0.3px;
          @media (max-width: 960px) {
            font-size: 17px;
            font-weight: 700;
            line-height: 120%; /* 21.6px */
            letter-spacing: 0.2px;
          }
        }
      }
      .button {
        display: flex;
        padding: 12px 24px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 10px;
        background: var(--Main-Green, #219d50);
        color: var(--Main-White, #fff);
        // font-family: Roboto;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-transform: capitalize;
        &:hover {
          background: #0c7935;
        }
        @media (max-width: 960px) {
          align-self: stretch;
        }
      }
    }
    .bottom {
      display: flex;
      align-items: flex-start;
      gap: 45px;
      align-self: stretch;
      @media (max-width: 960px) {
        flex-direction: column;
        gap: 40px;
      }
      .left {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 24px;
        flex: 1 0 0;
        max-width: 28%;
        @media (max-width: 960px) {
          max-width: 100%;
          gap: 40px;
          width: -webkit-fill-available;
        }
        .heading {
          color: var(--Variable-Grey-Text, #5c5f62);
          // font-family: Roboto;
          font-size: 24px;
          font-style: normal;
          font-weight: 500;
          line-height: 130%; /* 31.2px */
        }
        .box {
          display: flex;
          height: clamp(180px, 335px, 380px);
          padding: 24px 0px;
          flex-direction: column;
          justify-content: flex-end;
          align-items: center;
          gap: 24px;
          align-self: stretch;
          border-radius: 12px;
          background: var(--Variable-Off-White, #f7f6fb);
          position: relative;
          @media (max-width: 960px) {
            height: clamp(190px, 200px, 250px);
            padding: 16px 24px;
          }
          img {
            position: absolute;
            left: auto;
            top: -26px;
            width: 190px;
            height: 189px;
            @media (max-width: 960px) {
              max-width: 92px;
              max-height: 91px;
            }
          }
          .title {
            color: var(--Foggy-Gray-950, #29241f);
            // font-family: Roboto;
            font-size: 28px;
            font-style: normal;
            font-weight: 800;
            line-height: 150%; /* 42px */
            letter-spacing: 0.42px;
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 8px;
            @media (max-width: 960px) {
              font-size: 14px;
              font-weight: 700;
              letter-spacing: 0.21px;
            }
            span {
              color: var(--Foggy-Gray-500, #8c8666);
              // font-family: Roboto;
              font-size: 18px;
              font-style: normal;
              font-weight: 500;
              line-height: 150%; /* 27px */
              letter-spacing: 0.27px;
              @media (max-width: 960px) {
                font-size: 12px;
                font-weight: 700;
                letter-spacing: 0.18px;
              }
            }
          }
          .button {
            display: flex;
            padding: 12px 24px;
            justify-content: center;
            align-items: center;
            border-radius: 10px;
            border: 1.5px solid var(--Main-Green, #219d50);
            color: var(--Main-Green, var(--Fruit-Salad-600, #219d50));
            // font-family: Roboto;
            font-size: 18px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            text-transform: capitalize;
            &:hover {
              background: #219d50;
              color: #fff;
            }
            @media (max-width: 960px) {
              font-size: 14px;
              margin-top: 20px;
              align-self: stretch;
            }
          }
          .button.green {
            border: none;
            color: #fff;
            background: #219d50;
            &:hover {
              background: #0c7935;
            }
          }
          .badge {
            display: flex;
            max-width: 70px;
            max-height: 70px;
            padding: 16px 24px;
            justify-content: center;
            align-items: center;
            gap: 16px;
            position: absolute;
            left: -39px;
            top: 0px;
            border-radius: 59px;
            background: var(--Green-Light, rgba(104, 212, 145, 0.27));
            color: var(--Basic-Black, #000);
            text-align: center;
            // font-family: Roboto;
            font-size: 20px;
            font-style: normal;
            font-weight: 700;
            line-height: 150%; /* 30px */
            @media (max-width: 960px) {
              max-width: 40px;
              max-height: 40px;
              padding: 7px 10px;
              color: var(--Basic-Black, #000);
              font-size: 8.889px;
              letter-spacing: 0.133px;
              top: -12px;
              left: -10px;
            }
          }
        }
      }
      .right {
        max-width: 100%;
        // @media (max-width: 960px) {
        //   max-width: 100%;
        // }
      }
    }
  }
  .SectionThirten {
    display: flex;
    padding: 80px;
    align-items: center;
    position: relative;
    width: -webkit-fill-available;
    justify-content: center;
    @media (max-width: 960px) {
      padding: 40px 24px;
      max-width: calc(100vw - 48px);
    }
    .wrap {
      max-width: 1300px;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 50px;
      @media (max-width: 960px) {
        max-width: 100%;
        gap: 32px;
      }
      .upper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 8px;
        align-self: stretch;
        .subheader {
          color: var(--Fruit-Salad-800, #1c5f36);
          // font-family: Roboto;
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: 131.5%; /* 21.04px */
          letter-spacing: 1.44px;
          text-transform: uppercase;
        }
        .header {
          color: #3c3c3c;
          // font-family: Gabarito;
          font-size: 48px;
          font-style: normal;
          font-weight: 700;
          line-height: 120%; /* 57.6px */
          @media (max-width: 960px) {
            font-size: 32px;
          }
        }
      }
      .lower {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        gap: 58px;
        align-self: stretch;
        @media (max-width: 960px) {
          gap: 40px;
          flex-direction: column;
          align-items: center;
        }
        .box {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 16px;
          text-align: left;
          @media (max-width: 960px) {
            text-align: center;
            gap: 24px;
            align-items: center;
          }
          img {
            max-width: 32px;
            max-height: 32px;
          }
          .title {
            color: var(--Fruit-Salad-950, #082b17);
            // font-family: Roboto;
            font-size: 20px;
            font-style: normal;
            font-weight: 700;
            line-height: 150%; /* 30px */
          }
          .note {
            color: var(--Grey-500, #6b6a61);
            // font-family: Roboto;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 150%; /* 24px */
          }
        }
      }
      .button {
        display: flex;
        padding: 12px 24px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 10px;
        border: 1.5px solid var(--Main-Green, #219d50);
        color: var(--Main-Green, var(--Fruit-Salad-600, #219d50));
        // font-family: Roboto;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-transform: capitalize;
        text-decoration: none;
        &:hover {
          background: #219d50;
          color: #fff;
        }
        @media (max-width: 960px) {
          align-self: stretch;
        }
      }
    }
  }
  .SectionFort {
    display: flex;
    padding: 99px 80px;
    align-items: center;
    position: relative;
    width: -webkit-fill-available;
    justify-content: center;
    @media (max-width: 960px) {
      padding: 40px 24px;
      max-width: calc(100vw - 48px);
    }
    .wrap {
      max-width: 1300px;
      display: flex;
      flex-direction: column;
      align-items: center;
      @media (max-width: 960px) {
        max-width: 100%;
      }
      .lower {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        gap: 114px;
        align-self: stretch;
        @media (max-width: 960px) {
          flex-direction: column;
        }
        .box {
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 24px;
          text-align: left;
          max-width: 330px;

          .imagebg {
            width: 142px;
            height: 142px;
            border-radius: 16px;
            background: var(--Foggy-Gray-50, #f5f5f1);
            display: flex;
            justify-content: center;
            align-items: flex-end;
          }
          .title {
            color: var(--Fruit-Salad-950, #082b17);
            text-align: center;
            // font-family: Roboto;
            font-size: 24px;
            font-style: normal;
            font-weight: 600;
            line-height: 150%; /* 36px */
            letter-spacing: 0.36px;
          }
          .button {
            display: flex;
            padding: 12px 24px;
            justify-content: center;
            align-items: center;
            gap: 10px;
            border-radius: 10px;
            background: transparent;
            border: 1.5px solid var(--Main-Green, #219d50);
            color: var(--Main-Green, var(--Fruit-Salad-600, #219d50));
            // font-family: Roboto;
            font-size: 18px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            text-transform: capitalize;
            text-decoration: none;
            align-self: stretch;
            &:hover {
              background: #219d50;
              color: #fff;
            }
          }
        }
      }
    }
  }
}
