.Navbar {
  position: relative;
  background-color: #ffffff;
  box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.08);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 50;
}

.nav-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 94%;
  padding: 0px 3%;
  max-height: 204px;
  @media (max-width: 1065px) {
    width: 98%;
    padding: 0px 1%;
  }
}
.nav-wrapper .upper {
  display: flex;
  padding: 30px 0px;
  justify-content: space-between;
  align-items: center;
  max-height: 144px;
  width: 100%;
  max-width: 1360px;
  align-self: center;
}

.upper .left img {
  // width: -webkit-fill-available;
  width: 100%;
}
.upper .left a {
  // width: -webkit-fill-available;
  width: 39.4%;
  max-width: 268px;
  min-width: 238px;
  max-height: 48px;
}
.upper .middle {
  padding-left: 52px;
  display: flex;
  gap: 6.94%;
  width: fit-content;
}
.upper .middle .searchbarwrap {
  position: relative;
  cursor: pointer;
}
/* .upper .middle .searchbarwrap input {
  display: flex;
  width: clamp(100px, 275px, 350px);
  height: 40px;
  align-items: center;
  border-radius: 10px;
  border: 1px solid #dedede;
  padding: 0px 16px;
  color: #616161;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
} */

.upper .middle input {
  height: 27px;
  color: #122146;
  font-size: 16px;
  border-radius: 12px;
  /* border: 1px solid #dedede; */
  padding: 10px 16px 10px 16px;
}

.upper .middle input:focus {
  outline: 1px solid #00588f;
  box-shadow: 0px 4px 6px -2px rgba(0, 0, 0, 0.05),
    0px 10px 15px -3px rgba(32, 66, 137, 0.1);
}

.upper .middle input::placeholder {
  color: #616161;
  font-weight: 400;
  font-size: 16px;
}

.mui-register-password {
  padding-right: 10px;
  border-radius: 12px;
  border: 1px solid #e2e8f0;
  width: 380px;
}
.mui-register-password input {
  border: none;
}

.upper .middle .searchbarwrap img {
  position: absolute;
  bottom: 31%;
  right: 5%;
}
.upper .middle .UpperRight {
  display: flex;
  gap: 32px;
  // padding-right: 32px;
}
.upper .middle .UpperRight .boxWrap {
  display: flex;
  align-items: center;
  gap: 16px;
}
.upper .middle .UpperRight .boxWrap .textwrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.upper .middle .UpperRight .boxWrap .textwrap p {
  margin: 0;
}
.upper .middle .UpperRight .boxWrap .textwrap .title {
  color: var(--Fruit-Salad-600, #219d50);
  /* font-family: Roboto; */
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
}
.upper .middle .UpperRight .boxWrap .textwrap .contonet {
  color: var(--Basic-Black, #000);
  /* font-family: Roboto; */
  white-space: nowrap;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
}
.upper .right {
  display: flex;
  align-items: center;
  gap: 8px;
  padding-left: 2.2%;
  border-left: 1px solid #0000002e;
}
.loggedin {
  padding-left: 8px !important;
}
svg path {
  transition: 300ms;
}
.upper .right button {
  display: flex;
  padding: 10px 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 10px;
  border: 1.5px solid #dadada;
  background: #fff;
  color: var(--Main-Black, #1e1e1e);
  /* font-family: Roboto; */
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: capitalize;
  cursor: pointer;
}
.upper .right button:hover {
  background: #219d50;
  color: #fff;
}
.upper .right button:hover svg path {
  stroke: white;
}
.upper .right .green {
  white-space: nowrap;
  background: var(--Green-Light, rgba(104, 212, 145, 0.27));
  border: none;
  color: var(--Main-Green, var(--Fruit-Salad-600, #219d50));
}

.nav-wrapper .lower {
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  width: -webkit-fill-available;
  padding: 10px 0px;
  max-width: 1360px;
}
.lowrewrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1360px;
}
.shopButton {
  display: flex;
  align-items: center;
  gap: 6px;
  
}
.shopButton .ShopBut {
  border-radius: 10px;
  background-color:  rgba(104, 212, 145, 0.27);
  display: flex;
  padding: 10px 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: #219d50;
  /* font-family: Roboto; */
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: capitalize;
  border: none;
  text-decoration: none;
  &:hover{
    background-color:  rgba(104, 212, 145, 0.27);
  }
  @media (max-width: 1065px) {
    padding: 10px 15px;
  }
}
.shopButton .ShopBut svg {
  transition: 300ms;
}
.shopButton .ShopBut.active > svg {
  transform: rotate(180deg);
}

.shopButton .cartMini {
  display: flex;
  align-items: center;
  gap: 4px;
  cursor: pointer;
  transition: 300ms;
}

.cartMini:hover {
  scale: 1.1;
}
.shopButton .cartMini .total {
  color: #219d50;
  /* font-family: Roboto; */
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: capitalize;
}

.drop-down-link {
  color: var(--Variable-Grey-Text, #5c5f62);
  /* font-family: Roboto; */
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: capitalize;
  cursor: pointer;
}

.drop-down-link:hover {
  color: #219d50;
}

.drop-menu {
  position: relative;
  gap: 24px;
  align-items: center;
  display: flex;
  z-index: 20;
  top: calc(100% + 12px);
  left: 3%;
  padding: 22px 6px;
  border-radius: 32px;
  position: absolute;
  background: #fff;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
}

.menu-wrapper {
  justify-content: space-between;
  display: flex;
  flex-wrap: wrap;
  width: 700px;
}

.menu-img-wrap {
  width: 336px;
}

.drop-menu.two {
  flex-wrap: nowrap;
  flex-direction: column;
  width: 275px;
}

.drop-menu.two .menu-box {
  width: calc(100% - 48px);
  max-height: 70px;
  padding: 4px 12px;
}

.drop-menu .menu-box {
  transition: 0.2s all ease-in-out;
  display: flex;
  text-decoration: none;
  border-radius: 12px;
  align-items: center;
  gap: 16px;
  padding: 0px 24px;
  width: calc(49.5% - 48px);
  height: 56px;
}

.menu-box:hover {
  background-color: #abecc4;
}

.menu-box:hover .icon-arrow {
  color: #219d50;
  font-weight: 500;
  font-size: 22px;
}

.menu-box.blue:hover {
  background-color: #219d50;
}

.menu-box.blue .text-div .menuitemtitle {
  color: #fff2f1;
  font-weight: 600;
  font-size: 20px;
  max-width: 100%;
}

.logo-img {
  width: 165px;
  height: 58px;
}

.icon-div {
  transition: 0.2s all ease-in-out;
  width: 40px;
  height: 40px;
  background-color: #e5f4fd;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.text-div {
  display: flex;
  gap: 8px;
  width: calc(100% - 56px);
}

.icon-arrow {
  font-size: 20px;
}

.menuitemtitle {
  color: var(--Basic-Black, #000);
  text-align: center;
  /* font-family: Roboto; */
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: capitalize;
  white-space: normal;
  text-align: left;
}

.links-wrapper {
  // gap: clamp(10px, 20px, 25px);
  gap: 1.38%;
  display: flex;
  align-items: flex-start;
}

.links-wrapper a {
  white-space: nowrap;
  color: var(--Variable-Grey-Text, #5c5f62);
  /* font-family: Roboto; */
  font-size: clamp(12px, 1.2vw, 16px);
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: capitalize;
  text-decoration: none;
}

.links-wrapper a:hover {
  color: #219d50;
}

.links-wrapper .drop-down-link {
  font-size: clamp(12px, 1.2vw, 16px);
  display: flex;
  align-items: center;
  gap: 8px;
  transition: 300ms;
}

.mobileNav {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  flex-wrap: wrap;
  position: relative;
  width: -webkit-fill-available;
}
.mobileNav .upper {
  background: var(--Foggy-Gray-50, #f5f5f1);
  display: flex;
  /* width: 390px; */
  width: -webkit-fill-available;
  padding: 12px 0px 8px 0px;
  justify-content: center;
  align-items: center;
  gap: 24px;
  max-height: 144px;
}
.mobileNav .upper .Text_wrap {
  display: flex;
  align-items: center;
  gap: 6.667px;
  color: var(--Basic-Black, #000);
  text-align: center;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: capitalize;
  text-decoration: none;
}
.mobileNav .upper .Text_wrap span {
  color: var(--Fruit-Salad-600, #219d50);
  text-align: center;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: capitalize;
}
.mobileNav .middle {
  display: flex;
  margin: 6px 24px;
  padding-bottom: 16px;
  justify-content: center;
  align-items: flex-end;
  gap: 8px;
  flex-shrink: 0;
  justify-content: space-between;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.mobileNav .middle > a {
  max-width: 60%;
}
.mobileNav .middle > a > img {
  width: 100%;
}
.mobileNav .lower {
  display: flex;
  max-width: calc(100vw - 50px);
  max-height: 41px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  padding: 0 24px;
  padding-top: 8px;
  padding-bottom: 12px;
}
.mobileNav .lower .right {
  display: flex;
  align-items: center;
  gap: 8px;
}
.mobileNav .lower .right button {
  display: flex;
  padding: 10px 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 10px;
  border: 1.5px solid #dadada;
  background: #fff;
  color: #1e1e1e;
  /* font-family: Roboto; */
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: capitalize;
  cursor: pointer;
  max-width: 100px;
}
.mobileNav .lower .right button:hover {
  background: #219d50;
  color: #fff;
}
.mobileNav .lower .right button:hover svg path {
  stroke: white;
}
.mobileNav .lower .right .green {
  background: var(--Green-Light, rgba(104, 212, 145, 0.27));
  border: none;
  color: var(--Main-Green, var(--Fruit-Salad-600, #219d50));
}
.mobileNav .lower .cart {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}
.mobileNav .lower .cart .menu-icon {
  display: flex;
  padding: 12px 0px 12px 22px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-left: 1px solid #dedede;
}
.mobileNav .lower .cart .cartIcon {
  display: flex;
  padding: 20px;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  flex: 1 0 0;
}
.dropdown-links-wrapper {
  position: absolute;
  left: 0;
  display: flex;
  width: -webkit-fill-available;
  max-width: calc(100vw - 48px);
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  background-color: white;
  z-index: 9999;
  border-top: 1.5px solid rgba(143, 145, 161, 0.1);
  top: 100%;
}
.dropdown-links-wrapper a {
  color: var(--Main-Black, #1e1e1e);
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 27px */
  letter-spacing: 0.27px;
  padding: 12px 0px;
  width: -webkit-fill-available;
  text-align: left;
}
.navMenu-avatar-account {
  display: flex;
  flex-direction: column;
  .main-account-mobile-bttn {
    display: flex;
    align-items: center;
    gap: 9px;
    position: relative;
  }
  &.show {
    .bottom-user-cont-navigation-links {
      max-height: 1000px;
      padding: 12px;
    }
  }
  .bottom-user-cont-navigation-links {
    max-height: 0px;
    padding: 0px;
    overflow: hidden;
    position: absolute;
    top: 100%;
    left: 0;
    width: calc(100vw - 24px);
    transition: 0.3s all ease-in-out;
  }
  .right-bttn {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
  }
}

.navMenu-avatar-account .right-bttn .my-account-drop {
  overflow: hidden;
  color: #000;
  text-overflow: ellipsis;
  font-family: Roboto;
  font-size: 12px;
  font-weight: 400;
  line-height: 140%; /* 16.8px */
}
.dropdown-links-wrapper .drop-down-link {
  color: #1e1e1e;
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 27px */
  letter-spacing: 0.27px;
  width: -webkit-fill-available;
}
.MobDrop > .Mobbox {
  color: var(--Main-Black, #1e1e1e);
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 27px */
  letter-spacing: 0.27px;
  padding: 12px 0px;
  width: -webkit-fill-available;
  display: flex;
  flex-direction: column;
}
.MobDrop {
  padding-left: 10px;
}
.supmenuparent {
  display: flex !important;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  padding: 12px 0px;
}

/* @media (max-width: 1268px) {
  .Navbar {
    display: none;
  }
} */
// @media (max-width: 1000px) {
//   .upper .right {
// //  flex-direction: column;
//  button{
//   width: 100%;
//  }
//   }
// }
