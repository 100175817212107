.presc-message-container{
    display: flex;
    padding-bottom: 24px;
    margin-bottom: 24px;
    border-bottom: 1px solid #00000028;
    align-self: stretch;
    &:last-child{
        border: none;
        margin-bottom: 0px;
        padding-bottom: 0px;
    }
  .presc-notes-message{
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 100%;
    .top-user-details{
        display: flex;
        align-items: center;
        justify-content: space-between;
       flex-wrap: wrap;
       gap: 6px;
        .user-left{
            display: flex;
            gap: 16px;
            align-items:center;
            .user-name{
                color: #219D50;
                font-family: Roboto;
                font-size: clamp(14px, 4vw, 24px);
                font-style: italic;
                font-weight: 600;
                line-height: 130%; /* 31.2px */
                letter-spacing: 0.36px;
                text-align: left;
                &.doctor{
                    color:#5C5F62;
                    font-family: Roboto;
                    font-size: clamp(14px, 4vw, 24px);
                    font-style: italic;
                    font-weight: 600;
                    line-height: 130%; /* 31.2px */
                    letter-spacing: 0.36px;
                }
            }
        }
        .message-date{
            overflow: hidden;
            color: #000;
            text-align: right;
            text-overflow: ellipsis;
            font-family: Roboto;
            font-size: clamp(12px, 2vw, 16px);
            font-weight: 400;
            line-height: 150%; /* 24px */
        }
    }
    .message-text{
        text-align: left;
        color:#212529;
        font-family: Roboto;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; /* 21px */
    }
    .reply-div{
        width: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        .reply-bttn{
            color:#219D50;
            font-family: Roboto;
            font-size: 14px;
            font-weight: 600;
            text-transform: capitalize;
            gap: 8px;
        }
    }
}  
}
