.prescription-reminder {
  display: flex;
  padding: 32px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  border-radius: 10px;
  background: #d6f3e1;
  align-self: stretch;
  .title-div {
    display: flex;
    justify-content: space-between;
    width: 100%;
    .left {
      display: flex;
      align-items: center;
      gap: 8px;
      p {
        color: #000;
        font-family: Roboto;
        text-align: left;
        font-size: clamp(20px, 5vw, 24px);
        font-style: normal;
        font-weight: 600;
        line-height: 150%; /* 36px */
        letter-spacing: 0.36px;
      }
    }
  }
  .boxesData {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 12px;
    .presc-data-box {
      display: flex;
      justify-content: space-between;
      padding: 16px;
      align-items: center;
      gap: 16px;
      border-radius: 10px;
      border: 1px solid #dedede;
      background: #f8f8f8;
      @media (max-width: 1420px) {
        flex-direction: column;
      }
      .text-cont {
        display: flex;
        gap: 16px;
        text-align: left;
        align-self: stretch;
        .box-txt {
          overflow: hidden;
          color: var(--Basic-Black, #000);
          text-overflow: ellipsis;
          font-family: Roboto;
          font-size: clamp(12px, 3vw, 16px);
          font-style: normal;
          font-weight: 400;
          line-height: 150%;
        }
      }

      .presc-name {
        display: flex;
        gap: 4px;
        align-self: stretch;
        text-align: left;
        .box-txt {
          color: #000;
          font-family: Roboto;
          font-size: clamp(12px, 3vw, 16px);
          font-weight: 500;
          line-height: 140%; /* 19.6px */
        }
        .pres-status {
          white-space: nowrap;
          max-height: 17px;
          border-radius: 4px;
          background: #d6f3e1;
          display: flex;
          padding: 4px 8px;
          justify-content: center;
          align-items: center;
          color: #219d50;
          font-family: Roboto;
          font-size: 10px;
          font-weight: 600;
          line-height: 150%; /* 15px */
        }
      }
      .bttn-wrapper {
        display: flex;
        gap: 7px;
        align-items: center;
        align-self: stretch;
        .payNow-bttn {
          max-height: 36px;
          display: flex;
          width: 153px;
          padding: 10px 20px;
          justify-content: center;
          align-items: center;
          gap: 10px;
          flex-shrink: 0;
          border-radius: 10px;
          background: #219d50;
          color: var(--Main-White, #fff);
          font-family: Roboto;
          font-size: 14px;
          font-weight: 600;
          text-transform: capitalize;
          flex: 2;
          &:hover {
            background: #0c7935;
          }
          @media (max-width: 390px) {
            padding: 10px 6px;
            font-size: 12px;
          }
        }
        .presc-details-bttn {
          display: flex;
          padding: 8px 20px;
          justify-content: center;
          align-items: center;
          gap: 10px;
          border-radius: 10px;
          border: 1.5px solid #dadada;
          background: #fff;
          color: #1e1e1e;
          font-family: Roboto;
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          text-transform: capitalize;
          flex: 1;
          &:hover {
            background: #219d50;
            color: #fff;
          }
        }
      }
    }
  }
}
